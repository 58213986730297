import { cloneDeep, every, forEach, map, maxBy } from 'lodash'
import {
  BlockType,
  CellBlock,
  Cut,
  GetFileType,
  TestDataOcr,
  TestFile,
  TextBlock,
} from '../types'
import { isCell } from './guards'
import { getFileV2 } from '../api'
import { calculateHeightandWidth, rotateOcrBox } from './spatial'
import { fetchPDFFromLocal } from '../workbook'

/**
 * OCR util function to break down the data
 * @param ocr
 * @returns pages, tables, cells, lines, words
 */
export const breakDownOcr = (
  ocr: TestDataOcr | undefined,
  degree: number
): [
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>
] => {
  const pages: TextBlock[] = []
  const pageMap = new Map<string, TextBlock>()
  const wordMap = new Map<string, TextBlock>()
  const lineMap = new Map<string, TextBlock>()
  const tableMap = new Map<string, TextBlock>()
  const cellMap = new Map<string, TextBlock>()
  if (!ocr) return [pageMap, tableMap, cellMap, lineMap, wordMap]
  const copy = cloneDeep(ocr)
  for (const arr of copy) {
    const page = arr.textAnnotations.find(
      (block) => block.blockType === BlockType.PAGE
    )
    if (!page) continue
    const [topLeft, topRight, bottomRight, bottomLeft] =
      page.boundingPoly.vertices
    const [h, w] = calculateHeightandWidth(
      topLeft,
      topRight,
      bottomRight,
      bottomLeft
    )
    arr.textAnnotations.forEach((block) => {
      if (block.blockType !== BlockType.PAGE) {
        const newVertices = rotateOcrBox(
          block.boundingPoly.vertices,
          degree,
          w,
          h
        )
        block.boundingPoly.vertices = newVertices
      }
    })
  }
  forEach(copy, (item) => {
    forEach(item.textAnnotations, (block) => {
      if (block.blockType === BlockType.PAGE) pages.push(block)
      else if (block.blockType === BlockType.WORD) wordMap.set(block.id, block)
      else if (block.blockType === BlockType.LINE) lineMap.set(block.id, block)
      else if (block.blockType === BlockType.TABLE)
        tableMap.set(block.id, block)
      else if (block.blockType === BlockType.CELL) cellMap.set(block.id, block)
    })
  })

  forEach(pages, (page, i) => pageMap.set(String(i + 1), page))

  return [pageMap, tableMap, cellMap, lineMap, wordMap]
}

export const fillterDataBasedOnCutType = (
  page: TextBlock,
  tables: Map<string, TextBlock>,
  cells: Map<string, TextBlock>,
  lines: Map<string, TextBlock>,
  words: Map<string, TextBlock>,
  type: Cut
) => {
  const rects: TextBlock[] = []

  if (type === Cut.TABLECUT) {
    forEach(page.relationships, (re) => {
      forEach(re.ids, (id) => {
        if (tables.has(id)) {
          rects.push(tables.get(id) as TextBlock)
        }
      })
    })
  } else if (type === Cut.TEXTCUT || type === Cut.WORD || type === Cut.SUM) {
    forEach(page.relationships, (re) => [
      forEach(re.ids, (id) => {
        if (lines.has(id)) {
          const line = lines.get(id) as TextBlock
          forEach(line.relationships, (re) => {
            forEach(re.ids, (id) => {
              rects.push(words.get(id) as TextBlock)
            })
          })
        }
      }),
    ])
  } else if (type === Cut.LINE) {
    forEach(page.relationships, (re) => {
      forEach(re.ids, (id) => {
        if (lines.has(id)) {
          const line = lines.get(id)
          if (line) rects.push(line)
        }
      })
    })
  }
  return rects
}

/**
 * Transform text block to cell block
 * @param cells
 * @param words
 * @returns
 */
export const transformCells = (
  cells: TextBlock[],
  words: Map<string, TextBlock>
): CellBlock[] => {
  const bool = every(cells, (cell) => isCell(cell))
  if (!bool) return []
  const newCells = map(cells, (cell) => {
    const wordIds = cell?.relationships ? cell.relationships[0].ids : []
    const wordArray = map(wordIds, (id) => {
      const word = words.get(id)
      return word ? word.description : ''
    })
    const newCell = {
      ...cell,
      text: wordArray.length ? wordArray.join(' ') : '',
    }
    return newCell
  })
  return newCells as CellBlock[]
}

export const findRowAndColFromCells = (
  cells: CellBlock[]
): [row: number, col: number] => {
  const row = maxBy(cells, (cell) => cell.rowIndex)
  const col = maxBy(cells, (cell) => cell.columnIndex)
  return [row ? row.rowIndex : -1, col ? col.columnIndex : -1]
}

export const searchInputInFiles = async (
  files: TestFile[],
  input: any[][],
  primaryColumnIndices: number[],
  firstRowHeader: boolean,
  isLocalMode: boolean
): Promise<[any[][], number]> => {
  let hasSearched = 0
  const result: any[][] = []
  for (let i = 0; i < input.length; i++) {
    const tmp: any[] = []
    for (let j = 0; j < input[i].length; j++) {
      tmp.push(undefined)
      input[i][j] = String(input[i][j]).trim()
    }
    result.push(tmp)
  }
  if (firstRowHeader && input.length === 1) return [result, hasSearched]

  if (firstRowHeader) {
    const firstRow = [...input[0]]
    result[0] = firstRow
  }

  for (const file of files) {
    hasSearched++
    let res: GetFileType
    let ocr: TestDataOcr
    if (isLocalMode) {
      const f = await fetchPDFFromLocal(file.id)
      ocr = f?.ocr ?? []
    } else {
      res = await getFileV2(file.id)
      ocr = await (await fetch(res.ocrUrl)).json()
    }

    const lines = ocr.map((r) => {
      const pageInfo = r.textAnnotations[0]
      const vs = pageInfo.boundingPoly.vertices
      const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
      return r.textAnnotations
        .filter((annotation) => annotation.blockType === BlockType.LINE)
        .map((block) => ({ ...block, ocrH: h, ocrW: w }))
    })
    const idx = firstRowHeader ? 1 : 0
    for (let i = idx; i < input.length; i++) {
      const resultRow = result[i]
      if (every(resultRow)) continue
      const searchValues = [...input[i]]
      const arr = searchInputFileHelper(searchValues, lines, file.id)
      // if (every(arr)) result[i] = arr
      if (
        resultRow.length === primaryColumnIndices.length ||
        primaryColumnIndices.length === 0
      ) {
        if (every(arr)) result[i] = arr
      } else {
        const middleMan: any[] = []
        primaryColumnIndices.forEach((index) => middleMan.push(arr[index]))
        if (every(middleMan)) result[i] = arr
      }
    }
  }
  return [result, hasSearched]
}

const searchInputFileHelper = (
  valueToSearch: any[],
  ocrWOrds: TextBlock[][],
  fileId: string
) => {
  const result = Array.from(valueToSearch).fill(undefined)
  for (let i = 0; i < result.length; i++) {
    for (let j = 0; j < ocrWOrds.length; j++) {
      for (let k = 0; k < ocrWOrds[j].length; k++) {
        if (
          ocrWOrds[j][k].description
            .toLocaleLowerCase()
            .includes(valueToSearch[i].toLocaleLowerCase())
        ) {
          // add file id and file page to the textblock
          result[i] = { ...ocrWOrds[j][k], fileId, filePage: j + 1 }
        }
        if (every(result)) return result
      }
    }
  }
  return result
}
