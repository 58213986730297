import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Cut } from '../types'
import { RootState } from '../store'

type CutModeState = {
  type: Cut
  transposed: boolean
}

const initialState: CutModeState = {
  type: Cut.VIEW,
  transposed: false,
}

const cutModeSlice = createSlice({
  name: 'CutMode',
  initialState,
  reducers: {
    setCutType: (state, action: PayloadAction<Cut>) => {
      state.type = state.type === action.payload ? Cut.VIEW : action.payload
    },
    setTransposed: (state, action: PayloadAction<boolean>) => {
      state.transposed = action.payload
    },
  },
})

export const { setCutType, setTransposed } = cutModeSlice.actions
export const selectCutMode = (state: RootState) => state.cutMode
export default cutModeSlice.reducer
