import { useEffect } from 'react'
import { useAppDispatch } from '../dispatch'
import { setActiveWorksheetId } from '../slice/activeWorksheetSlice'

const useOnActivatedReceiver = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handler = (event: any) => {
      try {
        const e = event as unknown as CustomEvent
        const detail: Excel.WorksheetActivatedEventArgs = JSON.parse(e.detail)
        const sheetId = detail.worksheetId
        dispatch(setActiveWorksheetId(sheetId))
      } catch (err) {
        console.error(err)
      }
    }

    window.addEventListener('OnWorksheetActivated', handler)

    return () => window.removeEventListener('OnWorksheetActivated', handler)
  }, [dispatch])
}

export default useOnActivatedReceiver
