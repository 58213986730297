export const DISABLED_RIBBON_BUTTON: Office.RibbonUpdaterData = {
  tabs: [
    {
      id: 'QuickCut',
      groups: [
        {
          id: 'QuickCutNavigation',
          controls: [
            // {
            //   id: 'UploadPageButton',
            //   enabled: false,
            // },
            {
              id: 'FilesPageButton',
              enabled: false,
            },
          ],
        },
        {
          id: 'QuickcutBasics',
          controls: [
            {
              id: 'QuickCutTextract',
              enabled: false,
            },
            {
              id: 'QuickCutXRayWord',
              enabled: false,
            },
            {
              id: 'QuickCutXRayLine',
              enabled: false,
            },
            {
              id: 'QuickCutTablecut',
              enabled: false,
            },
            {
              id: 'Sum',
              enabled: false,
            },
            {
              id: 'Redact',
              enabled: false,
            },
          ],
        },
        {
          id: 'QuickcutAdvance',
          controls: [
            {
              id: 'QuickCutDataMatching',
              enabled: false,
            },
            // {
            //   id: 'Transpose',
            //   enabled: false,
            // },
            // {
            //   id: 'Undo',
            //   enabled: false,
            // },
          ],
        },
        {
          id: 'DeleteGroup',
          controls: [
            {
              id: 'Undo',
              enabled: false,
            },
            {
              id: 'DeleteReferenceControlMenu',
              enabled: false,
            },
            {
              id: 'DeleteSingleReference',
              enabled: false,
            },
            {
              id: 'DeleteAllReferences',
              enabled: false,
            },
          ],
        },
      ],
    },
  ],
}

export const ENABLED_RIBBON_BUTTON_AFTER_SEARCH_ALL: Office.RibbonUpdaterData =
  {
    tabs: [
      {
        id: 'QuickCut',
        groups: [
          {
            id: 'QuickCutNavigation',
            controls: [
              // {
              //   id: 'UploadPageButton',
              //   enabled: true,
              // },
              {
                id: 'FilesPageButton',
                enabled: true,
              },
            ],
          },
          {
            id: 'QuickcutBasics',
            controls: [
              {
                id: 'QuickCutTextract',
                enabled: true,
              },
              {
                id: 'QuickCutXRayWord',
                enabled: true,
              },
              {
                id: 'QuickCutXRayLine',
                enabled: true,
              },
              {
                id: 'QuickCutTablecut',
                enabled: true,
              },
              {
                id: 'Sum',
                enabled: true,
              },
              {
                id: 'Redact',
                enabled: true,
              },
            ],
          },
          {
            id: 'QuickcutAdvance',
            controls: [
              {
                id: 'QuickCutDataMatching',
                enabled: true,
              },
              // {
              //   id: 'Transpose',
              //   enabled: false,
              // },
            ],
          },
          {
            id: 'DeleteGroup',
            controls: [
              {
                id: 'Undo',
                enabled: true,
              },
              {
                id: 'DeleteReferenceControlMenu',
                enabled: true,
              },
              {
                id: 'DeleteSingleReference',
                enabled: true,
              },
              {
                id: 'DeleteAllReferences',
                enabled: true,
              },
            ],
          },
        ],
      },
    ],
  }

export const FETCH_FILE_ERR_TITLE =
  'We encountered a problem fetching the file.'
export const GENERAL_ERR_CONTENT =
  'To resolve this issuse, please reload the page. You can do this by clicking anywhere on the review pane to reveal an arrow button at the top right corner. Click on the arrow button and then select the reload option.'
export const LOAD_FILE_ERR_TITLE = 'We encountered a problem loading the file.'

export const FETCH_IS_SUBSCRIBED_ERROR_TITLE =
  'Something wrong when we tried to identify your subscription status.'
export const FETCH_IS_SUBSCRIBED_ERROR_CONTENT =
  'Please contact us to identify and fix the issue.'

export const Upload_API_ERROR =
  'Technical error identified. Please contact Extractly+ support with a snapshot of the error.'
