import { RadioGroup } from '@headlessui/react'
import { motion } from 'framer-motion'
import { useState } from 'react'

const CheckIcon = (props: any) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
    <path
      d="M7 13l3 3 7-7"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

type Props = {
  options: {
    label: string
    description: string
    onClick: (...arr: any[]) => any
  }[]
  label: string
}

const CustomRadio = ({ options, label }: Props) => {
  const [selected, setSelected] = useState(options[0].label)
  return (
    <div className="w-full px-4 py-2">
      {options.length > 0 && (
        <div className="mx-auto w-full max-w-md ">
          <RadioGroup
            value={selected}
            onChange={(label) => {
              setSelected(label)
              const item = options.find((option) => option.label === label)
              item?.onClick()
            }}
          >
            <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
            <div className="space-y-2">
              {options.map((option, idx) => (
                <RadioGroup.Option
                  as={motion.div}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.9 }}
                  key={option.label}
                  value={option.label}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-teal-300'
                        : ''
                    }
                  ${checked ? 'bg-teal-900/75 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium  ${
                                checked ? 'text-white' : 'text-gray-900'
                              }`}
                            >
                              {option.label}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked ? 'text-sky-100' : 'text-gray-500'
                              }`}
                            >
                              {option.description}
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <div className="shrink-0 text-white">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      )}
    </div>
  )
}

export default CustomRadio
