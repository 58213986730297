import { jwtDecode } from 'jwt-decode'
import {
  AppCustomEvent,
  DynamoFile,
  FileCommentTopic,
  OfficeRuntimeAccessToken,
} from '../types'
import { isOfficeRuntimeToken } from './guards'

export const fileContainsComment = (
  file: DynamoFile,
  comments: FileCommentTopic[]
) => comments.some((topic) => topic.fileId === file.fileId)

export const publishOnReferenceSelectedEvent = (bindingId: string) => {
  const event = new CustomEvent(AppCustomEvent.ON_REFERENCE_SELECTED, {
    detail: { bindingId },
  })
  window.dispatchEvent(event)
}

export const getOfficeErrorTitle = (message: string) => {
  if (message.startsWith('Excel is in cell-editing mode'))
    return 'Excel is in cell-editing mode'
  return message
}

export const getOfficeRuntimeAccessToken =
  async (): Promise<OfficeRuntimeAccessToken> => {
    const token = await Office.auth.getAccessToken({
      allowConsentPrompt: true,
      allowSignInPrompt: true,
    })
    const decoded = jwtDecode(token)
    if (!isOfficeRuntimeToken(decoded))
      throw new Error('Internal Error: access denied')
    return {
      name: decoded.name,
      preferred_username: decoded.preferred_username,
      oid: decoded.oid,
    }
  }
