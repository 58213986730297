import { useEffect, useRef } from 'react'

const ribbonObject = {
  tabs: [
    {
      id: 'QuickCut',
      groups: [
        {
          id: 'QuickcutBasics',
          controls: [
            {
              id: 'QuickCutTextract',
              enabled: true,
            },
            {
              id: 'QuickCutXRayWord',
              enabled: true,
            },
            {
              id: 'QuickCutXRayLine',
              enabled: true,
            },
            {
              id: 'QuickCutTablecut',
              enabled: true,
            },
          ],
        },
        {
          id: 'QuickcutAdvance',
          controls: [
            {
              id: 'Sum',
              enabled: true,
            },
            {
              id: 'Redact',
              enabled: true,
            },
          ],
        },
      ],
    },
  ],
}

const useUpdateRibbon = () => {
  const ref = useRef(false)

  useEffect(() => {
    let skip = false

    const func = async () => {
      ref.current = true
      ribbonObject.tabs.forEach((group) =>
        group.groups.forEach((item) =>
          item.controls.forEach((control) => {
            control.enabled = ref.current ? true : false
          })
        )
      )
      if (!skip) await Office.ribbon.requestUpdate(ribbonObject)
    }

    func().catch((err) => console.error(err))

    return () => {
      skip = true
      const cleanFunc = async () => {
        ref.current = false
        ribbonObject.tabs.forEach((group) =>
          group.groups.forEach((item) =>
            item.controls.forEach((control) => {
              control.enabled = ref.current ? true : false
            })
          )
        )
        await Office.ribbon.requestUpdate(ribbonObject)
      }

      if (ref.current) cleanFunc().catch((err) => console.error(err))
    }
  }, [])
}

export default useUpdateRibbon
