import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../dispatch'
import {
  deleteReferencesWithBindingIds,
  updateRefereces,
} from '../slice/referenceSlice'
import { removeInvalidBindings } from '../workbook'
import { TextcutRectangle } from '../types'
import { updateUndoStack } from '../slice/undoSlice'

const useOnChangedReceiver = () => {
  const references = useAppSelector((state) => state.references.references)
  const undos = useAppSelector((state) => state.undo.undoStack)
  const dispatch = useAppDispatch()

  const updateReferencesHelper = useCallback(
    (references: TextcutRectangle[]) =>
      Excel.run(async (ctx) => {
        const bindings = ctx.workbook.bindings
        bindings.load('items')
        await ctx.sync()
        const hashMap = new Map<string, Excel.Range>()
        bindings.items.forEach((item) => item.load('id'))
        await ctx.sync()
        bindings.items.forEach((item) => {
          const range = item.getRange()
          range.load('address')
          hashMap.set(item.id, range)
        })
        await ctx.sync()
        const newRefs = references.map((ref) => {
          if (hashMap.has(ref.bindingId)) {
            const range = hashMap.get(ref.bindingId)
            if (!range) return ref
            const [, address] = range.address.split('!')
            return {
              ...ref,
              rangeAddr: address,
            }
          }
          return ref
        })
        const updatedUndos = undos.map((undo) => {
          const item = newRefs.find(
            (ref) => ref.bindingId === undo.reference.bindingId
          )
          if (item) {
            return {
              ...undo,
              reference: {
                ...item,
              },
            }
          }
          return undo
        })
        await dispatch(updateRefereces(newRefs))
        dispatch(updateUndoStack(updatedUndos))
      }),
    [dispatch, undos]
  )

  useEffect(() => {
    const handler = async (event: any) => {
      const e = event as unknown as CustomEvent
      const args: Excel.WorksheetChangedEventArgs = JSON.parse(e.detail)
      if (
        args.changeType === Excel.DataChangeType.cellInserted ||
        args.changeType === Excel.DataChangeType.columnInserted ||
        args.changeType === Excel.DataChangeType.rowInserted
      ) {
        await updateReferencesHelper(references).catch((err) =>
          console.log(err)
        )
      } else if (
        args.changeType === Excel.DataChangeType.cellDeleted ||
        args.changeType === Excel.DataChangeType.columnDeleted ||
        args.changeType === Excel.DataChangeType.rowDeleted
      ) {
        const toBeDeleted = await removeInvalidBindings().catch((err) =>
          console.error(err)
        )
        if (toBeDeleted && toBeDeleted.length) {
          await dispatch(deleteReferencesWithBindingIds(toBeDeleted))
        }
        const filteredReferences = references.filter((ref) => {
          if (toBeDeleted && toBeDeleted.length) {
            return !toBeDeleted.some((id) => id === ref.bindingId)
          }
          return true
        })
        updateReferencesHelper(filteredReferences).catch((err) =>
          console.error(err)
        )
      }
    }

    window.addEventListener('OnExcelWorkbooksRangeValueChange', handler)

    return () =>
      window.removeEventListener('OnExcelWorkbooksRangeValueChange', handler)
  }, [references, dispatch, updateReferencesHelper])
}

export default useOnChangedReceiver
