import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FileCommentTopic } from '../types'
import { RootState } from '../store'

type CommentSliceType = {
  comments: FileCommentTopic[]
}

const initialState: CommentSliceType = {
  comments: [],
}

export const initComments = createAsyncThunk('CommentSlice/initComments', () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const comments = settings.getItemOrNullObject('COMMENTS')
    comments.load(['value', 'isNullObject'])
    await ctx.sync()
    if (comments.isNullObject) {
      return []
    }
    const values = comments.value
    const parsedComments: FileCommentTopic[] = JSON.parse(values)
    return parsedComments
  })
)

export const addComment = createAsyncThunk(
  'CommentSlice/addComment',
  (comment: FileCommentTopic) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const comments = settings.getItemOrNullObject('COMMENTS')
      comments.load(['value', 'isNullObject'])
      await ctx.sync()
      let arr: FileCommentTopic[] = []
      if (comments.isNullObject) {
        arr = [comment]
        const stringifiedComments = JSON.stringify(arr)
        settings.add('COMMENTS', stringifiedComments)
      } else {
        const values = comments.value
        const parsedComments: FileCommentTopic[] = JSON.parse(values)
        const filterdParsedComments = parsedComments.filter(
          (topic) => topic.id !== comment.id
        )
        arr = [...filterdParsedComments, comment]
        const stringifiedComments = JSON.stringify(arr)
        settings.add('COMMENTS', stringifiedComments)
      }
      await ctx.sync()
      return arr
    })
)

export const removeCommentById = createAsyncThunk(
  'CommentSlice/removeCommentById',
  (id: string) =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const comments = settings.getItemOrNullObject('COMMENTS')
      comments.load(['value', 'isNullObject'])
      await ctx.sync()
      let arr: FileCommentTopic[] = []
      if (comments.isNullObject) return arr
      const values = comments.value
      const parsedComments: FileCommentTopic[] = JSON.parse(values)
      arr = parsedComments.filter((comment) => comment.id !== id)
      const stringifiedComments = JSON.stringify(arr)
      settings.add('COMMENTS', stringifiedComments)
      await ctx.sync()
      return arr
    })
)

export const resetComments = createAsyncThunk(
  'CommentSlice/resetComments',
  () =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const comments = settings.getItemOrNullObject('COMMENTS')
      comments.load(['value', 'isNullObject'])
      await ctx.sync()
      if (comments.isNullObject) return []
      settings.add('COMMENTS', JSON.stringify([]))
      await ctx.sync()
      return []
    })
)

// export const removeCommentsByIds = createAsyncThunk(
//   'CommentSlice/removeCommentsByIds',
//   (ids: string[]) =>
//     Excel.run(async (ctx) => {
//       const settings = ctx.workbook.settings
//       const comments = settings.getItemOrNullObject('COMMENTS')
//       comments.load(['value', 'isNullObject'])
//       await ctx.sync()
//       let arr: Comment[] = []
//       if (comments.isNullObject) return arr
//       const values = comments.value
//       const parsedComments: Comment[] = JSON.parse(values)
//       arr = parsedComments.filter(
//         (comment) => !ids.some((id) => id === comment.id)
//       )
//       const stringifiedComments = JSON.stringify(arr)
//       settings.add('COMMENTS', stringifiedComments)
//       await ctx.sync()
//       return arr
//     })
// )

const commentSlice = createSlice({
  initialState,
  name: 'CommentSlice',
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initComments.fulfilled, (state, action) => {
      state.comments = action.payload
    })

    builder.addCase(addComment.fulfilled, (state, action) => {
      state.comments = action.payload
    })

    builder.addCase(removeCommentById.fulfilled, (state, action) => {
      state.comments = action.payload
    })

    builder.addCase(resetComments.fulfilled, (state, action) => {
      state.comments = action.payload
    })

    // builder.addCase(removeCommentsByIds.fulfilled, (state, action) => {
    //   state.comments = action.payload
    // })
  },
})

export const comments = (state: RootState) => state.comments.comments
export default commentSlice.reducer
