import { forEach } from 'lodash'
import numeral, { Numeral } from 'numeral'
import {
  calculateHeightandWidth,
  findTOpLeftPointFromVertices,
} from '../utils/spatial'
import { Cut, QuadTreeMiniTextBlock, TextBlock } from '../types'
import {
  Quadtree,
  Rectangle as QuadtreeRectangle,
} from '@timohausmann/quadtree-ts'

type Props = {
  height: number
  width: number
  filteredData: TextBlock[]
  ratioX: Numeral
  ratioY: Numeral
  cutType: Cut
}

/**
 * use quad tree hook
 * @param param0
 * @returns quadtree
 */
export const useQuadTree = ({
  height,
  width,
  filteredData,
  ratioX,
  ratioY,
  cutType,
}: Props) => {
  const quadTree = new Quadtree<QuadtreeRectangle<QuadTreeMiniTextBlock>>({
    height: height,
    width: width,
  })
  if (
    cutType === Cut.TEXTCUT ||
    cutType === Cut.SUM ||
    cutType === Cut.REDACT
  ) {
    forEach(filteredData, (data) => {
      const vertices = data.boundingPoly.vertices
      const topLeft = findTOpLeftPointFromVertices(vertices)
      const [h, w] = calculateHeightandWidth(
        vertices[0],
        vertices[1],
        vertices[2],
        vertices[3]
      )
      if (topLeft) {
        const rectangle = new QuadtreeRectangle<QuadTreeMiniTextBlock>({
          x: numeral(topLeft.x).divide(ratioX.value()).value() ?? -1,
          y: numeral(topLeft.y).divide(ratioY.value()).value() ?? -1,
          width: numeral(w).divide(ratioX.value()).value() ?? -1,
          height: numeral(h).divide(ratioY.value()).value() ?? -1,
          data: {
            id: data.id,
            blockType: data.blockType,
            vertices: vertices,
            description: data.description,
          },
        })
        quadTree.insert(rectangle)
      }
    })
  }

  return quadTree
}

export default useQuadTree
