import { useEffect } from 'react'
import { Cut } from '../types'
import { useAppDispatch, useAppSelector } from '../dispatch'
import { setCutType } from '../slice/cutModeSlice'
import { toast } from 'react-toastify'
/**
 * use cut event hook
 * @returns
 */
export const useCutEvent = (toastFunc: typeof toast) => {
  const isLocalMode = useAppSelector((state) => state.localMode.isLocalMode)
  const cutType = useAppSelector((state) => state.cutMode.type)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const cutTypeEventListener = (e: any) => {
      const customEvent = e as unknown as CustomEvent
      const type: Cut = customEvent.detail?.type ?? Cut.VIEW
      if (isLocalMode && type === Cut.REDACT) {
        toastFunc.error('Redaction is not allowed in local mode.', {
          autoClose: 1000,
        })
        if (cutType === Cut.REDACT) {
          dispatch(setCutType(Cut.VIEW))
        }
      } else {
        dispatch(setCutType(type))
      }
    }

    window.addEventListener('CutType', cutTypeEventListener)

    return () => {
      window.removeEventListener('CutType', cutTypeEventListener)
    }
  }, [dispatch, isLocalMode, toastFunc, cutType])
}
