import { FileWithPath } from 'react-dropzone'
import {
  BlockType,
  CellBlock,
  OfficeRuntimeAccessToken,
  OfficeRuntimeTokenJwtPayload,
  Reference,
  TextBlock,
} from '../types'

export const isCell = (block: TextBlock): block is CellBlock =>
  block.blockType === BlockType.CELL

export const isReferece = (item: any): item is Reference => {
  const keys = Object.keys(item)
  const set = new Set(keys)
  return (
    set.has('fileId') &&
    set.has('filePage') &&
    set.has('type') &&
    set.has('blockId')
  )
}

export const isTextBlock = (block: any): block is TextBlock => {
  const keys = Object.keys(block)
  const set = new Set(keys)
  return (
    set.has('id') &&
    set.has('blockType') &&
    set.has('description') &&
    set.has('boundingPoly')
  )
}

export const isDataMatchReference = (block: any): block is TextBlock => {
  const keys = Object.keys(block)
  const set = new Set(keys)
  return (
    isTextBlock(block) &&
    set.has('fileId') &&
    set.has('filePage') &&
    set.has('row') &&
    set.has('col') &&
    set.has('startRow') &&
    set.has('startCol') &&
    set.has('sheetId')
  )
}

export const eventTargetIsNode = (
  target: EventTarget | null
): target is HTMLElement => (target && 'nodeType' in target ? true : false)

export const isCustomEvent = (event: Event): event is CustomEvent =>
  'detail' in event
export const fileWithPathGuard = (file: File): file is FileWithPath =>
  'path' in file

export const isOfficeError = (error: any): error is Office.Error =>
  'name' in error && 'message' in error && 'name' in error

export const isOfficeRuntimeToken = (
  token: OfficeRuntimeTokenJwtPayload
): token is OfficeRuntimeAccessToken =>
  'name' in token && 'preferred_username' in token && 'oid' in token
