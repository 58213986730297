import { configureStore } from '@reduxjs/toolkit'
import ToggleReducer from '../slice/toggleSlice'
import ReferencesReducer from '../slice/referenceSlice'
import showSelectionOnlyReducer from '../slice/showSelectionOnlySlice'
import CutModeReducer from '../slice/cutModeSlice'
import activeWorksheetSlice from '../slice/activeWorksheetSlice'
import saveToLocalSlice from '../slice/saveToLocalSlice'
import undoSlice from '../slice/undoSlice'
import commentSlice from '../slice/commentSlice'

export const store = configureStore({
  reducer: {
    toggle: ToggleReducer,
    references: ReferencesReducer,
    showSelectionOnly: showSelectionOnlyReducer,
    cutMode: CutModeReducer,
    activeWorksheet: activeWorksheetSlice,
    localMode: saveToLocalSlice,
    undo: undoSlice,
    comments: commentSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
