import { useEffect, useState } from 'react'

/**
 * Custom hook for pdf resize
 * @returns width
 */
const useFileResize = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setWidth(window.innerWidth)
      }, 300)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return width
}

export default useFileResize
