import { Page, Document, pdfjs } from 'react-pdf'
import {
  CanvasArea,
  FileRotation,
  RotationType,
  TestDataOcr,
} from '../../types'
import { Dispatch, RefObject, useMemo, useRef, useState } from 'react'
import useFileResize from '../../hooks/useFileResize'
import { DocumentCallback, PageCallback } from 'react-pdf/dist/cjs/shared/types'
import { useOCR } from '../../hooks/useOCR'
import KonvaContainer from '../KonvaContainer'
import { BeatLoader } from 'react-spinners'
import ErrorMsg from '../ErrorMsg'
import { GENERAL_ERR_CONTENT, LOAD_FILE_ERR_TITLE } from '../../constant'
import { useNavigate } from 'react-router-dom'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString()

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

type Props = {
  file: string | Blob
  page: number
  divRef: RefObject<HTMLDivElement>
  setTotalPages: Dispatch<React.SetStateAction<number>>
  displayKonva: boolean
  setDisplayKonva: Dispatch<React.SetStateAction<boolean>>
  setCurrentPage: Dispatch<React.SetStateAction<number>>
  // ocrUrl: string
  fileId: string
  rotationDegree: RotationType
  dispatchRotation: Dispatch<{
    type: string
    payload: undefined | number
  }>
  ocr: TestDataOcr | undefined
  setShowConfirmRedact: Dispatch<React.SetStateAction<boolean>>
  setPageLoading: Dispatch<React.SetStateAction<boolean>>
  searchAllItem: string[]
  totalPages: number
  navigate: ReturnType<typeof useNavigate>
}

const renderLoader = () => (
  <div className="flex flex-col h-screen w-screen p-2 items-center justify-center">
    <BeatLoader color="#36d7b7" />
  </div>
)

const renderErrorMsg = (
  title: string,
  content: string,
  navigate: ReturnType<typeof useNavigate>
) => (
  <div className="flex w-full p-4">
    <ErrorMsg title={title} content={content} navigate={navigate} />
  </div>
)

const PDFDisplaySection = ({
  file,
  page,
  divRef,
  setTotalPages,
  displayKonva,
  setDisplayKonva,
  // ocrUrl,
  fileId,
  rotationDegree,
  dispatchRotation,
  ocr,
  setShowConfirmRedact,
  setPageLoading,
  searchAllItem,
  totalPages,
  navigate,
}: Props) => {
  const opt = useMemo(() => {
    return {
      cMapUrl: '/bcmaps/',
      cMapPacked: true,
    }
  }, [])
  const width = useFileResize()
  const [canvasArea, setCanvasArea] = useState<CanvasArea>({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const pageRef = useRef<HTMLCanvasElement>(null)

  const [pages, tables, cells, lines, words] = useOCR(ocr, rotationDegree.curr)

  const onPageLoaded = (pageCallback: PageCallback) => {
    dispatchRotation({
      payload: pageCallback.rotate,
      type: FileRotation.SET_META,
    })
    divRef.current?.scrollIntoView({ behavior: 'smooth' })
    setDisplayKonva(true)
    if (pageRef.current) {
      const clientRects = pageRef.current.getBoundingClientRect()
      setCanvasArea({
        height: clientRects.height,
        width: clientRects.width,
      })
    }
  }
  const onDocumentLoaded = (document: DocumentCallback) => {
    setTotalPages(document.numPages)
  }

  return (
    <div className="flex relative" onContextMenu={(e) => e.preventDefault()}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoaded}
        onLoadError={() => console.log('onLoadError')}
        options={opt}
        loading={renderLoader()}
        error={renderErrorMsg(
          LOAD_FILE_ERR_TITLE,
          GENERAL_ERR_CONTENT,
          navigate
        )}
      >
        {page <= totalPages && (
          <Page
            pageNumber={page}
            renderAnnotationLayer={false}
            renderForms={false}
            renderTextLayer={false}
            width={width}
            onLoadSuccess={onPageLoaded}
            canvasRef={pageRef}
            rotate={(rotationDegree.curr + rotationDegree.meta) % 360}
            onRenderError={() => console.log('onRenderError')}
          />
        )}
      </Document>
      {displayKonva && ocr && (
        <KonvaContainer
          canvasArea={canvasArea}
          width={canvasArea.width}
          height={canvasArea.height}
          pages={pages}
          cells={cells}
          lines={lines}
          tables={tables}
          words={words}
          filePage={page}
          fileId={fileId}
          rotationDegree={rotationDegree}
          setShowConfirmRedact={setShowConfirmRedact}
          setPageLoading={setPageLoading}
          searchAllItem={searchAllItem}
        />
      )}
    </div>
  )
}

export default PDFDisplaySection
