import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type ActiveWorkSheetSliceState = {
  activeWorksheetId: string
}

const initialState: ActiveWorkSheetSliceState = {
  activeWorksheetId: '',
}

export const initActiveWorksheet = createAsyncThunk(
  'ActiveWorksheetSlice/initActiveWorksheetId',
  () =>
    Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getActiveWorksheet()
      sheet.load('id')
      await ctx.sync()
      return sheet.id
    })
)

const activeWorksheetSlice = createSlice({
  name: 'ActiveWorksheetSlice',
  initialState,
  reducers: {
    setActiveWorksheetId: (state, action: PayloadAction<string>) => {
      state.activeWorksheetId = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(initActiveWorksheet.fulfilled, (state, action) => {
      state.activeWorksheetId = action.payload
    })
    builder.addCase(initActiveWorksheet.rejected, (state, action) => {
      state.activeWorksheetId = ''
    })
  },
})

export const { setActiveWorksheetId } = activeWorksheetSlice.actions
export const selectActiveWorksheetId = (state: RootState) =>
  state.activeWorksheet.activeWorksheetId
export default activeWorksheetSlice.reducer
