import numeral from 'numeral'

export const numberlize = (str: string, callback: (str: string) => number) =>
  callback(str)

export const convertPossibleAmountToNumber = (str: string) => {
  if (str.includes('$')) {
    const idx = str.indexOf('$')
    const newStr = str.slice(idx, str.length)
    return numeral(newStr).value() ?? NaN
  }
  return numeral(str).value() ?? NaN
}
