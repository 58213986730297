import { Dispatch, useEffect } from 'react'

const useConfirmRedactListener = (
  setPopup: Dispatch<React.SetStateAction<boolean>>,
  setPopupType: () => void
) => {
  useEffect(() => {
    const handler = () => {
      setPopup(true)
      setPopupType()
    }

    window.addEventListener('CONFIRM_REDACT', handler)

    return () => window.removeEventListener('CONFIRM_REDACT', handler)
  }, [setPopup, setPopupType])
}

export default useConfirmRedactListener
