import { TestDataOcr, TextBlock } from '../types'
import { breakDownOcr } from '../utils/ocr'

/**
 * useOcr custom hook
 * @param ocr
 * @returns
 */
export const useOCR = (
  ocr: TestDataOcr | undefined,
  degree: number
): [
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>,
  Map<string, TextBlock>
] => {
  const [pages, tables, cells, lines, words] = breakDownOcr(ocr, degree)

  return [pages, tables, cells, lines, words]
}
