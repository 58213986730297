import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  CutTag,
  FileStatus,
  TestFile,
  TextcutRectangle,
  UndoEvent,
} from '../../types'
import { every, filter, map } from 'lodash'
import {
  createBindingAndAddOnSelectionHandler,
  loadValuesFromARange,
  numberToLetters,
} from '../../workbook'
import { searchInputInFiles } from '../../utils/ocr'
import { BeatLoader } from 'react-spinners'
import { nanoid } from 'nanoid'
import {
  calculateHeightandWidth,
  findTOpLeftPointFromVertices,
} from '../../utils/spatial'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addMultipleReferences } from '../../slice/referenceSlice'
import useFiles from '../../hooks/useFiles'
import CustomDisclosure from '../CustomDisclosure'
import { motion, AnimatePresence } from 'framer-motion'
import { pushToUndoStack } from '../../slice/undoSlice'
import ErrorMsg from '../ErrorMsg'
import { getOfficeErrorTitle } from '../../utils/common'
import { isCustomEvent, isOfficeError } from '../../utils/guards'
import { GENERAL_ERR_CONTENT } from '../../constant'
import { useNavigate } from 'react-router-dom'

type TestFileCheck = TestFile & { checked: boolean }

const DataMatching = () => {
  const [firstRowHeader, setFirstRowHeader] = useState(0)
  const [inputRange, setInputRange] = useState('')
  const [outputRange, setOutputRange] = useState('')
  const [checkAllFiles, setCheckAllFiles] = useState(false)
  const [loading, setLoading] = useState(false)
  const isLocalMode = useAppSelector((state) => state.localMode.isLocalMode)
  const {
    files: data,
    isError,
    isFetchingLocal,
    isLoading,
    error,
    localError,
    isLocalError,
    isFetching,
  } = useFiles(isLocalMode)
  const [primaryColumnOptions, setPrimaryColumnOptions] = useState<string[]>([])
  const [primaryIndiecs, setPrimaryIndices] = useState<number[]>([])
  const navigate = useNavigate()
  const [files, setFiles] = useState<TestFileCheck[]>([])
  const inputRangeRef = useRef<HTMLInputElement>(null)
  const [inputRangeFocus, setInputRangeFocus] = useState(false)
  const outputRangeRef = useRef<HTMLInputElement>(null)
  const [outputRangeFocus, setOutputRangeFocus] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data) {
      const arr: TestFileCheck[] = data
        .filter((file) => file.status === FileStatus.SUCCEEDED)
        .map((file) => ({
          filename: file.fileName ?? '',
          checked: false,
          id: file.fileId,
          ocrPath: file.ocrFileKey,
          path: file.ocrFileKey,
        }))
      setFiles(arr)
      setCheckAllFiles(false)
    }
  }, [data])

  useEffect(() => {
    const func = async () =>
      Excel.run(async (ctx) => {
        const sheet = ctx.workbook.worksheets.getActiveWorksheet()
        if (inputRange === '') return []
        const range = sheet.getRange(inputRange)
        range.load(['columnCount', 'columnIndex', 'isNullObject'])
        await ctx.sync()
        if (range.isNullObject) return []
        const arr: string[] = []
        for (
          let i = range.columnIndex;
          i < range.columnIndex + range.columnCount;
          i++
        ) {
          arr.push(numberToLetters(i))
        }
        return arr
      })

    func()
      .then((res) => {
        setPrimaryColumnOptions(res)
        setPrimaryIndices([])
      })
      .catch((err) => {
        console.error(err)
        setPrimaryColumnOptions([])
      })
  }, [inputRange])

  useEffect(() => {
    const handler = (event: Event) => {
      if (!isCustomEvent(event)) return
      try {
        const args: Excel.WorksheetSelectionChangedEventArgs = JSON.parse(
          event.detail
        )

        if (inputRangeFocus && inputRangeRef.current) {
          setInputRange(args.address)
          // setInputRangeFocus(false)
          inputRangeRef.current.focus()
        } else if (outputRangeFocus && outputRangeRef.current) {
          setOutputRange(args.address)
          // setOutputRangeFocus(false)
        }
      } catch (err) {
        toast.error(`Error: ${err}`)
      }
    }

    window.addEventListener('OnExcelWorkbooksSelectionChange', handler)
    return () =>
      window.removeEventListener('OnExcelWorkbooksSelectionChange', handler)
  }, [inputRangeFocus, outputRangeFocus])

  // const getSelectedRange = async () => {
  //   return await Excel.run(async (context) => {
  //     const selectedRange = context.workbook.getSelectedRange()
  //     selectedRange.load('address')
  //     await context.sync()
  //     const result = selectedRange.address.split('!')[1]
  //     return result
  //   })
  // }

  // const fillInputRange = async () => {
  //   const range = await getSelectedRange()
  //   setInputRange(range)
  // }

  // const fillOutputRange = async () => {
  //   const range = await getSelectedRange()
  //   setOutputRange(range)
  // }

  const onCheckAllFilesChange = () => {
    const arr = files.map((file) => ({
      ...file,
      checked: checkAllFiles ? false : true,
    }))
    setCheckAllFiles(!checkAllFiles)
    setFiles(arr)
  }

  const onSingleFileCheck = (id: string) => () => {
    const newArr = map(files, (f) =>
      f.id === id ? { ...f, checked: !f.checked } : f
    )
    const allChecked = every(newArr, 'checked')
    setCheckAllFiles(allChecked ?? false)
    setFiles(newArr)
  }

  const onInputRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value.substring(2, event.target.value.length)
    setInputRange(val)
    if (val)
      selectRange(val).catch((err) => {
        console.error(err)
      })
  }

  const onOutputRangeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value.substring(2, event.target.value.length)
    setOutputRange(val)
    if (val)
      selectRange(val).catch((err) => {
        console.error(err)
      })
  }

  const dispatchPushToUndoStack = (
    preValues: any[][],
    reference: TextcutRectangle,
    type: 'ADD' | 'DELETE'
  ) => {
    const event: UndoEvent = {
      id: nanoid(),
      preValues,
      reference,
      type,
    }
    dispatch(pushToUndoStack(event))
  }

  const onMatchDocumentClick = () => {
    const func = async () => {
      setLoading(true)
      const inputValues = await loadValuesFromARange(inputRange)
      const inputFiles = filter(files, (file) => file.checked)
      // const totalSearchedDocuments = inputFiles.length
      if (inputRange === outputRange) {
        toast.error('Error: Input range is equal to output range', {})
        return
      }
      // toast(`⏳ Searching ${totalSearchedDocuments} documents...`, {
      //   icon: false,
      //   autoClose: false,
      // })
      // console.log('primaryIndiecs:', primaryIndiecs)
      const [result] = await searchInputInFiles(
        inputFiles,
        inputValues,
        primaryIndiecs,
        firstRowHeader === 1 ? true : false,
        isLocalMode
      )
      // toast(
      //   `📄 Searched: ${hasSearched}, remains: ${
      //     totalSearchedDocuments - hasSearched
      //   }`,
      //   {
      //     icon: false,
      //     autoClose: false,
      //   }
      // )
      const arr: any[][] = []
      for (let i = 0; i < result.length; i++) {
        if (every(result[i])) arr.push([...inputValues[i]])
        else if (
          primaryIndiecs.length === result[i].length ||
          primaryIndiecs.length === 0
        ) {
          const tmp = map(inputValues[i], () => '#NOT FOUND')
          arr.push(tmp)
        } else {
          const middleMan: any[] = []
          primaryIndiecs.forEach((index) => middleMan.push(result[i][index]))
          if (every(middleMan)) {
            const tmp: any[] = []
            inputValues[i].forEach((value, index) => {
              if (primaryIndiecs.includes(index)) tmp.push(value)
              else tmp.push('#NOT FOUND')
            })
            arr.push(tmp)
          } else {
            const tmp = map(inputValues[i], () => '#NOT FOUND')
            arr.push(tmp)
          }
        }
      }

      await Excel.run(async (ctx) => {
        const sheet = ctx.workbook.worksheets.getActiveWorksheet()
        const range = sheet.getRange(outputRange)
        range.load(['rowIndex', 'columnIndex', 'values'])
        sheet.load(['id', 'name'])
        await ctx.sync()
        const preValues = range.values
        range.numberFormat = arr.map((row) => row.map((_) => '@'))
        range.values = arr
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < arr[i].length; j++) {
            const cell = range.getCell(i, j)
            if (arr[i][j] === '#NOT FOUND') {
              cell.format.font.color = 'red'
            } else {
              cell.format.font.color = 'black'
            }
            cell.format.horizontalAlignment = Excel.HorizontalAlignment.right
          }
        }
        range.format.autofitColumns()
        range.format.font.name = 'Segoe UI'
        range.format.fill.color = '#C3E9FE'
        // let findings = 0
        // let total = 0
        const unlinkedRange: string[] = []
        const startRowIdx = firstRowHeader ? 1 : 0
        const rects: TextcutRectangle[] = []
        for (let i = startRowIdx; i < result.length; i++) {
          for (let j = 0; j < result[i].length; j++) {
            // total++
            if (!result[i][j]) {
              const r = range.rowIndex + i
              const c = range.columnIndex + j
              unlinkedRange.push(`${numberToLetters(c)}${r + 1}`)
              continue
            }
            // findings++
            const nano = nanoid()
            const vs = result[i][j].boundingPoly.vertices
            const topLeft = findTOpLeftPointFromVertices(vs)
            const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
            const rect: TextcutRectangle = {
              ...result[i][j],
              rangeAddr: `${numberToLetters(range.columnIndex + j)}${
                range.rowIndex + i + 1
              }`,
              sheetId: sheet.id,
              degree: 0,
              x: topLeft?.x ?? 0,
              y: topLeft?.y ?? 0,
              h,
              w,
              cH: 0,
              cW: 0,
              tag: CutTag.DATA_MATCH,
              bindingId: nano,
              sheetName: sheet.name,
              stroke: '#A9E0FE',
              fill: '',
            }
            rects.push(rect)
            dispatchPushToUndoStack(preValues[i][j], rect, 'ADD')
          }
        }
        createBindingForFinding(rects)
          .then(() => dispatch(addMultipleReferences(rects)))
          .then(async () => {
            await ctx.sync()
            // toast(`📈 Findings: ${findings} / ${total}`, {
            //   icon: false,
            //   autoClose: false,
            // })
            // toast(`📈 Unlinked Cell: ${unlinkedRange.join(', ')}`, {
            //   icon: false,
            //   autoClose: false,
            // })
          })
          .catch((err) => {
            console.error(err)
            toast.error(`Error: ${err}`)
          })
      })
    }
    func()
      .catch((err) => {
        console.error(err)
        toast.error(`Error: ${err}`)
      })
      .finally(() => setLoading(false))
  }

  const onPrimaryColumnChange = (index: number) => () => {
    if (primaryIndiecs.includes(index)) {
      const arr = primaryIndiecs.filter((idx) => idx !== index)
      setPrimaryIndices(arr)
    } else {
      setPrimaryIndices([...primaryIndiecs, index])
    }
  }

  const selectRange = (range: string) =>
    Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getActiveWorksheet()
      const r = sheet.getRange(range)
      r.select()
      await ctx.sync()
    })

  const renderErrorTitle = () => {
    if (isLocalMode && localError)
      return getOfficeErrorTitle(localError.message)
    return isOfficeError(error)
      ? getOfficeErrorTitle(error.message)
      : 'Internal Error'
  }

  const inputRangeElem = () => (
    <>
      <div className="bg-white rounded p-1">
        <input
          id="InputRange"
          ref={inputRangeRef}
          type="text"
          className="w-full h-7  my-2 p-1 px-2 border-2 rounded  text-black border-b-[#616161] "
          onClick={() => {
            setInputRangeFocus(true)
            setOutputRangeFocus(false)
          }}
          value={`= ${inputRange}`}
          onChange={onInputRangeChange}
        />
        <div className="flex items-center justify-between">
          <div>
            <input
              type="checkbox"
              value={firstRowHeader}
              checked={firstRowHeader === 1}
              onChange={() => setFirstRowHeader(firstRowHeader ? 0 : 1)}
              onClick={() => setFirstRowHeader(firstRowHeader === 0 ? 1 : 0)}
              size={20}
              className="mr-2 accent-[#107C41]"
            />
            <label htmlFor="include-heading">First row including heading</label>
          </div>
        </div>

        {primaryColumnOptions.length > 0 && (
          <div>
            Compulsory Column(s):
            <ul className="flex ">
              {primaryColumnOptions.map((option, index) => (
                <li key={option} className="w-full border-b border-gray-200 ">
                  <div className="flex items-center ps-3">
                    <input
                      type="checkbox"
                      size={20}
                      className="mr-2 accent-[#107C41]"
                      checked={primaryIndiecs.includes(index)}
                      onChange={onPrimaryColumnChange(index)}
                    />
                    <label
                      className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                      htmlFor="include-heading"
                    >
                      {option}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  )

  const filesElem = ({
    navigate,
  }: {
    navigate: ReturnType<typeof useNavigate>
  }) => {
    return (
      <>
        {(isLoading || isFetching || isFetchingLocal) && (
          <div className="flex justify-center items-center h-screen w-screen">
            <BeatLoader size={20} color="#36d7b7" />
          </div>
        )}
        {(isError || isLocalError) && (
          <div className="flex flex-col p-4">
            <ErrorMsg
              title={renderErrorTitle()}
              content={GENERAL_ERR_CONTENT}
              navigate={navigate}
            />
          </div>
        )}
        {!isLocalError &&
          !isError &&
          !isLoading &&
          !isFetching &&
          !isFetchingLocal && (
            <div className="bg-white mt-1 py-2 rounded max-h-80 h-fit overflow-y-auto">
              <div className="flex justify-between items-center">
                <div className="flex items-center p-2">
                  <input
                    type="checkbox"
                    name="select-all"
                    id=""
                    className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
                    checked={checkAllFiles}
                    onChange={onCheckAllFilesChange}
                  />
                  <label htmlFor="select-all">Select all</label>
                </div>
              </div>

              {files.map((file) => (
                <div
                  className="flex justify-between items-center"
                  key={file.id}
                >
                  <div className="flex items-center p-2">
                    <input
                      type="checkbox"
                      name="select-all"
                      id=""
                      className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
                      checked={file.checked}
                      onChange={onSingleFileCheck(file.id)}
                    />
                    <label htmlFor="select-all">{file.filename}</label>
                  </div>
                </div>
              ))}
            </div>
          )}
      </>
    )
  }

  const outputElem = () => (
    <>
      <div className="bg-white px-3 mt-1 py-2 rounded ">
        <input
          ref={outputRangeRef}
          id="OutputRange"
          type="text"
          className="w-full text-black h-7  my-2 p-1 px-2 border-2 rounded border-b-[#616161]"
          onClick={() => {
            setOutputRangeFocus(true)
            setInputRangeFocus(false)
          }}
          value={`= ${outputRange}`}
          onChange={(e) => onOutputRangeChange(e)}
          // onBlur={onOutputBlur}
        />

        <div className="flex flex-row-reverse py-2">
          <button
            className="bg-[#107C41] text-white text-sm rounded p-2 h-9 flex justify-center items-center"
            onClick={onMatchDocumentClick}
          >
            Match documents
          </button>
        </div>
      </div>
    </>
  )

  const createBindingForFinding = useCallback(
    (elems: TextcutRectangle[]) =>
      Excel.run(async (ctx) => {
        if (!elems.length) return
        const firstElem = elems[0]
        const sheet = ctx.workbook.worksheets.getItemOrNullObject(
          firstElem.sheetId
        )
        sheet.load('isNullObject')
        await ctx.sync()
        if (sheet.isNullObject)
          throw new Error('Something goes wrong on createBindingForFinding')
        for (const elem of elems) {
          const range = sheet.getRange(elem.rangeAddr)
          createBindingAndAddOnSelectionHandler(
            ctx,
            range,
            'Range',
            elem.bindingId
          )
        }
        await ctx.sync()
      }),
    []
  )

  return (
    <div className="flex flex-col h-full w-full p-2 bg-[#f5f5f5] overflow-y-auto">
      <ToastContainer stacked={true} className="max-w-72" />
      {loading && !isError && (
        <div className="flex justify-center items-center h-full w-full">
          <BeatLoader size={20} color="#36d7b7" />
        </div>
      )}
      {!loading && (
        <AnimatePresence>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div>
              <div className="mb-3 bg-white p-2 rounded-2xl">
                <CustomDisclosure
                  header="1. Select input range."
                  element={inputRangeElem()}
                />
              </div>

              <div className="mb-3 bg-white p-2 rounded-2xl">
                <CustomDisclosure
                  element={filesElem({ navigate })}
                  header="2. Select supporting documents or folders if they belong to different types."
                />
              </div>

              <div className="mb-3 bg-white p-2 rounded-2xl">
                <CustomDisclosure
                  header="3. Select output range."
                  element={outputElem()}
                />
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default DataMatching
