import { Dispatch, useEffect, useState } from 'react'
import { DynamoFile, FileStatus } from '../types'
import {
  containProcessingRecord,
  fetchPDFsFromWorkbook,
  savePdfsToWorkbook,
} from '../workbook'
import { useQuery } from 'react-query'
import { getFilesWithFileIds, listAllFilesV2 } from '../api'

const useFiles = (
  isLocalMode: boolean,
  setLoading?: Dispatch<React.SetStateAction<boolean>>
) => {
  const [folderStack, setFolderStack] = useState<string[]>([])
  const [files, setFiles] = useState<DynamoFile[]>([])
  const [isFetchingLocal, setIsFetchingLocal] = useState(false)
  const [isFetchSuccess, setIsFetchSuccess] = useState(false)
  const [refetchLocal, setRefetchLocal] = useState(false)
  const [processingIds, setProcessingIds] = useState<string[]>([])
  const [sync, setSync] = useState(false)
  const [isLocalError, setIsLocalError] = useState(false)
  const [localError, setLocalError] = useState<Office.Error | undefined>(
    undefined
  )

  useEffect(() => {
    setIsFetchingLocal(true)
    setIsFetchSuccess(false)
    setIsLocalError(false)
    setLocalError(undefined)
    let ignore = false
    const func = async () => {
      if (isLocalMode) {
        const results = await fetchPDFsFromWorkbook()
        if (!ignore) {
          setFiles(results)
          // console.log('Finish fetching data from local!!!')
          const [bool, records] = await containProcessingRecord()
          if (bool) {
            setSync(true)
            const ids = records.map((record) => record.fileId)
            setProcessingIds(ids)
          }
        }
        setIsFetchSuccess(true)
      }
    }
    func()
      .catch((err) => {
        console.error(err)
        setIsLocalError(true)
        setLocalError(err)
      })
      .finally(() => {
        if (!ignore) {
          setIsFetchingLocal(false)
          if (setLoading) {
            setLoading(false)
          }
        }
      })
    return () => {
      ignore = true
    }
  }, [isLocalMode, refetchLocal, setLoading])

  const { isError, refetch, isFetching, isSuccess, isLoading, error } =
    useQuery('ALL_FILES', listAllFilesV2, {
      refetchInterval: (data) => {
        const result = data?.find(
          (data) =>
            data.status === FileStatus.PROCESSING ||
            data.status === FileStatus.UPLOADING ||
            data.status === FileStatus.REDACTING
        )
        return result ? 3000 : false
      },
      onSuccess: (success) => {
        if (!isLocalMode) setFiles(success)
        // console.log('fetching data from remote!!!')
      },
      enabled: !isLocalMode,
      refetchOnWindowFocus: false,
    })

  useQuery(
    ['SyncFiles', ...processingIds],
    () => getFilesWithFileIds(processingIds),
    {
      enabled: isLocalMode && sync,
      refetchInterval: 10000,
      onSuccess: async (data) => {
        const every = data.every(
          (file) => file.status !== FileStatus.PROCESSING
        )
        if (every) {
          setSync(false)
          setProcessingIds([])
          const filtered = data.filter(
            (file) => file.status === FileStatus.SUCCEEDED
          )
          await savePdfsToWorkbook(filtered)
          setRefetchLocal(true)
        }
      },
    }
  )

  useEffect(() => {
    if (isFetching) setIsFetchSuccess(false)
    if (isSuccess) setIsFetchSuccess(true)
  }, [isFetching, isSuccess])

  return {
    files,
    isFetchingLocal,
    isError,
    refetch,
    isFetchSuccess,
    setFiles,
    isFetching,
    isLoading,
    refetchLocal,
    setRefetchLocal,
    folderStack,
    setFolderStack,
    error,
    isLocalError,
    localError,
  }
}

export default useFiles
