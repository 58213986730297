import { RefObject, useEffect } from 'react'
import { eventTargetIsNode } from '../utils/guards'

type Props<T extends HTMLElement> = {
  ref: RefObject<T>
  action: (...args: any[]) => any
}

const useClickOutside = <T extends HTMLElement>({ ref, action }: Props<T>) => {
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (
        ref.current &&
        eventTargetIsNode(event.target) &&
        !ref.current.contains(event.target)
      ) {
        action()
      }
    }

    window.addEventListener('mousedown', handler)

    return () => window.removeEventListener('mousedown', handler)
  }, [ref, action])
}

export default useClickOutside
