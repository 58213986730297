import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CutTag } from '../types'
import { RootState } from '../store'

type ToggleState = {
  enable: CutTag[]
}

const fetchInitialToggleState = (): ToggleState => {
  const item = localStorage.getItem('TOGGLE_STATE')
  if (item) return JSON.parse(item)
  const state: ToggleState = {
    enable: [
      CutTag.TEXTCUT,
      CutTag.WORD,
      CutTag.LINE,
      CutTag.TABLE,
      CutTag.DATA_MATCH,
    ],
  }
  localStorage.setItem('TOGGLE_STATE', JSON.stringify(state))
  return state
}

const inititalToggleState: ToggleState = fetchInitialToggleState()

export const toggleSlice = createSlice({
  name: 'TOGGLE',
  initialState: inititalToggleState,
  reducers: {
    addTag: (state, action: PayloadAction<CutTag>) => {
      state.enable = [...state.enable, action.payload]
      window.localStorage.setItem('TOGGLE_STATE', JSON.stringify(state))
    },
    removeTag: (state, action: PayloadAction<CutTag>) => {
      const arr = state.enable.filter((tag) => tag !== action.payload)
      state.enable = arr
      window.localStorage.setItem('TOGGLE_STATE', JSON.stringify(state))
    },
  },
})

export const { addTag, removeTag } = toggleSlice.actions

export const selectEnable = (state: RootState) => state.toggle.enable

export default toggleSlice.reducer
