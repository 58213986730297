import numeral from 'numeral'
import {
  DynamoFile,
  FileCommentTopic,
  FileStatus,
  PopupTextType,
  TextcutRectangle,
} from '../../types'
import { Dispatch } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import { fileContainsComment } from '../../utils/common'
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import { ClipLoader } from 'react-spinners'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

const DELETE_FILE = {
  header: 'Delete File',
  content: 'Are you sure to delete the file?',
}

type Props = {
  files: DynamoFile[]
  onFileClick: (file: DynamoFile) => () => Promise<void>
  showPopup: (popup: PopupTextType) => void
  deletePDF: (fileId: string) => () => void
  isTrimmable: (fileId: string) => any
  trimFile: (fileId: string) => void
  isExportable: (fileId: string) => boolean
  exportPDF: (file: DynamoFile) => () => Promise<void>
  downloadFile: (file: DynamoFile) => () => Promise<void>
  folders?: string[]
  folderStack?: string[]
  setFolderStack?: Dispatch<React.SetStateAction<string[]>>
  localMode: boolean
  references: TextcutRectangle[]
  comments: FileCommentTopic[]
}

type FilePathBreadcrumProps = {
  setFolderStack: Dispatch<React.SetStateAction<string[]>>
  folderStack: string[]
}

const renderIndicator = (fileStatus: string) => {
  if (fileStatus === FileStatus.SUCCEEDED)
    return (
      <div className="w-4 h-4 bg-green-200 rounded-full border-2 border-green-600 mr-2"></div>
    )
  else if (isUnhealthyFile(fileStatus))
    return (
      <div className="w-4 h-4  border-red-600 border-2 rounded-full mr-2"></div>
    )
  else
    return (
      <div className="mr-2">
        <ClipLoader color="#FBBF24" size={14} />
      </div>
    )
}

const renderFilePathBreadcrum = ({
  folderStack,
  setFolderStack,
}: FilePathBreadcrumProps) => {
  const stack = ['Files', ...folderStack]
  const onPathClick = (idx: number) => () => {
    const tmp = stack.slice(0, idx + 1)
    tmp.shift()
    setFolderStack(tmp)
  }

  return (
    <ol className="inline-flex">
      {stack.map((path, i) => (
        <li key={i}>
          <div className="flex items-center">
            <div
              className={`text-sm cursor-pointer ${
                i === stack.length - 1 ? 'font-semibold' : 'font-light'
              }`}
              onClick={onPathClick(i)}
            >
              {path}
            </div>
            {i < stack.length - 1 && (
              <div className="flex items-center">
                <KeyboardArrowRightOutlinedIcon sx={{ fontSize: 20 }} />
              </div>
            )}
          </div>
        </li>
      ))}
    </ol>
  )
}

const isUnhealthyFile = (status: string) =>
  status === FileStatus.FAILED ||
  status === FileStatus.INVALID ||
  status === FileStatus.MALICIOUS

const renderUnhealthyFileBadge = (status: string) => {
  switch (status) {
    case FileStatus.FAILED:
      return 'Failed'
    case FileStatus.INVALID:
      return 'Invalid'
    case FileStatus.MALICIOUS:
      return 'Malicious'
    default:
      return 'Something wrong'
  }
}

const FileUnorderedList = ({
  files,
  onFileClick,
  showPopup,
  deletePDF,
  isTrimmable,
  trimFile,
  isExportable,
  exportPDF,
  downloadFile,
  folders,
  folderStack,
  setFolderStack,
  localMode,
  references,
  comments,
}: Props) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -100, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <ul>
          {folderStack && folderStack?.length !== 0 && setFolderStack && (
            <li
              // onClick={() =>
              //   setFolderStack && folderStack && folderStack.length > 0
              //     ? setFolderStack(folderStack.slice(0, folderStack.length - 1))
              //     : null
              // }
              className="relative rounded-md p-1 py-3"
            >
              {/* <motion.div whileHover={{ scale: 0.98 }}>
                <div className="flex justify-between items-center">
                  <div className="flex w- full">
                    <h3 className="text-2xl font-semibold leading-5">..</h3>
                  </div>
                </div>
              </motion.div> */}
              {renderFilePathBreadcrum({ folderStack, setFolderStack })}
            </li>
          )}
          {folders &&
            folders.length > 0 &&
            folders.map((folder) => (
              <li
                className="relative rounded-md p-1 hover:bg-gray-100 cursor-pointer py-3"
                key={folder}
                onClick={() =>
                  setFolderStack
                    ? setFolderStack([...(folderStack ?? []), folder])
                    : null
                }
              >
                <motion.div whileHover={{ scale: 0.98 }}>
                  <div className="flex justify-between items-center ">
                    <div className="flex w-full items-center">
                      <div className="flex items-center mr-2">
                        <FolderOutlinedIcon sx={{ fontSize: 20 }} />
                      </div>

                      <div className="flex items-center">
                        <h3 className="text-sm font-medium leading-5">
                          {folder}
                        </h3>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </li>
            ))}
          {files.map((file, i) => (
            <li
              key={file.fileId}
              className="relative rounded-md p-1 hover:bg-gray-100 cursor-pointer"
            >
              <motion.div whileHover={{ scale: 0.98 }}>
                <div className="flex justify-between">
                  <div
                    className="flex w-full justify-start items-center"
                    onClick={onFileClick(file)}
                  >
                    {renderIndicator(file.status)}
                    <div className="mr-2">
                      <h3 className="text-sm font-medium leading-5">
                        {file.fileName}
                      </h3>
                    </div>
                    {!isUnhealthyFile(file.status) && (
                      <div className="text-xs font-normal leading-4 text-gray-500 mr-2">
                        {numeral(file.originalFileSize).format('0b').toString()}
                      </div>
                    )}
                    {!isUnhealthyFile(file.status) && !localMode && (
                      <div className="flex mr-2 items-center">
                        <CloudOutlinedIcon sx={{ fontSize: 12 }} />
                      </div>
                    )}
                    {!isUnhealthyFile(file.status) &&
                      fileContainsComment(file, comments) && (
                        <div className="flex mr-2 items-center">
                          <ChatBubbleOutlineOutlinedIcon
                            sx={{ fontSize: 12 }}
                          />
                        </div>
                      )}
                    {isUnhealthyFile(file.status) && (
                      <div className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                        {renderUnhealthyFileBadge(file.status)}
                      </div>
                    )}
                  </div>
                  {/* <div
                    className="flex flex-col w-full"
                    onClick={onFileClick(file)}
                  >
                    

                    <h3 className="text-sm font-medium leading-5">
                      {file.fileName}
                    </h3>

                    <ul className="mt-1 flex items-center space-x-1 text-xs font-normal leading-4 text-gray-500">
                      <li>
                        <span
                          className={`${
                            file.status === FileStatus.PROCESSING ||
                            file.status === FileStatus.UPLOADING
                              ? 'bg-yellow-100 text-yellow-800'
                              : file.status === FileStatus.SUCCEEDED
                              ? 'bg-green-100 text-green-800'
                              : file.status === FileStatus.REDACTING
                              ? 'bg-orange-100 text-orange-800'
                              : 'bg-red-100 text-red-800'
                          } text-xs font-medium me-2 px-2.5 py-0.5 rounded`}
                        >
                          {file.status}
                        </span>
                      </li>
                      <li>&middot;</li>
                      <li className="font-bold">
                        {numeral(file.originalFileSize).format('0b').toString()}
                      </li>
                      <li>&middot;</li>
                      <li>{dayjs(file.createdAt).format('D-MMM-YYYY')}</li>
                      {!localMode && (
                        <>
                          <li>&middot;</li>
                          <li>
                            <CloudOutlinedIcon sx={{ fontSize: 15 }} />
                          </li>
                        </>
                      )}
                      {fileContainsComment(file, comments) && (
                        <>
                          <li>&middot;</li>
                          <li>
                            <ChatBubbleOutlineOutlinedIcon
                              sx={{ fontSize: 15 }}
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div> */}
                  {file.status !== FileStatus.SUCCEEDED &&
                    file.status !== FileStatus.UPLOADING &&
                    file.status !== FileStatus.PROCESSING && (
                      <div
                        onClick={() =>
                          showPopup({
                            ...DELETE_FILE,
                            onClick: deletePDF(file.fileId),
                          })
                        }
                        className="relative group/singledeletefile flex justify-center p-2.5 rounded  hover:bg-gray-500"
                      >
                        <button className="w-full h-full rounded transition ease-in-out delay-100 hover:scale-110">
                          <DeleteOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                        </button>
                        <div
                          className={`absolute ${
                            i === files.length - 1
                              ? 'bottom-14 before:-bottom-1'
                              : 'top-14 before:-top-1'
                          }
                          scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/singledeletefile:scale-100 group-hover/singledeletefile:w-fit before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-1  before:right-0 before:mx-auto`}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  {file.status === FileStatus.SUCCEEDED && (
                    <div className="flex items-center group/ellipses">
                      {isTrimmable(file.fileId) && (
                        <div className="relative group/trim p-2.5 hidden group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                          <button
                            className="w-full h-full transition ease-in-out delay-100 hover:scale-110"
                            onClick={() => trimFile(file.fileId)}
                          >
                            <ContentCutOutlinedIcon sx={{ fontSize: 20 }} />
                          </button>
                          <div
                            className={`absolute ${
                              i === files.length - 1
                                ? 'bottom-14 before:-bottom-1'
                                : 'top-14 before:-top-1'
                            } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/trim:scale-100 group-hover/trim:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
                          >
                            Delete pages without links
                          </div>
                        </div>
                      )}
                      {isExportable(file.fileId) && (
                        <div className="p-2.5 hidden group/export group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                          <button
                            className="w-full h-full transition ease-in-out delay-100 hover:scale-110"
                            onClick={exportPDF(file)}
                          >
                            <SimCardDownloadOutlinedIcon
                              sx={{ fontSize: 20 }}
                            />
                          </button>
                          <div
                            className={`absolute ${
                              i === files.length - 1
                                ? 'bottom-14 before:-bottom-1'
                                : 'top-14 before:-top-1'
                            } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/export:scale-100 group-hover/export:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
                          >
                            Export modified file
                          </div>
                        </div>
                      )}
                      <div className="p-2.5 hidden group/download group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded">
                        <button
                          className="w-full h-full transition ease-in-out delay-100 hover:scale-110"
                          onClick={downloadFile(file)}
                        >
                          <IosShareOutlinedIcon sx={{ fontSize: 20 }} />
                        </button>
                        <div
                          className={`absolute ${
                            i === files.length - 1
                              ? 'bottom-14 before:-bottom-1'
                              : 'top-14 before:-top-1'
                          } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/download:scale-100 group-hover/download:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:left-0  before:right-0 before:mx-auto`}
                        >
                          Export original file
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          showPopup({
                            ...DELETE_FILE,
                            onClick: deletePDF(file.fileId),
                          })
                        }
                        className="p-2.5 hidden group/deletefile group-hover/ellipses:flex group-hover/ellipses:justify-center hover:bg-gray-500 rounded"
                      >
                        <button className="w-full h-full transition ease-in-out delay-100 hover:scale-110">
                          <DeleteOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                        </button>
                        <div
                          className={`absolute ${
                            i === files.length - 1
                              ? 'bottom-14 before:-bottom-1'
                              : 'top-14 before:-top-1'
                          } scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/deletefile:scale-100 group-hover/deletefile:w-fit before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:left-0  before:right-0 before:mx-auto`}
                        >
                          Delete
                        </div>
                      </div>
                      <div className=" p-2.5 flex justify-center h-full w-full group-hover/ellipses:hidden">
                        <button className="w-full h-full">
                          <MoreHorizOutlinedIcon sx={{ fontSize: 20 }} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </motion.div>
            </li>
          ))}
        </ul>
      </motion.div>
    </AnimatePresence>
  )
}

export default FileUnorderedList
