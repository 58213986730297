import { useQuery } from 'react-query'
import { getFileV2, getOcr } from '../api'
import { fetchPDFFromLocal } from '../workbook'
import { useEffect, useState } from 'react'
import { TestDataOcr } from '../types'

const useFile = (id: string, isLocalMode: boolean) => {
  const [pdf, setPDF] = useState<Blob | string>('')
  const [ocr, setOCR] = useState<TestDataOcr | undefined>(undefined)
  const [isFetchLocal, setIsFetchLocal] = useState(false)

  useEffect(() => {
    setIsFetchLocal(true)
    const func = async () => {
      if (isLocalMode) {
        const result = await fetchPDFFromLocal(id ?? '')
        setPDF(result?.pdfBlob ?? '')
        setOCR(result?.ocr ?? undefined)
        // console.log('Fetch file from local!!!')
      }
    }
    func()
      .catch((err) => console.log(err))
      .finally(() => setIsFetchLocal(false))
  }, [id, isLocalMode])

  const { data, isError, isLoading } = useQuery(id, () => getFileV2(id), {
    refetchOnWindowFocus: false,
    onSuccess: (res) => setPDF(res.pdfUrl),
    enabled: !isLocalMode && !!id,
  })
  const { isError: isOcrError, isLoading: isOcrLoading } = useQuery(
    `${id}-${data?.ocrUrl ?? ''}`,
    () => getOcr(data?.ocrUrl ?? ''),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setOCR(res)
        // console.log('Fetch file from remote!!!')
      },
      enabled: !isLocalMode && data !== undefined,
    }
  )
  return {
    pdf,
    ocr,
    isError,
    isLoading,
    isOcrError,
    isOcrLoading,
    isFetchLocal,
  }
}

export default useFile
