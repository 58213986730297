import { useEffect } from 'react'

export const useOnChanged = () => {
  useEffect(() => {
    let ignore = false
    let registered = false
    let eventResult: any = undefined

    const onChangedEvent = async () => {
      await Excel.run(async (context) => {
        const sheets = context.workbook.worksheets
        if (ignore) return
        eventResult = sheets.onChanged.add(async (event) => {
          const e = new CustomEvent('OnExcelWorkbooksRangeValueChange', {
            detail: JSON.stringify(event),
          })
          dispatchEvent(e)
        })
        await context.sync()
        registered = true
        console.log('The worksheet onChanged handler is registered.')
      })
    }

    onChangedEvent().catch((err) => console.error(err))

    return () => {
      ignore = true
      if (registered && eventResult) {
        const tmp =
          eventResult as unknown as OfficeExtension.EventHandlerResult<Excel.WorksheetSingleClickedEventArgs>
        Excel.run(tmp.context, async (context) => {
          tmp.remove()
          await context.sync()

          eventResult = undefined
          console.log('Event onChanged handler successfully removed.')
        })
      }
    }
  }, [])
}

export default useOnChanged
