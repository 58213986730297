import { Layer, Rect, Stage, Image } from 'react-konva'
import {
  CanvasArea,
  Cut,
  CutTag,
  FileCommentContent,
  FileCommentTopic,
  First,
  KonvaRectangle,
  Rectangle,
  RotationType,
  TextBlock,
  TextcutRectangle,
  UndoEvent,
} from '../../types'
import {
  Dispatch,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { KonvaEventObject } from 'konva/lib/Node'
import {
  fillterDataBasedOnCutType,
  findRowAndColFromCells,
  transformCells,
} from '../../utils/ocr'
import {
  calculateHeightandWidth,
  convertToFourPointVertices,
  filterNodes,
  findTOpLeftPointFromVertices,
  rotateAVertex,
  rotateOcrBox,
  sortTextcutInReadableWay,
} from '../../utils/spatial'
import numeral from 'numeral'
import { Rectangle as QuadtreeRectangle } from '@timohausmann/quadtree-ts'
import { forEach, map, zip } from 'lodash'
import {
  createBindingAndAddOnSelectionHandler,
  getRangeValues,
  numberToLetters,
} from '../../workbook'
import useQuadTree from '../../hooks/useQuadTree'
import { nanoid } from 'nanoid'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import {
  addReferece,
  deleteReferencesWithBindingId,
  deleteReferencesWithBindingIds,
} from '../../slice/referenceSlice'
import { useCutEvent } from '../../hooks/useCutEvent'
import { convertPossibleAmountToNumber, numberlize } from '../../utils/number'
import useConfirmRedactListener from '../../hooks/useConfirmRedactListener'
import Popup from '../Popup'
import { redactFile, startProcessingRedactedDoc } from '../../api'
import { useNavigate } from 'react-router-dom'
import { pushToUndoStack } from '../../slice/undoSlice'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import dayjs from 'dayjs'
import { addComment, removeCommentById } from '../../slice/commentSlice'
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { setShowSelectionOnlyRange } from '../../slice/showSelectionOnlySlice'
import useImage from 'use-image'
import useUpdateRibbon from '../../hooks/useUpdateRibbon'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getOfficeRuntimeAccessToken } from '../../utils/common'

const REDACTION_CONTENT =
  "Are you sure you want to save all the redactions? This opertion cannot be undone, and you' lose all links for given file"

type Props = {
  width: number
  height: number
  canvasArea: CanvasArea
  pages: Map<string, TextBlock>
  tables: Map<string, TextBlock>
  cells: Map<string, TextBlock>
  lines: Map<string, TextBlock>
  words: Map<string, TextBlock>
  filePage: number
  fileId: string
  rotationDegree: RotationType
  setShowConfirmRedact: Dispatch<React.SetStateAction<boolean>>
  setPageLoading: Dispatch<React.SetStateAction<boolean>>
  searchAllItem: string[]
}

const getCutTypeStyle = (type: Cut): { stroke: string; fill: string } => {
  if (type === Cut.SUM) {
    return {
      stroke: '#FDE6B6',
      fill: '',
    }
  } else if (type === Cut.TEXTCUT) {
    return {
      stroke: '#B6FBFB',
      fill: '',
    }
  } else {
    return {
      stroke: 'rgb(0,0,0)',
      fill: 'rgb(0,0,0)',
    }
  }
}

type CustomContextualMenuProps = {
  x: number
  y: number
  menuItems: { onClick: MouseEventHandler<HTMLLIElement>; elem: JSX.Element }[]
}

const CustomContextualMenu = ({
  x,
  y,

  menuItems,
}: CustomContextualMenuProps) => {
  return (
    <div
      style={{
        top: y,
        left: x,
      }}
      className={`absolute  bg-white divide-y divide-gray-100 rounded-lg shadow w-fit h-fit`}
    >
      <ul>
        {menuItems.map((item, i) => (
          <li
            className="px-2 py-2 rounded-lg font-semibold hover:bg-gray-100 w-full"
            onClick={item.onClick}
            key={i}
          >
            {item.elem}
          </li>
        ))}
      </ul>
    </div>
  )
}

enum LayerRightClickType {
  REDACT = 'REDACT',
  CUT = 'CUT',
  STAGE = 'STAGE',
}

const TextArea = (
  ref: React.LegacyRef<HTMLInputElement>,
  comment: FileCommentTopic | undefined,
  dispatch: ReturnType<typeof useAppDispatch>,
  onClick: (...args: any[]) => any,
  userEmail: string,
  setPopup: Dispatch<React.SetStateAction<boolean>>
) => {
  const removeComments = () => {
    if (!comment) return
    return dispatch(removeCommentById(comment.id))
      .catch((err) => console.error(err))
      .finally(() => setPopup(false))
  }

  const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      const func = onClick as unknown as (...args: any) => void
      func(comment)
    }
  }

  return (
    <>
      <div className="relative w-full cursor-default overflow-hidden rounded-sm text-left shadow-md  focus:ring-2 focus:ring-teal-300/75 focus:ring-offset-2 focus:ring-offset-teal-300 sm:text-sm">
        {!comment && (
          <>
            <input
              ref={ref}
              className="w-full border-none rounded-sm  py-2 pl-3 pr-10 text-sm leading-5 text-gray-900"
              placeholder={'Comment here'}
              maxLength={140}
              onKeyDown={onEnterKeyDown}
            />
            <button
              className="absolute inset-y-0 right-0 items-center pr-2"
              onClick={() => onClick(comment)}
            >
              <ArrowCircleUpOutlinedIcon />
            </button>
          </>
        )}
        {comment && (
          <div className="flex flex-col p-2">
            <div className="overflow-y-auto max-h-60">
              <div className="flex justify-end">
                <button
                  className="hover:bg-gray-200 p-1"
                  onClick={removeComments}
                >
                  <DeleteOutlineOutlinedIcon />
                </button>
              </div>
              <div className="divide-x-2">
                <hr />
              </div>
              {[...comment.comments]
                .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
                .map((content, idx) => (
                  <div key={`${comment.id}-${idx}`} className="mt-2">
                    <div className="bg-white p-2 rounded-lg ">
                      <div className="flex mt-2">
                        <div className="relative flex items-center justify-center w-7 h-7 overflow-hidden bg-blue-200 rounded-full mr-1 ">
                          <span className="font-medium text-white">
                            {content.createdBy[0].toLocaleUpperCase()}
                          </span>
                        </div>
                        <div className="flex items-center mr-1">
                          <h3 className="text-md font-normal text-black">
                            {content.createdBy}
                          </h3>
                        </div>
                        <div className="flex items-center">
                          <h4 className="text-md font-light">
                            {dayjs(content.createdAt).format('DD/MM/YYYY')}
                          </h4>
                        </div>
                        {/* <button
                          className="hover:bg-gray-200 p-2"
                          onClick={() => removeComment(comment.id)}
                        >
                          <FaRegTrashAlt size={20} />
                        </button> */}
                      </div>
                      <div className="flex mt-2">
                        <p className="text-black text-base indent-1">
                          {content.content}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex mt-2 items-center">
              <div className="relative flex items-center justify-center w-7 h-7 overflow-hidden bg-blue-200 rounded-full mr-1 ">
                <span className="font-medium text-white">{userEmail}</span>
              </div>
              <div className="relative flex w-full">
                <input
                  ref={ref}
                  className="w-full border-none rounded-sm  py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 bg-gray-200"
                  placeholder={'Reply here'}
                  maxLength={140}
                  onKeyDown={onEnterKeyDown}
                />
                <button
                  className="absolute inset-y-0 right-0 items-center pr-2"
                  onClick={() => onClick(comment)}
                >
                  <ArrowCircleUpOutlinedIcon />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

type RenderImageProps = {
  key: string
  x: number
  y: number
  image: First<ReturnType<typeof useImage>>
  onClick: (...args: any[]) => void
}

const RenderImage = ({ key, x, y, image, onClick }: RenderImageProps) => {
  return (
    <Image
      key={key}
      image={image}
      width={30}
      height={30}
      x={x}
      y={y}
      draggable
      onClick={() => onClick(key)}
    />
  )
}

const KonvaContainer = ({
  width,
  height,
  pages,
  tables,
  cells,
  lines,
  words,
  filePage,
  fileId,
  rotationDegree,
  setShowConfirmRedact,
  setPageLoading,
  searchAllItem,
}: Props) => {
  const [drawing, setDrawing] = useState(false)
  const [startPos, setStartPos] = useState<[number, number]>([-1, -1])
  const [endPos, setEndPos] = useState<[number, number]>([-1, -1])
  const [isSelecting, setIsSelecting] = useState(false)
  useCutEvent(toast)
  const cutType = useAppSelector((state) => state.cutMode.type)
  const [hoverIdx, setHoverIdx] = useState<number>(-1)
  const [strokeIdx, setStrokeIdx] = useState<number>(-1)
  const [isMouseMove, setIsMouseMove] = useState(false)
  const [popup, setPopup] = useState(false)
  const navigate = useNavigate()
  const [contextMenuPosition, setContextualMenuPosition] = useState<
    [number, number]
  >([-1, -1])
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [clickedRect, setClickedRect] = useState<string | undefined>(undefined)
  const [layerRightClickType, setLayerRightClickType] = useState<
    LayerRightClickType | undefined
  >(undefined)
  const [popupType, setPopupType] = useState<LayerRightClickType | undefined>(
    undefined
  )
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined)
  const textareaRef = useRef<HTMLInputElement>(null)
  const [image] = useImage('/icons/chat-bubble-left.svg')
  const [commentTopicId, setCommentTopicId] = useState<string | undefined>(
    undefined
  )

  // const enable = useAppSelector((state) => state.toggle.enable)
  const references = useAppSelector((state) => state.references.references)
  const showSelectionOnly = useAppSelector((state) => state.showSelectionOnly)
  const comments = useAppSelector((state) => state.comments.comments)
  const dispatch = useAppDispatch()

  const textcutRects: TextcutRectangle[] = []
  const referenceRect: TextcutRectangle[] = []
  const dataMatchRects: TextcutRectangle[] = []
  const sumRects: TextcutRectangle[] = []
  const redactRects: TextcutRectangle[] = []

  references.forEach((reference) => {
    if (reference.tag === CutTag.DATA_MATCH) {
      dataMatchRects.push(reference)
    } else if (reference.tag === CutTag.TEXTCUT) {
      textcutRects.push(reference)
    } else if (reference.tag === CutTag.SUM) {
      sumRects.push(reference)
    } else if (reference.tag === CutTag.REDACT) {
      redactRects.push(reference)
    } else {
      referenceRect.push(reference)
    }
  })

  useUpdateRibbon()
  const redacts = redactRects.filter((rect) => rect.fileId === fileId)
  useConfirmRedactListener(setPopup, () =>
    setPopupType(LayerRightClickType.REDACT)
  )

  useEffect(() => {
    if (redacts.length > 0) {
      setShowConfirmRedact(true)
    }

    return () => setShowConfirmRedact(false)
  }, [redacts, setShowConfirmRedact])

  useEffect(() => {
    getOfficeRuntimeAccessToken()
      .then((token) => setUserEmail(token.preferred_username))
      .catch((err) => {
        toast.error('Office Authentication Error:', err)
      })
  }, [])

  const dispatchPushToUndoStack = useCallback(
    (
      preValues: any[][],
      reference: TextcutRectangle,
      type: 'ADD' | 'DELETE'
    ) => {
      const event: UndoEvent = {
        id: nanoid(),
        preValues,
        reference,
        type,
      }
      dispatch(pushToUndoStack(event))
    },
    [dispatch]
  )

  const confirmRedactPopup = async () => {
    try {
      setPageLoading(true)
      // await dispatch(deleteReferencesWithFileIds([fileId]))
      await dispatch(
        deleteReferencesWithBindingIds(
          redacts.map((redact) => redact.bindingId)
        )
      )
      await redactFile(redacts, fileId)
      await startProcessingRedactedDoc(fileId) // trigger doc extraction after redaction complete
      navigate('/files')
    } catch (err) {
      console.error(err)
    } finally {
      setPopup(false)
      setPageLoading(false)
      setPopupType(undefined)
    }
  }

  const currentPageOcr = pages.get(String(filePage)) as TextBlock
  const [ocrPageH, ocrPageW] = calculateHeightandWidth(
    currentPageOcr?.boundingPoly.vertices[0],
    currentPageOcr?.boundingPoly.vertices[1],
    currentPageOcr?.boundingPoly.vertices[2],
    currentPageOcr?.boundingPoly.vertices[3]
  )

  const filteredData = fillterDataBasedOnCutType(
    currentPageOcr,
    tables,
    cells,
    lines,
    words,
    cutType
  )

  const ocrW =
    rotationDegree.curr === 0 || rotationDegree.curr === 180
      ? ocrPageW
      : ocrPageH
  const ocrH =
    rotationDegree.curr === 0 || rotationDegree.curr === 180
      ? ocrPageH
      : ocrPageW
  const ratioX = numeral(ocrW).divide(width)
  const ratioY = numeral(ocrH).divide(height)

  const quadTree = useQuadTree({
    cutType,
    filteredData,
    height,
    ratioX,
    ratioY,
    width,
  })

  const mapTextBlockToKonvaRect = (data: TextBlock): KonvaRectangle => {
    const vertices = data.boundingPoly.vertices
    const topLeft = findTOpLeftPointFromVertices(vertices)
    const [h, w] = calculateHeightandWidth(
      vertices[0],
      vertices[1],
      vertices[2],
      vertices[3]
    )
    return {
      x: topLeft?.x ?? -1,
      y: topLeft?.y ?? -1,
      w,
      h,
      ocrId: data.id,
      stroke:
        cutType === Cut.WORD || cutType === Cut.LINE ? undefined : 'green',
    }
  }

  const rects = map(filteredData, mapTextBlockToKonvaRect)
  const searchAllItemRects = Array.from(lines.values())
    .filter((line) => searchAllItem.includes(line.id))
    .map(mapTextBlockToKonvaRect)

  const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (showContextMenu) setShowContextMenu(false)
    if (e.evt.button !== 0) return
    const stage = e.target.getStage()?.getPointerPosition()
    if (
      stage &&
      (cutType === Cut.TEXTCUT || cutType === Cut.SUM || cutType === Cut.REDACT)
    ) {
      setDrawing(true)
      setStartPos([stage.x, stage.y])
    }
  }

  const onMouseUp = async () => {
    if (!drawing) return
    setDrawing(false)
    const rect: Rectangle = {
      x: Math.min(startPos[0], endPos[0]),
      y: Math.min(startPos[1], endPos[1]),
      w: Math.abs(startPos[0] - endPos[0]),
      h: Math.abs(startPos[1] - endPos[1]),
    }

    if (
      (cutType === Cut.TEXTCUT ||
        cutType === Cut.SUM ||
        cutType === Cut.REDACT) &&
      isMouseMove
    ) {
      const nodes = quadTree.retrieve(
        new QuadtreeRectangle({
          x: rect.x,
          y: rect.y,
          width: rect.w,
          height: rect.h,
        })
      )
      const filteredNodes = filterNodes(
        new QuadtreeRectangle({
          x: rect.x,
          y: rect.y,
          width: rect.w,
          height: rect.h,
        }),
        nodes
      )
      const sortedNodes = sortTextcutInReadableWay(filteredNodes)
      let text = ''
      if (cutType === Cut.TEXTCUT)
        forEach(sortedNodes, (node) => (text += node?.data?.description + ' '))
      else if (cutType === Cut.SUM) {
        const tmp = sortedNodes.map((node) =>
          numberlize(
            node.data?.description ?? '',
            convertPossibleAmountToNumber
          )
        )
        const tmp2 = tmp.filter((val) => !isNaN(val))
        text = tmp2.length > 0 ? `=${tmp2.join('+')}` : 'NaN'
        if (text === 'NaN') {
          setIsSelecting(false)
          setStartPos([-1, -1])
          setEndPos([-1, -1])
          setIsMouseMove(false)
          return
        }
      }

      if (cutType === Cut.REDACT) {
        const nano = nanoid()
        const elem: TextcutRectangle = {
          ...rect,
          x: rect.x * (ratioX.value() ?? 1),
          y: rect.y * (ratioY.value() ?? 1),
          w: rect.w * (ratioX.value() ?? 1),
          h: rect.h * (ratioY.value() ?? 1),
          rangeAddr: nano,
          fileId,
          filePage,
          sheetId: nano,
          degree: rotationDegree.curr,
          ocrW:
            rotationDegree.curr === 0 || rotationDegree.curr === 180
              ? ocrPageW
              : ocrPageH,
          ocrH:
            rotationDegree.curr === 0 || rotationDegree.curr === 180
              ? ocrPageH
              : ocrPageW,
          tag: CutTag.REDACT,
          bindingId: nano,
          sheetName: '',
          ...getCutTypeStyle(cutType),
        }
        dispatch(addReferece(elem))
          .then(() => dispatchPushToUndoStack([['']], elem, 'ADD'))
          .catch((err) => console.error(err))
      } else if (text !== '') {
        Excel.run(async (ctx) => {
          const sheet = ctx.workbook.worksheets.getActiveWorksheet()
          const range = ctx.workbook.getSelectedRange()
          sheet.load(['id', 'name'])
          range.load(['rowIndex', 'columnIndex'])
          await ctx.sync()
          const firstCell = sheet.getCell(range.rowIndex, range.columnIndex)
          const nano = nanoid()
          range.load(['rowIndex', 'columnIndex'])
          sheet.load('id')
          firstCell.load(['address', 'values'])
          await ctx.sync()
          const [, rangeAddr] = firstCell.address.split('!')
          let box: any = {
            ...rect,
            x: rect.x * (ratioX.value() ?? 1),
            y: rect.y * (ratioY.value() ?? 1),
            w: rect.w * (ratioX.value() ?? 1),
            h: rect.h * (ratioY.value() ?? 1),
            rangeAddr,
            fileId,
            filePage,
          }

          const elem: TextcutRectangle = {
            ...box,
            row: 0,
            col: 0,
            startRow: range.rowIndex,
            startCol: range.columnIndex,
            sheetId: sheet.id,
            fileId: fileId,
            filePage: filePage,
            rangeAddr,
            referenceId: `${
              cutType === Cut.TEXTCUT ? 'Textcut' : 'Sum'
            }>>${nano}>>0>>0`,
            degree: rotationDegree.curr,
            ocrW:
              rotationDegree.curr === 0 || rotationDegree.curr === 180
                ? ocrPageW
                : ocrPageH,
            ocrH:
              rotationDegree.curr === 0 || rotationDegree.curr === 180
                ? ocrPageH
                : ocrPageW,
            tag: cutType === Cut.TEXTCUT ? CutTag.TEXTCUT : CutTag.SUM,
            bindingId: nano,
            sheetName: sheet.name,
            ...getCutTypeStyle(cutType),
          }
          const preValues = firstCell.values
          if (cutType === Cut.TEXTCUT) firstCell.numberFormat = [['@']]
          firstCell.values = [[text]]
          firstCell.format.font.name = 'Segoe UI'
          firstCell.format.fill.color =
            cutType === Cut.TEXTCUT ? '#CCFCFC' : '#FEEECC'
          firstCell.format.autofitColumns()
          createBindingAndAddOnSelectionHandler(ctx, firstCell, 'Range', nano)
          await ctx.sync()
          dispatchGeneralAddRefActions(elem, preValues).catch((err) =>
            console.error(err)
          )
        }).catch((err) => console.log(err))
      }
    }
    setIsSelecting(false)
    setStartPos([-1, -1])
    setEndPos([-1, -1])
    setIsMouseMove(false)
  }

  const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (!drawing) {
      return
    }
    if (!isSelecting) setIsSelecting(true)
    const stage = e.target.getStage()?.getPointerPosition()

    if (!stage) {
      setIsSelecting(false)
      setDrawing(false)
      setStartPos([-1, -1])
      return
    }
    setIsMouseMove(true)
    setEndPos([stage.x, stage.y])
  }

  const onHoveredClick =
    (rect: KonvaRectangle) => (evt: KonvaEventObject<MouseEvent>) => {
      if (cutType === Cut.TABLECUT) {
        const transposed =
          evt.evt.button === 0 ? false : evt.evt.button === 2 ? true : undefined
        if (transposed === undefined) return
        const table = tables.get(rect.ocrId)
        if (!table) return
        const childIds = table?.relationships ? table?.relationships[0].ids : []
        const tableCells = childIds
          .filter((id) => cells.has(id))
          .map((id) => cells.get(id)!)
        const transformedTableCells = transformCells(tableCells, words)
        const [row, col] = findRowAndColFromCells(transformedTableCells)
        const matrix: string[][] = Array.from({ length: row }, () =>
          new Array(col).fill('')
        )
        const numberFormatMatrix: string[][] = Array.from({ length: row }, () =>
          new Array(col).fill('@')
        )
        forEach(
          transformedTableCells,
          (cell) =>
            (matrix[cell.rowIndex - 1][cell.columnIndex - 1] = cell.text
              ? cell.text
              : '')
        )
        Excel.run(async (ctx) => {
          const range = ctx.workbook.getSelectedRange()
          const sheet = ctx.workbook.worksheets.getActiveWorksheet()
          range.load('columnIndex')
          range.load('rowIndex')
          sheet.load(['id', 'name'])
          await ctx.sync()
          const [startRow, startCol] = [range.rowIndex, range.columnIndex]
          const [endRow, endCol] = transposed
            ? [startRow + col - 1, startCol + row - 1]
            : [startRow + row - 1, startCol + col - 1]

          const matrixRange = `${numberToLetters(startCol)}${
            startRow + 1
          }:${numberToLetters(endCol)}${endRow + 1}`
          const outputRange = ctx.workbook.worksheets
            .getActiveWorksheet()
            .getRange(matrixRange)
          outputRange.load([
            'rowIndex',
            'columnIndex',
            'rowCount',
            'columnCount',
            'address',
            'values',
          ])

          await ctx.sync()
          const preValues = outputRange.values
          outputRange.numberFormat = transposed
            ? zip(...numberFormatMatrix)
            : numberFormatMatrix
          outputRange.values = transposed ? zip(...matrix) : matrix
          outputRange.format.borders.getItem('EdgeBottom').style = 'Continuous'
          outputRange.format.borders.getItem('EdgeLeft').style = 'Continuous'
          outputRange.format.borders.getItem('EdgeRight').style = 'Continuous'
          outputRange.format.borders.getItem('EdgeTop').style = 'Continuous'
          outputRange.format.autofitColumns()
          outputRange.format.autofitRows()
          outputRange.format.font.name = 'Segoe UI'
          outputRange.format.fill.color = '#CCFCFC'
          const nano = nanoid()
          const vs = table.boundingPoly.vertices
          const topLeft = findTOpLeftPointFromVertices(vs)
          const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
          const [, rangeAddr] = outputRange.address.split('!')
          const elem: TextcutRectangle = {
            sheetId: sheet.id,
            fileId: fileId,
            filePage: filePage,
            rangeAddr,
            ocrH,
            ocrW,
            degree: rotationDegree.curr,
            x: topLeft?.x ?? 0,
            y: topLeft?.y ?? 0,
            h,
            w,
            tag: CutTag.TABLE,
            bindingId: nano,
            sheetName: sheet.name,
            stroke: '#B6FBFB',
            fill: '',
          }
          createBindingAndAddOnSelectionHandler(ctx, outputRange, 'Range', nano)
          dispatchGeneralAddRefActions(elem, preValues).catch((err) =>
            console.error(err)
          )
        })
          .catch((err) => console.error(err))
          .finally(() => setHoverIdx(-1))
      } else if (
        evt.evt.button === 0 &&
        (cutType === Cut.WORD || cutType === Cut.LINE)
      ) {
        const collection = cutType === Cut.WORD ? words : lines
        const tag = cutType === Cut.WORD ? CutTag.WORD : CutTag.LINE
        const item = collection.get(rect.ocrId)
        if (!item) return
        Excel.run(async (ctx) => {
          const sheet = ctx.workbook.worksheets.getActiveWorksheet()
          const range = ctx.workbook.getSelectedRange()
          const firstCell = range.getCell(0, 0)
          sheet.load(['id', 'name'])
          firstCell.load(['rowIndex', 'columnIndex', 'address', 'values'])
          await ctx.sync()
          const preValues = firstCell.values
          firstCell.numberFormat = [['@']]
          firstCell.values = [[item.description]]
          firstCell.format.autofitColumns()
          firstCell.format.font.name = 'Segoe UI'
          firstCell.format.fill.color = '#CCFCFC'
          const nano = nanoid()
          const vs = item.boundingPoly.vertices
          const topLeft = findTOpLeftPointFromVertices(vs)
          const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
          const [, rangeAddr] = firstCell.address.split('!')
          const elem: TextcutRectangle = {
            sheetId: sheet.id,
            fileId: fileId,
            filePage: filePage,
            rangeAddr: rangeAddr,
            ocrH,
            ocrW,
            degree: rotationDegree.curr,
            x: topLeft?.x ?? 0,
            y: topLeft?.y ?? 0,
            h,
            w,
            tag,
            bindingId: nano,
            sheetName: sheet.name,
            stroke: '#B6FBFB',
            fill: '',
          }
          createBindingAndAddOnSelectionHandler(ctx, firstCell, 'Range', nano)
          dispatchGeneralAddRefActions(elem, preValues).catch((err) =>
            console.log(err)
          )
        })
          .catch((err) => console.error(err))
          .finally(() => setHoverIdx(-1))
      }
    }

  const onTextcutRectClick = (rect: TextcutRectangle) => () => {
    Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getItem(rect.sheetId)
      sheet.load('isNullObject')
      await ctx.sync()
      if (sheet.isNullObject) return
      sheet.getRange(rect.rangeAddr).select()
      sheet.activate()
    }).catch((err) => console.log(err))
  }

  const draw = (arr: TextcutRectangle[]) =>
    arr
      .filter((rect) => rect.fileId === fileId && rect.filePage === filePage)
      .filter((rect) => {
        if (!showSelectionOnly.showSelectionOnly) return true
        if (
          rect.sheetId === showSelectionOnly.sheetId &&
          rect.rangeAddr === showSelectionOnly.rangeAddress
        ) {
          return true
        }
        return false
      })
      .map((rect) => {
        if (rect.degree !== rotationDegree.curr) {
          const targetDegree =
            (((rotationDegree.curr - rect.degree) % 360) + 360) % 360
          const input = convertToFourPointVertices(
            rect.x,
            rect.y,
            rect.w,
            rect.h
          )
          const arr = rotateOcrBox(input, targetDegree, rect.ocrW, rect.ocrH)
          const topLeft = findTOpLeftPointFromVertices(arr)
          const [hh, ww] = calculateHeightandWidth(
            arr[0],
            arr[1],
            arr[2],
            arr[3]
          )
          if (!topLeft) return rect
          rect = { ...rect, x: topLeft.x, y: topLeft.y, h: hh, w: ww }
          return rect
        } else {
          return rect
        }
      })
      .map((r) => (
        <Rect
          key={r.bindingId}
          x={numeral(r.x).divide(ratioX.value()).difference(2) ?? -1}
          y={numeral(r.y).divide(ratioY.value()).difference(2) ?? -1}
          width={numeral(r.w).divide(ratioX.value()).add(4).value() ?? -1}
          height={numeral(r.h).divide(ratioY.value()).add(4).value() ?? -1}
          fill={r.fill}
          stroke={r.stroke}
          strokeWidth={3}
          onClick={onTextcutRectClick(r)}
          onContextMenu={(evt) => {
            layerRightClickHandler(evt, r.bindingId, LayerRightClickType.CUT)
          }}
          onMouseEnter={onLinkMouseEnter(r.tag)}
          onMouseLeave={onLinkMouseLeave(r.tag, r.fill)}
        />
      ))

  const layerRightClickHandler = (
    evt: KonvaEventObject<PointerEvent>,
    bindingId: string,
    type: LayerRightClickType
  ) => {
    evt.cancelBubble = true
    const stage = evt.currentTarget.getStage()
    if (!stage) return
    const vector = stage.getPointerPosition()
    const { height, width } = stage.getSize()
    if (!vector) return
    let x = vector.x
    let y = vector.y
    if (x + 80 > width) {
      x -= x + 80 - width
    }
    if (y + 40 > height) {
      y -= y + 40 - height
    }
    setClickedRect(bindingId)
    setContextualMenuPosition([x, y])
    setShowContextMenu(true)
    setLayerRightClickType(type)
  }

  const onContextMenuDeleteClicked = () => {
    if (clickedRect) {
      const item = references.find((ref) => ref.bindingId === clickedRect)

      dispatch(deleteReferencesWithBindingId(clickedRect))
        .then(async () => {
          if (item) {
            if (item.tag === CutTag.REDACT)
              dispatchPushToUndoStack([['']], item, 'DELETE')
            else {
              const values = await getRangeValues(item.sheetId, item.rangeAddr)
              dispatchPushToUndoStack(values, item, 'DELETE')
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setClickedRect(undefined)
          setShowContextMenu(false)
          setLayerRightClickType(undefined)
        })
    }
  }

  const onContextMenuCommentClick = () => {
    if (rotationDegree.curr) {
      toast.warning('Comment is diabled when file is rotated.', {
        autoClose: 1000,
      })
    } else {
      setPopupType(LayerRightClickType.STAGE)
      setPopup(true)
    }
    setShowContextMenu(false)
    setLayerRightClickType(undefined)
  }

  const onPopupCancel = () => {
    setPopup(false)
    setPopupType(undefined)
    if (clickedRect) setClickedRect(undefined)
    if (commentTopicId) setCommentTopicId(undefined)
  }

  const onCommentPopupConfirm = (topic: FileCommentTopic | undefined) => {
    setPopup(false)
    setPopupType(undefined)
    const [x, y] = contextMenuPosition
    const convertedX = ratioX.multiply(x).value(),
      convertedY = ratioY.multiply(y).value()
    if (!convertedX || !convertedY || isNaN(convertedX) || isNaN(convertedY))
      return
    if (
      textareaRef.current?.value &&
      textareaRef.current.value.replace(/\s/g, '') &&
      userEmail
    ) {
      const createdAt = dayjs().valueOf()
      const commentTopic: FileCommentTopic = topic
        ? { ...topic }
        : {
            id: nanoid(),
            createdAt,
            comments: [],
            createdBy: userEmail,
            fileId,
            filePage,
            x: convertedX,
            y: convertedY,
            ocrH,
            ocrW,
            degree: rotationDegree.curr,
          }

      const comment: FileCommentContent = {
        content: textareaRef.current.value,
        createdAt,
        createdBy: userEmail,
      }
      commentTopic.comments = [...commentTopic.comments, comment]
      dispatch(addComment(commentTopic)).catch((err) => console.error(err))
    }
  }

  const renderPopup = (setPopup: Dispatch<React.SetStateAction<boolean>>) => {
    if (popupType === LayerRightClickType.REDACT)
      return (
        <Popup
          isOpen={popup}
          popupHeader="Redaction"
          setIsOpen={setPopup}
          secondBtn={{ text: 'Cancel', onClick: onPopupCancel }}
          firstBtn={{ text: 'Confirm', onClick: confirmRedactPopup }}
          popupContent={REDACTION_CONTENT}
        />
      )
    else if (popupType === LayerRightClickType.STAGE) {
      const topic = comments.find((topic) => topic.id === commentTopicId)
      return (
        <Popup
          initialRef={textareaRef}
          isOpen={popup}
          popupHeader={'Comment'}
          setIsOpen={setPopup}
          secondBtn={{ text: 'Cancel', onClick: onPopupCancel }}
          firstBtn={{ text: 'Confirm', onClick: () => {} }}
          popupContent={TextArea(
            textareaRef,
            topic,
            dispatch,
            onCommentPopupConfirm,
            userEmail ? userEmail[0].toLocaleUpperCase() : 'U',
            setPopup
          )}
          hideBtns={true}
          hideHeader={true}
        />
      )
    } else return <></>
  }

  const renderContextMenuItems = (): Pick<
    CustomContextualMenuProps,
    'menuItems'
  > => {
    if (layerRightClickType === LayerRightClickType.REDACT) {
      return {
        menuItems: [
          {
            elem: (
              <button className="flex items-center justify-center">
                <DeleteOutlineOutlinedIcon sx={{ fontSize: 15 }} />
                <h3 className="ml-2">Remove Redaction</h3>
              </button>
            ),
            onClick: onContextMenuDeleteClicked,
          },
        ],
      }
    } else if (layerRightClickType === LayerRightClickType.STAGE) {
      return {
        menuItems: [
          {
            elem: (
              <button className="flex items-center justify-center">
                <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 15 }} />
                <h3 className="ml-2">Comment</h3>
              </button>
            ),
            onClick: onContextMenuCommentClick,
          },
        ],
      }
    }
    return {
      menuItems: [
        {
          elem: (
            <button className="flex items-center justify-center">
              <DeleteOutlineOutlinedIcon sx={{ fontSize: 15 }} />
              <h3 className="ml-2">Delete</h3>
            </button>
          ),
          onClick: onContextMenuDeleteClicked,
        },
        // {
        //   elem: (
        //     <button className="flex items-center justify-center">
        //       <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 15 }} />
        //       <h3 className="ml-2">Comment</h3>
        //     </button>
        //   ),
        //   onClick: onContextMenuCommentClick,
        // },
      ],
    }
  }
  const dispatchGeneralAddRefActions = useCallback(
    async (elem: TextcutRectangle, preValues: any[][]) => {
      await dispatch(addReferece(elem))
      dispatchPushToUndoStack(preValues, elem, 'ADD')
      dispatch(
        setShowSelectionOnlyRange({
          sheetId: elem.sheetId,
          rangeAddress: elem.rangeAddr,
        })
      )
    },
    [dispatch, dispatchPushToUndoStack]
  )

  const onCommentIconClick = (id: string) => {
    setCommentTopicId(id)
    setPopupType(LayerRightClickType.STAGE)
    setPopup(true)
  }

  const drawCommets = () =>
    comments
      .filter((topic) => topic.fileId === fileId && topic.filePage === filePage)
      .map((topic) => {
        const [x, y] = rotateAVertex(
          topic.x,
          topic.y,
          topic.ocrW,
          topic.ocrH,
          rotationDegree.curr
        )
        const convertedX = numeral(x).divide(ratioX.value()).value() ?? 0
        const convertedY = numeral(y).divide(ratioY.value()).value() ?? 0
        return RenderImage({
          key: topic.id,
          image,
          x: convertedX,
          y: convertedY,
          onClick: onCommentIconClick,
        })
      })
  const onLinkMouseEnter =
    (tag: CutTag) => (e: KonvaEventObject<MouseEvent>) => {
      if (tag === CutTag.REDACT) return
      e.currentTarget.setAttr('fill', 'RGB(241,241,241, 0.5)')
    }

  const onLinkMouseLeave =
    (tag: CutTag, color?: string) => (e: KonvaEventObject<MouseEvent>) => {
      if (tag === CutTag.REDACT) return
      e.currentTarget.setAttr('fill', color ?? '')
    }

  return (
    <>
      {popup && renderPopup(setPopup)}
      {<ToastContainer />}
      <Stage
        className="absolute"
        width={width}
        height={height}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onContextMenu={(evt) => {
          layerRightClickHandler(evt, '', LayerRightClickType.STAGE)
        }}
      >
        <Layer>
          {dataMatchRects.length > 0 && draw(dataMatchRects)}

          {referenceRect.length > 0 && draw(referenceRect)}
          {textcutRects.length > 0 && draw(textcutRects)}

          {sumRects.length > 0 && draw(sumRects)}

          {drawCommets()}

          {searchAllItemRects.length > 0 &&
            searchAllItemRects.map((r) => (
              <Rect
                key={r.ocrId}
                x={numeral(r.x).divide(ratioX.value()).difference(2) ?? -1}
                y={numeral(r.y).divide(ratioY.value()).difference(2) ?? -1}
                width={numeral(r.w).divide(ratioX.value()).add(4).value() ?? -1}
                height={
                  numeral(r.h).divide(ratioY.value()).add(4).value() ?? -1
                }
                fill={'rgb(230,230,250, 0.3)'}
                stroke={'purple'}
              />
            ))}

          {isSelecting && (
            <Rect
              x={Math.min(startPos[0], endPos[0])}
              y={Math.min(startPos[1], endPos[1])}
              width={Math.abs(startPos[0] - endPos[0])}
              height={Math.abs(startPos[1] - endPos[1])}
              stroke={getCutTypeStyle(cutType).stroke}
              fill={getCutTypeStyle(cutType).fill}
              strokeWidth={3}
            />
          )}

          {(cutType === Cut.TABLECUT ||
            cutType === Cut.WORD ||
            cutType === Cut.LINE) &&
            rects.map((r, i) => (
              <Rect
                key={i}
                x={numeral(r.x).divide(ratioX.value()).difference(2) ?? -1}
                y={numeral(r.y).divide(ratioY.value()).difference(2) ?? -1}
                width={numeral(r.w).divide(ratioX.value()).add(4).value() ?? -1}
                height={
                  numeral(r.h).divide(ratioY.value()).add(4).value() ?? -1
                }
                fill={hoverIdx === i ? 'rgb(241,241,241, 0.5)' : ''}
                stroke={
                  r.stroke ? r.stroke : strokeIdx === i ? '#B6FBFB' : r.stroke
                }
                onMouseEnter={() => {
                  setHoverIdx(i)
                  setStrokeIdx(i)
                }}
                onMouseLeave={() => {
                  setHoverIdx(-1)
                  setStrokeIdx(i)
                }}
                onClick={onHoveredClick(r)}
              />
            ))}
          {redactRects.length > 0 &&
            redactRects
              .filter(
                (rect) => rect.fileId === fileId && rect.filePage === filePage
              )
              .map((rect) => {
                if (rect.degree !== rotationDegree.curr) {
                  const targetDegree =
                    (((rotationDegree.curr - rect.degree) % 360) + 360) % 360
                  const input = convertToFourPointVertices(
                    rect.x,
                    rect.y,
                    rect.w,
                    rect.h
                  )
                  const arr = rotateOcrBox(
                    input,
                    targetDegree,
                    rect.ocrW,
                    rect.ocrH
                  )
                  const topLeft = findTOpLeftPointFromVertices(arr)
                  const [hh, ww] = calculateHeightandWidth(
                    arr[0],
                    arr[1],
                    arr[2],
                    arr[3]
                  )
                  if (!topLeft) return rect
                  rect = { ...rect, x: topLeft.x, y: topLeft.y, h: hh, w: ww }
                  return rect
                } else {
                  return rect
                }
              })
              .map((r: TextcutRectangle, i: number) => (
                <Rect
                  key={r.bindingId}
                  x={numeral(r.x).divide(ratioX.value()).difference(2) ?? -1}
                  y={numeral(r.y).divide(ratioY.value()).difference(2) ?? -1}
                  width={
                    numeral(r.w).divide(ratioX.value()).add(4).value() ?? -1
                  }
                  height={
                    numeral(r.h).divide(ratioY.value()).add(4).value() ?? -1
                  }
                  fill={r.fill}
                  stroke={r.stroke}
                  onClick={() => setShowContextMenu(false)}
                  onContextMenu={(evt) => {
                    layerRightClickHandler(
                      evt,
                      r.bindingId,
                      LayerRightClickType.REDACT
                    )
                  }}
                />
              ))}
        </Layer>
      </Stage>
      {showContextMenu && layerRightClickType && (
        <CustomContextualMenu
          x={contextMenuPosition[0]}
          y={contextMenuPosition[1]}
          menuItems={renderContextMenuItems().menuItems}
        />
      )}
    </>
  )
}

export default KonvaContainer
