import { JwtPayload } from 'jwt-decode'
import { MouseEventHandler } from 'react'

export type TestFile = {
  id: string
  filename: string
  path: string
  ocrPath: string
}

export type CanvasArea = {
  height: number
  width: number
}

export type Rectangle = {
  x: number
  y: number
  w: number
  h: number
  // cW: number // canvas width
  // cH: number // canvas height,
}

export type KonvaRectangle = Rectangle & {
  ocrId: string
  stroke: string | undefined
  referenceId?: string
  row?: number
  col?: number
  startRow?: number
  startCol?: number
  sheetId?: string
  rangeAddr?: string
}

export type Func = (...args: any[]) => any

export enum Cut {
  TEXTCUT = 'TEXTCUT',
  TABLECUT = 'TABLECUT',
  VIEW = 'VIEW',
  LINE = 'LINE',
  WORD = 'WORD',
  SUM = 'SUM',
  REDACT = 'REDACT',
}

export type TextBlock = {
  id: string
  blockType: BlockType
  description: string
  boundingPoly: { vertices: Vertex[] }
  relationships?: TextBlockRelation[]
}

export type TextBlockRelation = {
  type: 'CHILD'
  ids: string[]
}

export enum BlockType {
  PAGE = 'PAGE',
  LINE = 'LINE',
  WORD = 'WORD',
  TABLE = 'TABLE',
  CELL = 'CELL',
}

export type Vertex = {
  x: number
  y: number
}

export type TextAnnotation = TextBlock[]

export type TestDataOcr = {
  textAnnotations: TextAnnotation
  textPreview: string
}[]

export type OCRData = TestDataOcr

export type QuadTreeMiniTextBlock = Omit<
  TextBlock,
  'relationships' | 'boundingPoly'
> & {
  vertices: Vertex[]
}

export type CellBlock = Omit<TextBlock, 'blockType'> & {
  blockType: BlockType.CELL
  columnIndex: number
  columnSpan: number
  rowIndex: number
  rowSpan: number
  text?: string
}

export type Reference = {
  fileId: string
  filePage: number
  type: BlockType
  blockId: string
  table?: boolean
}

export type DataMatchReference = TextBlock & {
  fileId: string
  filePage: number
  row: number
  col: number
  startRow: number
  startCol: number
  sheetId: string
  rangeAddr?: string
}

export type CutRefrence = DataMatchReference

export enum FileStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  UPLOADING = 'UPLOADING',
  MALICIOUS = 'MALICIOUS',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  REDACTING = 'REDACTING',
  INVALID = 'INVALID',
}

export type DynamoFile = {
  createdAt: string
  completedAt: string
  fileId: string
  fileType: string
  ocrFileKey: string
  originalFileKey: string
  status: string
  userId: string
  fileName: string
  originalFileSize: number
  dir: string
}

export type GetFileType = DynamoFile & {
  ocrUrl: string
  pdfUrl: string
}

export type TextcutRectangle = Rectangle & {
  fileId: string
  filePage: number
  rangeAddr: string
  sheetId: string
  // referenceId: string
  degree: number
  ocrH: number
  ocrW: number
  // row?: number
  // col?: number
  // startRow?: number
  // startCol?: number
  tag: CutTag
  bindingId: string
  sheetName: string
  boundingPoly?: { vertices: Vertex[] }
  fill: string
  stroke: string
}

export enum QUICKCUT_ACCESS_TOKEN {
  'ACCESS_TOKEN' = 'QUICKCUT_ACCESS_TOKEN',
}

export enum FileRotation {
  Rotation = 'ROTATION',
  SET_META = 'SET_META',
}

export type RotationType = {
  curr: number
  meta: number
}

export type SetTextcutValueInput = {
  value: string
  fileId: string
  filePage: number
  drawingRect: Rectangle
  degree: number
  tag: Cut
}

export enum CutTag {
  TEXTCUT = 'TEXTCUT',
  WORD = 'WORD',
  LINE = 'LINE',
  TABLE = 'TABLE',
  DATA_MATCH = 'DATA_MATCH',
  SUM = 'SUM',
  REDACT = 'REDACT',
}

export type CompressedFile = DynamoFile & {
  compressedPDF: string
  compressedOCR: string
}

export type WorkbookPDF = DynamoFile & {
  ocr: TestDataOcr
  pdf: Blob
}

export type PopupTextType = {
  header: string | JSX.Element
  content: string | JSX.Element
  onClick: MouseEventHandler
  onSecondBtnClick?: MouseEventHandler
}

export type FolderStructure = {
  [key: string]: FolderStructure | null
}

export type FileRecord = Pick<DynamoFile, 'fileId' | 'userId' | 'status'>

export type Downloadable = {
  blob: Blob
  fileName: string
  type: string
}

export type UndoEvent = {
  type: 'ADD' | 'DELETE'
  id: string
  reference: TextcutRectangle
  preValues: any[][]
}

// export type Comment = {
//   id: string
//   bindingId: string
//   content: string
//   createdAt: string
//   updatedAt: string
//   createdBy: string
// }

export type SearchAllType = {
  blockId: string
  fileId: string
  fileName: string
  filePage: number
}

export enum AppCustomEvent {
  ON_REFERENCE_SELECTED = 'ON_REFERENCE_SELECTED',
}

export type FileCommentTopic = {
  id: string
  x: number
  y: number
  createdAt: number
  createdBy: string
  comments: FileCommentContent[]
  fileId: string
  filePage: number
  ocrW: number
  ocrH: number
  degree: number
}

export type FileCommentContent = {
  content: string
  createdAt: number
  createdBy: string
}

export type First<T extends any[]> = T[0]
export type Optional<T> = {
  [key in keyof T]?: T[key]
}

export type OfficeRuntimeAccessToken = {
  name: string
  preferred_username: string
  oid: string
}

export type OfficeRuntimeTokenJwtPayload = JwtPayload &
  Optional<OfficeRuntimeAccessToken>
