import { useEffect } from 'react'

export const useOnSelectionChange = () => {
  useEffect(() => {
    let ignore = false
    let registered = false
    let eventResult: any = undefined
    const onSelectionChangeHandler = async () => {
      await Excel.run(async (context) => {
        const sheets = context.workbook.worksheets
        if (!ignore) {
          eventResult = sheets.onSelectionChanged.add(async (event) => {
            const e = new CustomEvent('OnExcelWorkbooksSelectionChange', {
              detail: JSON.stringify(event),
            })
            dispatchEvent(e)
          })
          await context.sync()
          console.log('The worksheet onSelectionChange handler is registered')
          registered = true
        }
      })
    }

    onSelectionChangeHandler().catch((err) => console.error(err))

    return () => {
      ignore = true
      if (registered && eventResult) {
        const tmp =
          eventResult as unknown as OfficeExtension.EventHandlerResult<Excel.WorksheetSingleClickedEventArgs>
        Excel.run(tmp.context, async (context) => {
          tmp.remove()
          await context.sync()

          eventResult = undefined
          console.log('Event onSelectionChange handler successfully removed.')
        })
      }
    }
  }, [])
}

export default useOnSelectionChange
