import { Dispatch, useEffect, useMemo, useState } from 'react'
import {
  createRecord,
  getSignedUrl,
  putFileToSignedURL,
  startProcessingDoc,
} from '../../api'
import { FileRecord, FileStatus } from '../../types'
import { fileWithPathGuard } from '../../utils/guards'
import { saveFileRecordsToLocal } from '../../workbook'
import numeral from 'numeral'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDropzone, FileWithPath } from 'react-dropzone'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CloseIcon from '@mui/icons-material/Close'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { BeatLoader } from 'react-spinners'
import { getOfficeRuntimeAccessToken } from '../../utils/common'
import { Upload_API_ERROR } from '../../constant'

const filesSanityCheck = (files: FileWithPath[]) => {
  return (
    files.every((file) => file.size < numeral('5MB').value()!) &&
    files.every((file) => file.type === 'application/pdf')
  )
}

const pdfLessThan5MB = (size: number) => size < numeral('5MB').value()!

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type Props = {
  setUpload: Dispatch<React.SetStateAction<boolean>>
  refetchFiles: () => void
  loading: boolean
  setLoading: Dispatch<React.SetStateAction<boolean>>
}

export default function UploadContainer({
  setUpload,
  refetchFiles,
  loading,
  setLoading,
}: Props) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
  })
  const [files, setFiles] = useState<FileWithPath[]>([])
  const [folderSet, setFolderSet] = useState<string[]>([])
  useEffect(() => {
    const set = new Set<string>()
    const filteredFiles = acceptedFiles.filter(fileWithPathGuard)
    filteredFiles.forEach((file) => {
      const path = file.path?.split('/')
      if (path && path.length > 1) set.add(path[1])
    })
    setFiles(filteredFiles)
    setFolderSet([...set])
  }, [acceptedFiles])

  const uploadFiles = async () => {
    setLoading(true)
    const func = async () => {
      if (!files.length) return
      // const extendedFiles: ExtendedFilePondFile[] = (
      //   files as unknown as any[]
      // ).filter((file) => isExtendedFilePondFile(file))
      const fileRecord: FileRecord[] = []
      const { oid } = await getOfficeRuntimeAccessToken()
      for (const file of files) {
        const arr = file.path?.split('/')
        if (!arr) continue
        const res = (
          await createRecord(
            file.name,
            arr.length <= 1 ? `/${file.path}` : file.path!
          )
        ).data.data
        const signedUrl = (await getSignedUrl(res.fileId)).data.uploadURL
        const buffer = await file.arrayBuffer()
        const blob = new Blob([buffer])
        await putFileToSignedURL(signedUrl, new File([blob], file.name))
        await startProcessingDoc(res.fileId) // Trigger processing of Doc after upload
        fileRecord.push({
          fileId: res.fileId,
          userId: oid,
          status: FileStatus.PROCESSING,
        })
      }
      await saveFileRecordsToLocal(fileRecord).catch((err) =>
        console.error('saveFileRecordsToLocal:', err)
      )
    }
    const checked = filesSanityCheck(files)
    if (!checked) {
      toast.error('Invalid file type or file size exceeds 5MB')
      setLoading(false)
      return
    }
    func()
      .then(() => {
        setLoading(false)
        // navigate('/files')
        setUpload(false)
        refetchFiles()
      })
      .catch((err) => {
        setLoading(false)
        console.error(err)
        toast.error(Upload_API_ERROR, { autoClose: false })
        toast.error(`Details: ${err}`, { autoClose: false })
      })
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const removeAllFiles = () => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    setFiles([])
    setFolderSet([])
  }

  const removeFile = (file: FileWithPath) => () => {
    const filteredFiles = files.filter(
      (f) => f.name !== file.name || f.path !== file.path
    )
    const idx = acceptedFiles.findIndex(
      (f) => f.name === file.name && (f as FileWithPath).path === file.path
    )

    if (idx >= 0) {
      acceptedFiles.splice(idx, 1)
    }
    setFiles(filteredFiles)
  }

  const removeFolder = (startPath: string) => () => {
    const filteredFolders = folderSet.filter((folder) => folder !== startPath)
    const remainingFiles = files.filter(
      (file) => !file.path?.startsWith(`/${startPath}`)
    )
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    remainingFiles.forEach((file) => acceptedFiles.push(file))
    setFolderSet(filteredFolders)
    setFiles(remainingFiles)
  }

  const folderContainsPdfLessThan5MB = (path: string) => {
    const filteredFiles = files.filter((file) =>
      file.path?.startsWith(`/${path}`)
    )
    return filteredFiles.every((file) => pdfLessThan5MB(file.size))
  }

  return (
    <div className="h-full min-w-[480px] ">
      <div>
        <ToastContainer className="max-w-72" />
      </div>
      {loading && (
        <div className="flex w-screen h-screen justify-center items-center">
          <BeatLoader size={20} color="#36d7b7" />
        </div>
      )}
      {!loading && (
        <>
          <div className="flex flex-col p-4 pb-0 max-h-[85%]">
            <div className="bg-white p-4 rounded-lg mb-2">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div>
                  <CloudUploadOutlinedIcon sx={{ fontSize: 68 }} />
                </div>
                <div className="text-lg text-black font-semibold mb-1">
                  Drag & drop files/folders or{' '}
                  <span className="text-[#089797] cursor-pointer">Browse</span>
                </div>
                <div className="text-sm text-[#606372]">
                  PDF only, file size no more that 5MB
                </div>
              </div>
            </div>
            {files.length > 0 && (
              <div className="flex flex-col bg-white rounded-lg p-4 overflow-y-auto ">
                <ul>
                  {folderSet.length > 0 &&
                    folderSet.map((folder, i) => (
                      <li key={i}>
                        <div
                          className={`flex border-2 ${
                            folderContainsPdfLessThan5MB(folder)
                              ? 'border-[#089797]'
                              : 'border-[#EF4444]'
                          }  mb-1 rounded-md justify-between p-2`}
                        >
                          <div className="flex">
                            <div className="flex  text-black font-semibold mr-2 items-center">
                              <FolderOutlinedIcon sx={{ fontSize: 20 }} />
                            </div>
                            <div className="flex text-black items-center text-sm font-semibold">
                              {folder}
                            </div>
                          </div>
                          <div>
                            <button
                              className="transition ease-in-out delay-100 hover:scale-125"
                              onClick={removeFolder(folder)}
                            >
                              <CloseIcon sx={{ fontSize: 20 }} />
                            </button>
                          </div>
                        </div>
                        {!folderContainsPdfLessThan5MB(folder) && (
                          <div className="text-sm text-[#EF4444]">
                            This folder contains file more than 5MB, please
                            delete and upload the folder again.
                          </div>
                        )}
                      </li>
                    ))}
                  {files
                    .filter(
                      (file) =>
                        !folderSet.some(
                          (path) => file.path?.startsWith(`/${path}`) ?? false
                        )
                    )
                    .map((file) => (
                      <li className="mb-1" key={`${file.path}-${file.name}`}>
                        <div
                          className={`flex border-2 ${
                            pdfLessThan5MB(file.size)
                              ? 'border-[#089797]'
                              : 'border-[#EF4444]'
                          } rounded-md justify-between p-2`}
                        >
                          <div className="flex">
                            <div className="flex  text-black font-semibold mr-2">
                              {file.name}
                            </div>
                            <div className="flex text-black items-center text-sm font-light">
                              {numeral(file.size).format('0b')}
                            </div>
                          </div>

                          <div>
                            <button
                              className="transition ease-in-out delay-100 hover:scale-125"
                              onClick={removeFile(file)}
                            >
                              <CloseIcon sx={{ fontSize: 20 }} />
                            </button>
                          </div>
                        </div>
                        {!pdfLessThan5MB(file.size) && (
                          <div className="text-sm text-[#EF4444]">
                            This file is over 5MB, please delete and upload
                            another file.
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
      {files.length > 0 && (
        <footer className="absolute flex justify-end  bottom-0 bg-white w-full rounded-t-3xl min-h-[5%]">
          <div className="flex p-2">
            <button
              onClick={removeAllFiles}
              type="button"
              className="inline-flex m-1 text-teal-600 justify-center rounded-full border border-teal-400 px-4 py-2 text-sm font-medium hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={uploadFiles}
              type="button"
              className="inline-flex text-white m-1 justify-center rounded-full px-4 py-2 text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
            >
              Process
            </button>
          </div>
        </footer>
      )}
    </div>
  )
}
