import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../dispatch'
import {
  addReferece,
  deleteReferencesWithBindingId,
} from '../slice/referenceSlice'
import {
  createBindingAndAddOnSelectionHandler,
  deleteBinding,
  setRangeValues,
  undoRange,
} from '../workbook'
import { popUndoStack } from '../slice/undoSlice'
import { CutTag } from '../types'
import { nanoid } from 'nanoid'

const useUndo = () => {
  const stack = useAppSelector((state) => state.undo.undoStack)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handler = async () => {
      if (!stack.length) return
      const item = stack.at(stack.length - 1)
      if (!item) return
      if (item.type === 'ADD') {
        deleteBinding(item.reference.bindingId)
          .then(() =>
            dispatch(deleteReferencesWithBindingId(item.reference.bindingId))
          )
          .then(async () => {
            if (item.reference.tag !== CutTag.REDACT) await undoRange(item)
            dispatch(popUndoStack())
          })
          .catch((err) => console.error(err))
      } else {
        Excel.run(async (ctx) => {
          const sheet = ctx.workbook.worksheets.getItemOrNullObject(
            item.reference.sheetId
          )
          sheet.load('isNullObject')
          await ctx.sync()
          if (sheet.isNullObject) return
          const range = sheet.getRange(item.reference.rangeAddr)
          const nano = nanoid()
          createBindingAndAddOnSelectionHandler(ctx, range, 'Range', nano)
          await dispatch(addReferece({ ...item.reference, bindingId: nano }))
          await setRangeValues(
            item.reference.sheetId,
            item.reference.rangeAddr,
            item.preValues
          )
          dispatch(popUndoStack())
          await ctx.sync()
        }).catch((err) => console.error(err))
      }
    }

    window.addEventListener('UNDO', handler)

    return () => window.removeEventListener('UNDO', handler)
  }, [stack, dispatch])
}

export default useUndo
