import { MdDelete } from 'react-icons/md'
import {
  ChangeEvent,
  Dispatch,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Cut,
  FileRotation,
  FileStatus,
  RotationType,
  TextcutRectangle,
} from '../../types'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import { setShowSelectionOnly } from '../../slice/showSelectionOnlySlice'
import DropdownButton from '../DropdownButton'
import { useNavigate } from 'react-router-dom'
import { deleteReferencesWithBindingId } from '../../slice/referenceSlice'
import useFiles from '../../hooks/useFiles'
import CustomComboBox from '../SearchAllComboBox'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

type Props = {
  totalPages: number
  currentPage: number
  setCurrentPage: Dispatch<React.SetStateAction<number>>
  setDisplayKonva: Dispatch<React.SetStateAction<boolean>>
  dispatchRotation: Dispatch<{
    type: string
    payload: undefined | number
  }>
  isLocalMode: boolean
  degree: RotationType
  fileId: string
  setSearchAllItem: Dispatch<React.SetStateAction<string[]>>
  setFileId: Dispatch<React.SetStateAction<string>>
}

enum ChangePage {
  PREV = 0,
  NEXT = 1,
}

const FileController = ({
  totalPages,
  currentPage,
  setCurrentPage,
  setDisplayKonva,
  dispatchRotation,
  isLocalMode,
  degree,
  fileId,
  setSearchAllItem,
  setFileId,
}: Props) => {
  const navigate = useNavigate()

  const references = useAppSelector((state) => state.references.references)
  // const comments = useAppSelector((state) => state.comments.comments)
  const [searchAllInput, setSearchAllInput] = useState(false)
  const [filesDropdown, setFilesDropdown] = useState(true)

  // const renderCommentIcon =
  //   (comments: FileCommentTopic[]) => (elem: TextcutRectangle) => {
  //     if (comments.some((comment) => comment.bindingId === elem.bindingId))
  //       return <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 15 }} />
  //     return <></>
  //   }

  const selectRange = (rect: TextcutRectangle) =>
    Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getItem(rect.sheetId)
      sheet.load('isNullObject')
      await ctx.sync()
      if (sheet.isNullObject) return
      const range = sheet.getRange(rect.rangeAddr)
      range.select()
      sheet.activate()
    })

  const referecnesInput = references.map((ref) => ({
    ...ref,
    id: ref.bindingId,
    label: `${ref.sheetName}-${ref.rangeAddr}-${ref.tag}`,
    onClick: () => selectRange(ref).catch((err) => console.log(err)),
    itemDeletion: [
      <MdDelete />,
      () =>
        dispatch(deleteReferencesWithBindingId(ref.bindingId)).catch((err) =>
          console.log(err)
        ),
    ] as [JSX.Element, MouseEventHandler],
  }))
  const { files, isFetchSuccess } = useFiles(isLocalMode)

  const input =
    files
      ?.filter((file) => file.status === FileStatus.SUCCEEDED)
      .map((file) => ({
        id: file.fileId,
        label: file.fileName ?? '',
        onClick: () => navigate(`/file/${file.fileId}`, { state: { page: 1 } }),
      })) ?? []

  const pageInputRef = useRef<HTMLInputElement>(null)
  const showSelectionOnly = useAppSelector(
    (state) => state.showSelectionOnly.showSelectionOnly
  )
  const cutMode = useAppSelector((state) => state.cutMode)
  const dispatch = useAppDispatch()
  const onChangePageBtnClick = (changePage: ChangePage) => () => {
    if (changePage === ChangePage.NEXT && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      setDisplayKonva(false)
      if (pageInputRef.current)
        pageInputRef.current.value = currentPage + 1 + ''
    } else if (changePage === ChangePage.PREV && currentPage > 1) {
      setCurrentPage(currentPage - 1)
      setDisplayKonva(false)
      if (pageInputRef.current)
        pageInputRef.current.value = currentPage - 1 + ''
    }
  }

  const onPageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    if (value > 0 && value <= totalPages) {
      setCurrentPage(value)
    }
  }

  const expandSearchAllInput = () => {
    const { innerWidth } = window
    if (innerWidth < 640) setFilesDropdown(false)
    setSearchAllInput(true)
  }

  const collapesSearchAllInput = () => {
    if (!filesDropdown) setFilesDropdown(true)
    setSearchAllInput(false)
  }

  useEffect(() => {
    if (pageInputRef.current) pageInputRef.current.value = currentPage + ''
  }, [currentPage])

  useEffect(() => {
    const handler = () => {
      const { innerWidth } = window
      if (innerWidth >= 640 && !filesDropdown) setFilesDropdown(true)
      else if (innerWidth < 640 && searchAllInput) setFilesDropdown(false)
    }

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  })

  return (
    <div className="flex w-full min-w-[476px] h-fit justify-between space-x-6 p-1">
      <div className="flex justify-start items-center">
        {/* <Link to={'/files'}>
          <button className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
            <RiArrowGoBackFill />
          </button>
        </Link> */}
        {isFetchSuccess && filesDropdown && (
          <div className="max-h-10 flex transition ease-in-out delay-100 hover:scale-110 p-1">
            <DropdownButton
              input={input}
              icon={<></>}
              tooltip="View another file"
              rearIcon={
                <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '16px' }} />
              }
            />
          </div>
        )}
        {referecnesInput.length > 0 && filesDropdown && (
          <div className="flex justify-center transition ease-in-out delay-100 hover:scale-110 p-1">
            <DropdownButton
              input={referecnesInput}
              icon={<InsertLinkOutlinedIcon sx={{ fontSize: '16px' }} />}
              tooltip="Link "
            />
          </div>
        )}
        <div className="flex justify-center items-center p-1">
          <span
            className={`flex w-fit items-center h-fit ${
              cutMode.type === Cut.VIEW ? 'bg-gray-700' : 'bg-green-700'
            } text-white text-xs/[10px] font-medium  px-2 py-1 rounded-full`}
          >
            {cutMode.type === Cut.TEXTCUT
              ? 'TEXT'
              : cutMode.type === Cut.TABLECUT
              ? 'TABLE'
              : cutMode.type}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-evenly items-center min-w-56">
        {/* <DropdownCheckbox /> */}

        {/* {cutMode.transposed && (
          <button className="text-sm text-gray-800 font-bold py-2 px-4 mr-1 rounded sm:inline-flex items-center">
            {<MdPivotTableChart />}
          </button>
        )} */}

        {/* <div className="hidden group relative sm:flex justify-center">
          <button
            className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-2 px-4 mr-1 rounded inline-flex items-center"
            onClick={() =>
              Office.context.ui.displayDialogAsync(
                `https://localhost:3000${window.location.pathname}`
              )
            }
          >
            {<TbFloatLeft />}
          </button>
          <span className="absolute top-14 scale-0 rounded bg-gray-100 p-2 text-xs text-black group-hover:scale-100">
            Float
          </span>
        </div> */}

        <div className="flex group relative justify-center transition ease-in-out delay-100 hover:scale-110">
          <button
            className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
            onClick={() => dispatch(setShowSelectionOnly(!showSelectionOnly))}
          >
            {showSelectionOnly ? (
              <VisibilityOffOutlinedIcon sx={{ fontSize: '16px' }} />
            ) : (
              <VisibilityOutlinedIcon sx={{ fontSize: '16px' }} />
            )}
          </button>
          <div className="absolute top-14 w-max scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
            {showSelectionOnly ? 'View selected' : 'View all'}
          </div>
        </div>

        <div className="flex group relative justify-center transition ease-in-out delay-100 hover:scale-110">
          <button
            className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
            onClick={() =>
              dispatchRotation({
                type: FileRotation.Rotation,
                payload: undefined,
              })
            }
          >
            <Rotate90DegreesCwOutlinedIcon sx={{ fontSize: '16px' }} />
          </button>
          <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
            Rotate
          </div>
        </div>

        {totalPages > 0 && (
          <div className="relative flex items-center group">
            <div className="flex">
              <input
                ref={pageInputRef}
                type="text"
                inputMode="numeric"
                className="w-5 h-5 text-center text-sm/[10px]"
                onChange={onPageInputChange}
                defaultValue={currentPage}
              />{' '}
            </div>
            <div className="flex text-sm/[10px]">{`/ ${totalPages}`}</div>
            <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
              Pages
            </div>
          </div>
        )}

        {totalPages > 1 && (
          <>
            <div className="group relative flex justify-center hover:scale-110 transition ease-in-out delay-100">
              <button
                onClick={onChangePageBtnClick(ChangePage.PREV)}
                className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
              >
                <KeyboardArrowLeftOutlinedIcon sx={{ fontSize: '16px' }} />
              </button>
              <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                Prev
              </div>
            </div>
            <div className="group relative flex justify-center hover:scale-110 transition ease-in-out delay-100">
              <button
                onClick={onChangePageBtnClick(ChangePage.NEXT)}
                className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
              >
                <KeyboardArrowRightOutlinedIcon sx={{ fontSize: '16px' }} />
              </button>
              <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                Next
              </div>
            </div>
          </>
        )}

        {!searchAllInput && (
          <div className="flex w-max group relative justify-center transition ease-in-out delay-100 hover:scale-110">
            <button
              className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
              onClick={expandSearchAllInput}
            >
              <SearchOutlinedIcon sx={{ fontSize: '16px' }} />
            </button>
            <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
              Search
            </div>
          </div>
        )}

        {searchAllInput && (
          <div className="flex group relative justify-center ml-1">
            <CustomComboBox
              files={files}
              isLocalMode={isLocalMode}
              degree={degree}
              filePage={currentPage}
              fileId={fileId}
              navigate={navigate}
              setCurrentPage={setCurrentPage}
              setSearchAllItem={setSearchAllItem}
              setFileId={setFileId}
              setSearchAllInput={collapesSearchAllInput}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FileController
