import ErrorIcon from '@mui/icons-material/Error'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
  content: string
  navigate?: ReturnType<typeof useNavigate> | undefined
}

const ErrorMsg = ({ title, content, navigate }: Props) => {
  return (
    <div className="flex flex-col w-full justify-center items-center bg-white p-4 rounded-lg">
      <div>
        <ErrorIcon sx={{ color: 'red', fontSize: 30 }} />
      </div>
      <div className="text-sm font-bold">{title}</div>
      <div className="text-sm font-semibold">{content}</div>
      {navigate && (
        <div className="flex w-full justify-center mt-1">
          <button
            onClick={() => navigate(0)}
            className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-teal-300 font-medium rounded-full text-xs px-2 py-2 text-center"
          >
            Reload
          </button>
        </div>
      )}
    </div>
  )
}

export default ErrorMsg
