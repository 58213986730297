import { filter, reduce } from 'lodash'
import { QuadTreeMiniTextBlock, TextcutRectangle, Vertex } from '../types'
import { Rectangle } from '@timohausmann/quadtree-ts'
import numeral from 'numeral'

type height = number
type width = number

export const calculateHeightandWidth = (
  v1: Vertex,
  v2: Vertex,
  v3: Vertex,
  v4: Vertex
): [height, width] => {
  const xS = [v1.x, v2.x, v3.x, v4.x]
  const yS = [v1.y, v2.y, v3.y, v4.y]

  const maxX = Math.max(...xS)
  const maxY = Math.max(...yS)

  const minX = Math.min(...xS)
  const minY = Math.min(...yS)

  return [maxY - minY, maxX - minX]
}

export const findTOpLeftPointFromVertices = (vertices: Vertex[]) => {
  return reduce(vertices, (acc, point) => ({
    x: Math.min(acc.x, point.x),
    y: Math.min(acc.y, point.y),
  }))
}

export const filterNodes = (
  rect: Rectangle,
  nodes: Rectangle<QuadTreeMiniTextBlock>[]
) => {
  const filtered = filter(nodes, (node) => {
    return (
      node.x >= rect.x &&
      node.y >= rect.y &&
      rect.x + rect.width >= node.x + node.width &&
      rect.y + rect.height >= node.y + node.height
    )
  })
  return filtered
}

const getRectangleMidPoint = (
  rect: Rectangle<QuadTreeMiniTextBlock>
): [number, number] => [
  numeral(rect.x).multiply(2).add(rect.width).divide(2).value() ?? 0,
  numeral(rect.y).multiply(2).add(rect.height).divide(2).value() ?? 0,
]

export const sortTextcutInReadableWay = (
  arr: Rectangle<QuadTreeMiniTextBlock>[]
) => {
  return arr.sort((a, b) => {
    const [aX, aY] = getRectangleMidPoint(a)
    const [bX, bY] = getRectangleMidPoint(b)
    const yDiff = aY - bY
    if (Math.abs(yDiff) < 10) return aX - bX
    else return yDiff
  })
}

export const rotateOcrBox = (
  box: Vertex[],
  degree: number,
  pageWidth: number,
  pageHeight: number
) => {
  const [boxH, boxW] = calculateHeightandWidth(box[0], box[1], box[2], box[3])
  const topLeft = findTOpLeftPointFromVertices(box)
  const x = topLeft?.x ?? 0
  const y = topLeft?.y ?? 0
  let newX: number, newY: number, newW: number, newH: number
  switch (degree) {
    case 90:
      newX = pageHeight - (y + boxH)
      newY = x
      newW = boxH
      newH = boxW
      break
    case 180:
      newX = pageWidth - (x + boxW)
      newY = pageHeight - (y + boxH)
      newW = boxW
      newH = boxH
      break
    case 270:
      newX = y
      newY = pageWidth - (x + boxW)
      newW = boxH
      newH = boxW
      break
    default:
      newX = x
      newY = y
      newW = boxW
      newH = boxH
  }

  return [
    { x: newX, y: newY },
    { x: newX + newW, y: newY },
    { x: newX + newW, y: newY + newH },
    { x: newX, y: newY + newH },
  ]
}

export const convertToFourPointVertices = (
  x: number,
  y: number,
  width: number,
  height: number
): Vertex[] => {
  return [
    { x, y },
    { x: x + width, y },
    { x: x + width, y: y + height },
    { x, y: y + height },
  ]
}

export const rotateARectangle = (rect: TextcutRectangle, degree: number) => {
  const input = convertToFourPointVertices(rect.x, rect.y, rect.w, rect.h)
  const arr = rotateOcrBox(input, degree, rect.ocrW, rect.ocrH)
  const topLeft = findTOpLeftPointFromVertices(arr)
  const [hh, ww] = calculateHeightandWidth(arr[0], arr[1], arr[2], arr[3])
  if (!topLeft) return rect
  if (degree % 360 === 90 || degree % 360 === 270) {
    const tmpH = rect.ocrH
    const tmpW = rect.ocrW
    return {
      ...rect,
      x: topLeft.x,
      y: topLeft.y,
      h: hh,
      w: ww,
      ocrH: tmpW,
      ocrW: tmpH,
    }
  }
  rect = { ...rect, x: topLeft.x, y: topLeft.y, h: hh, w: ww }
  return rect
}

export const rotateAVertex = (
  x: number,
  y: number,
  width: number,
  height: number,
  degree: number
) => {
  switch (degree) {
    case 0:
      return [x, y, width, height]
    case 360:
      return [x, y, width, height]
    case 90:
      return [height - y, x, height, width]
    case 180:
      return [width - x, height - y, width, height]
    default:
      return [y, width - x, height, width]
  }
}
