import { Dialog, Transition } from '@headlessui/react'
import { Dispatch, Fragment, MouseEventHandler } from 'react'
import CloseIcon from '@mui/icons-material/Close'

type Button = {
  text: string
  onClick: MouseEventHandler
}

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  popupHeader: string | JSX.Element
  popupContent: string | JSX.Element
  firstBtn: Button
  secondBtn: Button
  hideBtns?: boolean
  hideHeader?: boolean
  initialRef?: React.RefObject<HTMLElement>
}

const Popup = ({
  isOpen,
  popupContent,
  popupHeader,
  firstBtn,
  secondBtn,
  hideBtns,
  setIsOpen,
  hideHeader,
  initialRef,
}: Props) => {
  // const closeModal = () => setIsOpen(false)

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={secondBtn.onClick as unknown as () => void}
          initialFocus={initialRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={` max-w-md transform overflow-hidden bg-white ${
                    hideHeader ? 'p-0 rounded-sm' : 'p-6 rounded-2xl'
                  } text-left align-middle shadow-xl transition-all`}
                >
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      {!hideHeader && (
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {popupHeader}
                        </Dialog.Title>
                      )}
                    </div>
                    {!hideHeader && (
                      <div
                        className="flex items-center transition ease-in-out delay-100 hover:scale-110 cursor-pointer"
                        onClick={secondBtn.onClick}
                      >
                        <CloseIcon sx={{ fontSize: 20 }} />
                      </div>
                    )}
                  </div>
                  {!hideHeader && (
                    <hr className="h-px  bg-gray-100 border-0"></hr>
                  )}
                  <div className={`${hideHeader ? 'mt-0' : 'mt-2'}`}>
                    <div className="text-sm text-gray-500">{popupContent}</div>
                  </div>

                  {!hideBtns && (
                    <div className="mt-4 flex flex-row-reverse justify-start">
                      <button
                        type="button"
                        className="inline-flex text-white m-1 justify-center rounded-full px-4 py-2 text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
                        onClick={firstBtn.onClick}
                      >
                        {firstBtn.text}
                      </button>
                      <button
                        type="button"
                        className="inline-flex m-1 text-teal-600 justify-center rounded-full border border-teal-400 border-transparent px-4 py-2 text-sm font-medium hover:bg-gray-100"
                        onClick={secondBtn.onClick}
                      >
                        {secondBtn.text}
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Popup
