import { Disclosure, Transition } from '@headlessui/react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

type Props = {
  element: JSX.Element
  header: string
}

const CustomDisclosure = ({ element, header }: Props) => {
  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-50 px-4 py-1 text-left text-sm font-medium text-black hover:bg-green-100 focus:outline-none focus-visible:ring focus-visible:ring-offset-green-400">
            <span>{header}</span>
            <MdOutlineKeyboardArrowDown
              className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="px-4 pb-2 text-sm text-gray-500 bg-white">
              {element}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}
export default CustomDisclosure
