import { useNavigate } from 'react-router-dom'
import {
  deleteFile,
  getFileV2,
  getOcr,
  putFileToSignedURL,
  replacePdfAndOcr,
} from '../../api'
import { BeatLoader } from 'react-spinners'
import {
  CutTag,
  Downloadable,
  DynamoFile,
  FileStatus,
  FolderStructure,
  PopupTextType,
  TestDataOcr,
  TextcutRectangle,
} from '../../types'
import Dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import { Tab } from '@headlessui/react'
import { useRef, useState } from 'react'
import {
  deleteReferencesWithFileIds,
  resetReferences,
  updateRefereces,
} from '../../slice/referenceSlice'
import download from 'downloadjs'
import { Color, PDFArray, PDFDocument, PDFName, PDFString, rgb } from 'pdf-lib'
import { nanoid } from 'nanoid'
import {
  autoSave,
  fetchPDFFromLocal,
  removeAttachedFile,
  removeLocalFileRecordWithIds,
  replaceLocalPDF,
  resetLocalFileRecord,
  savePdfsToWorkbook,
} from '../../workbook'
import useFiles from '../../hooks/useFiles'
import Popup from '../Popup'
import { setLocalMode } from '../../slice/saveToLocalSlice'
import FileUnorderedList from '../FileUnorderedList'
import { motion } from 'framer-motion'
import CustomRadio from '../CustomRadio'
import JSZip from 'jszip'
import { rotateARectangle, rotateAVertex } from '../../utils/spatial'
import dayjs from 'dayjs'
import { resetComments } from '../../slice/commentSlice'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import UploadContainer from '../UploadContainer'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ErrorMsg from '../ErrorMsg'
import { GENERAL_ERR_CONTENT } from '../../constant'
import { isOfficeError } from '../../utils/guards'
import { getOfficeErrorTitle } from '../../utils/common'

Dayjs.extend(duration)
Dayjs.extend(require('dayjs/plugin/localizedFormat'))
const IS_DEV_MODE = process.env.REACT_APP_IS_DEV_MODE
console.log('IS_DEV_MODE', IS_DEV_MODE)

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

enum Category {
  DEFAULT = 'Default',
  ALL = 'All Files',
  LINKED = 'Linked Files',
  UNLINKED = 'Unlinked Files',
}

const generatePdfLibRgb = ([r, g, b]: [number, number, number]) =>
  rgb(r / 255, g / 255, b / 255)

const getColor = (
  tag: CutTag
): {
  color: Color | undefined
  opacity: number | undefined
  borderColor: Color
  borderWidth: 1 | 2
} => {
  if (tag === CutTag.DATA_MATCH) {
    return {
      // color: rgb(173 / 255, 216 / 255, 230 / 255),
      // opacity: 0.3,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([169, 224, 254]),
      borderWidth: 2,
    }
  } else if (tag === CutTag.TEXTCUT) {
    return {
      // color: rgb(255 / 255, 99 / 255, 71 / 255),
      // opacity: 0.3,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([182, 251, 251]),
      borderWidth: 2,
    }
  } else if (tag === CutTag.SUM) {
    return {
      // color: rgb(0 / 255, 191 / 255, 255 / 255),
      // opacity: 0.3,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([253, 230, 182]),
      borderWidth: 2,
    }
  } else if (tag === CutTag.REDACT) {
    return {
      color: rgb(0, 0, 0),
      opacity: 1,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    }
  } else {
    return {
      // color: rgb(247 / 255, 202 / 255, 201 / 255),
      // opacity: 0.5,
      color: undefined,
      opacity: undefined,
      borderColor: generatePdfLibRgb([182, 251, 251]),
      borderWidth: 2,
    }
  }
}

const TRANSFER = {
  header: 'Transfer',
  content:
    'Are you sure you want to transfer all files to this workbooks? All files saved in the cloud will be deleted after transfer.',
}

const downloadPDFFromCloud = async (
  file: DynamoFile
): Promise<Downloadable> => {
  const res = await getFileV2(file.fileId)
  const result = await fetch(res.pdfUrl)
  if (!result.ok) throw new Error('Failed to download pdf from cloud')
  const blob = await result.blob()
  return {
    blob,
    fileName: `${res.fileName}`,
    type: 'application/pdf',
  }
}

const downloadPDFFromLocal = async (
  file: DynamoFile
): Promise<Downloadable> => {
  const res = await fetchPDFFromLocal(file.fileId)
  const blob = res?.pdfBlob
  if (blob)
    return {
      blob,
      fileName: `${res.fileName}`,
      type: 'application/pdf',
    }
  else {
    throw new Error('Failed to download pdf from local')
  }
}

const exportPDFFromCloud = async (file: DynamoFile) => {
  const res = await getFileV2(file.fileId)
  const result = await fetch(res.pdfUrl)
  const blob = await result.blob()
  return blob
}

const exportPDFFromLocal = async (file: DynamoFile) => {
  const res = await fetchPDFFromLocal(file.fileId)
  return res?.pdfBlob
}

const convertPDFToPdfDocumentCloud = async (file: DynamoFile) => {
  const res = await getFileV2(file.fileId)
  const result = await fetch(res.pdfUrl)
  const pdfBytes = await result.arrayBuffer()
  const pdfDoc = await PDFDocument.load(pdfBytes)
  return pdfDoc
}

const convertPDFToPdfDocumentLocal = async (file: DynamoFile) => {
  const res = await fetchPDFFromLocal(file.fileId)
  const blob = res?.pdfBlob
  if (blob) {
    const pdfBytes = await blob.arrayBuffer()
    const pdfDoc = await PDFDocument.load(pdfBytes)
    return pdfDoc
  } else {
    throw new Error('Failed to download pdf from local')
  }
}

const buildFolderFromPaths = (paths: string[]) => {
  const root: any = {}
  paths.forEach((path) => {
    const parts = path.split('/').slice(1)
    let current = root
    parts.forEach((part, i) => {
      if (!current[part]) {
        current[part] = i === parts.length - 1 ? null : {}
      }
      current = current[part]
    })
  })
  return root
}

const getFilesFromFolder = (
  folderStack: string[],
  folderStructure: FolderStructure,
  files: DynamoFile[]
): DynamoFile[] => {
  let current: FolderStructure | null = folderStructure
  for (let i = 0; i < folderStack.length; i++) {
    if (!current) {
      const tmp = folderStack.slice(0, i)
      const str = `/${tmp.join('/')}`
      return files.filter((file) => file.dir === `${str}/${file.fileName}`)
    }
    current = current[folderStack[i]]
  }
  const str = folderStack.length === 0 ? '' : `/${folderStack.join('/')}`
  return files.filter((file) => file.dir === `${str}/${file.fileName}`)
}

const getFolders = (
  folderStack: string[],
  folderStructure: FolderStructure
): string[] => {
  let current: FolderStructure | null = folderStructure
  for (let i = 0; i < folderStack.length; i++) {
    if (!current) return []
    current = current[folderStack[i]]
  }
  if (current) {
    const keys = Object.keys(current)
    return keys.filter((key) => current![key] !== null)
  }
  return []
}

const FilesContainer = () => {
  const CATEGORIES = [
    Category.DEFAULT,
    // Category.ALL,
    Category.LINKED,
    Category.UNLINKED,
  ]
  const navigate = useNavigate()
  const references = useAppSelector((state) => state.references.references)
  const comments = useAppSelector((state) => state.comments.comments)
  const referencesFileIds = references.map((ref) => ref.fileId)
  const dispatch = useAppDispatch()
  const [openPopup, setOpenPopup] = useState(false)
  const localMode = useAppSelector((state) => state.localMode.isLocalMode)
  const [pageLoading, setPageLoading] = useState(false)
  const [popupText, setPopupText] = useState<PopupTextType>({
    header: '',
    content: '',
    onClick: () => {},
  })
  const [upload, setUpload] = useState(false)
  const [uploadOnProgress, setUploadOnProgress] = useState(false)
  const deleteOption = useRef(0)
  const downloadOption = useRef(0)

  const {
    files,
    isError,
    isFetchingLocal,
    refetch,
    setFiles,
    refetchLocal,
    setRefetchLocal,
    folderStack,
    setFolderStack,
    error,
    isFetching,
    isLocalError,
    isLoading,
    localError,
  } = useFiles(localMode, setPageLoading)

  const refetchFiles = () => {
    if (localMode) setRefetchLocal(true)
    else refetch()
  }

  const linkedFiles = files.filter((file) =>
    referencesFileIds.includes(file.fileId)
  )
  const unlinkedFiles = files.filter(
    (file) => !referencesFileIds.includes(file.fileId)
  )
  const filePaths: string[] = files.map((file) => file.dir)
  const folders = buildFolderFromPaths(filePaths)
  const deletePDF = (fileId: string) => () => {
    if (localMode) {
      removeAttachedFile(fileId)
        .then(async () => {
          await removeLocalFileRecordWithIds([fileId])
          await dispatch(deleteReferencesWithFileIds([fileId]))
          const arr = files.filter((file) => file.fileId !== fileId)
          setFiles(arr)
        })
        .catch((err) => console.error(err))
        .finally(() => setOpenPopup(false))
    } else {
      deleteFile(fileId)
        .then(async () => {
          await removeLocalFileRecordWithIds([fileId])
          await dispatch(deleteReferencesWithFileIds([fileId]))
          await refetch()
        })
        .catch((err) => console.log(err))
        .finally(() => setOpenPopup(false))
    }
  }

  const deleteAllFiles = () => {
    const deletePromises = localMode
      ? files.map((file) => removeAttachedFile(file.fileId))
      : files.map((file) => deleteFile(file.fileId))
    Promise.all(deletePromises)
      .then(async () => {
        await resetLocalFileRecord()
        await dispatch(resetReferences())
        await dispatch(resetComments())
        setFiles([])
      })
      .catch((err) => console.error(err))
      .finally(async () => {
        if (!localMode) await refetch()
        setOpenPopup(false)
      })
  }

  const deleteFilesWithoutReferences = () => {
    const fileIds = unlinkedFiles.map((file) => file.fileId)
    const promises = localMode
      ? unlinkedFiles.map((file) => removeAttachedFile(file.fileId))
      : unlinkedFiles.map((file) => deleteFile(file.fileId))
    Promise.all(promises)
      .then(async () => {
        await removeLocalFileRecordWithIds(fileIds)
        if (localMode) {
          const arr = files.filter((file) => !fileIds.includes(file.fileId))
          setFiles(arr)
        }
      })
      .catch((err) => console.error(err))
      .finally(async () => {
        if (!localMode) await refetch()
        setOpenPopup(false)
      })
  }

  const deleteFilesWithReferences = () => {
    const fileIds = linkedFiles.map((file) => file.fileId)
    const promises = localMode
      ? linkedFiles.map((file) => removeAttachedFile(file.fileId))
      : linkedFiles.map((file) => deleteFile(file.fileId))
    Promise.all(promises)
      .then(async () => {
        await removeLocalFileRecordWithIds(fileIds)
        await dispatch(deleteReferencesWithFileIds(fileIds))
        if (localMode) {
          const arr = files.filter((file) => !fileIds.includes(file.fileId))
          setFiles(arr)
        }
      })
      .catch((err) => console.error(err))
      .finally(async () => {
        if (!localMode) await refetch()
        setOpenPopup(false)
      })
  }

  const DELETE_OPTIONS = [
    {
      label: 'Delete All Files',
      description: 'Delete all files',
      onClick: () => {
        deleteOption.current = 0
      },
    },
    {
      label: 'Delete Files With Markup',
      description: 'Only delete files with markup',
      onClick: () => {
        deleteOption.current = 1
      },
    },
    {
      label: 'Delete Files Without Markup',
      description: 'Only delete files without markup',
      onClick: () => {
        deleteOption.current = 2
      },
    },
  ]

  const deleteFiles = () => {
    const option = deleteOption.current
    if (option === 0) {
      deleteAllFiles()
    } else if (option === 1) {
      deleteFilesWithReferences()
    } else if (option === 2) {
      deleteFilesWithoutReferences()
    }
    deleteOption.current = 0
    setOpenPopup(false)
  }

  const DOWNLAOD_OPTIONS: typeof DELETE_OPTIONS = [
    {
      label: 'Export All Files',
      description: 'Export all files without links to your local machine',
      onClick: () => {
        downloadOption.current = 0
      },
    },
    {
      label: 'Export all files with links',
      description: 'Export all files with links to your local machine',
      onClick: () => {
        downloadOption.current = 1
      },
    },
    {
      label: 'Export all files (ZIP)',
      description:
        'Export all files as ZIP without links to your local machine',
      onClick: () => {
        downloadOption.current = 3
      },
    },
    {
      label: 'Export all files with links (ZIP)',
      description: 'Export all files as ZIP with links to your local machine',
      onClick: () => {
        downloadOption.current = 4
      },
    },
    {
      label: 'Compact Export',
      description:
        'Combine all linked pages from all files into one PDF and export to your local machine',
      onClick: () => {
        downloadOption.current = 2
      },
    },
  ]

  const downloadFiles = () => {
    const option = downloadOption.current
    if (option === 0) {
      downloadAllFiles().catch((err) => console.log(err))
    } else if (option === 1) {
      exportAllLinkedFiles().catch((err) => console.log(err))
    } else if (option === 2) {
      compactExport().catch((err) => console.log(err))
    } else if (option === 3) {
      downloadAllFiles(true).catch((err) => console.log(err))
    } else if (option === 4) {
      exportAllLinkedFiles(true).catch((err) => console.log(err))
    }
    downloadOption.current = 0
    setOpenPopup(false)
  }

  const showDeleteOptionsPopup = () =>
    showPopup({
      header: (
        <div className="flex items-center p-2">
          <div className="flex items-center mr-1">
            <DeleteOutlinedIcon sx={{ fontSize: 20 }} />
          </div>
          <div>Delete options</div>
        </div>
      ),
      content: <CustomRadio options={DELETE_OPTIONS} label="DELETE" />,
      onClick: () => deleteFiles(),
      onSecondBtnClick: () => {
        deleteOption.current = 0
        setOpenPopup(false)
      },
    })

  const showDownloadOptionsPopup = () =>
    showPopup({
      header: (
        <div className="flex items-center p-2">
          <div className="flex items-center mr-1">
            <FileUploadOutlinedIcon sx={{ fontSize: 20 }} />
          </div>
          <div>Export options</div>
        </div>
      ),
      content: <CustomRadio options={DOWNLAOD_OPTIONS} label="DOWNLOAD" />,
      onClick: () => downloadFiles(),
      onSecondBtnClick: () => {
        downloadOption.current = 0
        setOpenPopup(false)
      },
    })

  const onRemoteFileClick = async (file: DynamoFile) => {
    const res = await getFileV2(file.fileId)
    navigate(`/file/${file.fileId}`, {
      state: {
        pdfUrl: res.pdfUrl,
        ocrUrl: res.ocrUrl,
        fileId: res.fileId,
      },
    })
  }

  const onLocalFileClick = (file: DynamoFile) =>
    navigate(`/file/${file.fileId}`)
  const onFileClick = (file: DynamoFile) => async () => {
    if (
      file.status !== FileStatus.SUCCEEDED &&
      file.status !== FileStatus.UPLOADING &&
      file.status !== FileStatus.MALICIOUS
    )
      return
    if (localMode) {
      onLocalFileClick(file)
    } else {
      await onRemoteFileClick(file).catch((err) => console.error(err))
    }
  }

  const downloadFile = (file: DynamoFile) => async () => {
    if (localMode) {
      const result = await downloadPDFFromLocal(file).catch((err) =>
        console.error(err)
      )
      if (result) download(result.blob, result.fileName, result.type)
    } else {
      const result = await downloadPDFFromCloud(file).catch((err) =>
        console.error(err)
      )
      if (result) download(result.blob, result.fileName, result.type)
    }
  }

  const exportPDF = async (
    file: DynamoFile
  ): Promise<Downloadable | undefined> => {
    try {
      const pdfBlob = localMode
        ? await exportPDFFromLocal(file)
        : await exportPDFFromCloud(file)
      if (!pdfBlob) throw new Error('Unable to export PDF')
      const filteredReferences = references.filter(
        (ref) => ref.fileId === file.fileId
      )
      const pdfBytes = await pdfBlob.arrayBuffer()
      const pdfDoc = await PDFDocument.load(pdfBytes)
      const pages = pdfDoc.getPages()

      for (let i = 0; i < pages.length; i++) {
        const { width, height } = pages[i].getSize()
        const rotation = pages[i].getRotation()
        const targetDegree_ = (((0 - rotation.angle) % 360) + 360) % 360
        // add comments to pdf page
        const filteredComments = comments.filter(
          (topic) => topic.fileId === file.fileId && topic.filePage === i + 1
        )

        for (const topic of filteredComments) {
          const contents = [...topic.comments].sort((a, b) =>
            dayjs(b.createdAt).diff(a.createdAt)
          )
          const textArr = contents.map(
            (comment) => `${comment.content}(${comment.createdBy})\n`
          )

          const target_degree = 360 - rotation.angle

          const [rotated_x, rotated_y, rotated_w, rotated_h] = rotateAVertex(
            topic.x,
            topic.y,
            topic.ocrW,
            topic.ocrH,
            target_degree
          )

          const x = (rotated_x * width) / rotated_w
          const y = height - (rotated_y * height) / rotated_h

          const annotation = pdfDoc.context.obj({
            Type: 'Annot',
            Subtype: 'Text',
            Open: false, // Is the annotation open by default?
            Name: 'Note', // Determines the icon to place in the document.
            Rect: [x, y, 30, 30], // The position of the annotation
            Contents: PDFString.of(textArr.join('')), // The annotation text
          })

          const annotationRef = pdfDoc.context.register(annotation)

          const annots = pages[i].node.lookup(PDFName.of('Annots'))
          if (annots) {
            ;(annots as PDFArray).push(annotationRef)
          } else
            pages[i].node.set(
              PDFName.of('Annots'),
              pdfDoc.context.obj([annotationRef])
            )
        }

        const pageRefs = filteredReferences.filter(
          (ref) => ref.filePage === i + 1
        )
        for (const ref of pageRefs) {
          const targetDegree = (((0 - ref.degree) % 360) + 360) % 360
          const newRef_ = rotateARectangle(ref, targetDegree)
          const newRef = rotateARectangle(newRef_, targetDegree_)
          const refX = newRef.x,
            refY = newRef.y + newRef.h,
            refH = newRef.h,
            refW = newRef.w,
            ocrH = newRef.ocrH,
            ocrW = newRef.ocrW

          pages[i].drawRectangle({
            x: (refX * width) / ocrW - 2,
            y: height - (refY * height) / ocrH - 2,
            width: (refW * width) / ocrW + 4,
            height: (refH * height) / ocrH + 4,
            ...getColor(ref.tag),
          })
        }
      }
      const updatedPDFBytes = await pdfDoc.save()
      return {
        blob: new Blob([updatedPDFBytes]),
        fileName: `${file.fileName}`,
        type: 'application/pdf',
      }
    } catch (err) {
      console.error(err)
    }
  }

  const downloadExportPdf = (file: DynamoFile) => async () => {
    const result = await exportPDF(file).catch((err) => console.error(err))
    if (result) download(result.blob, result.fileName, result.type)
  }

  const convertPDFToPdfDocument = (file: DynamoFile) => {
    if (localMode) return convertPDFToPdfDocumentLocal(file)
    else return convertPDFToPdfDocumentCloud(file)
  }

  const compactExport = async () => {
    setPageLoading(true)
    try {
      const promises: ReturnType<typeof convertPDFToPdfDocument>[] = []
      for (const file of linkedFiles) {
        promises.push(convertPDFToPdfDocument(file))
      }
      const pdfDocuments = await Promise.all(promises)
      const newPDF = await PDFDocument.create()

      for (let i = 0; i < linkedFiles.length; i++) {
        const doc = pdfDocuments[i]
        const f = linkedFiles[i]
        const refs = references.filter((ref) => ref.fileId === f.fileId)
        const hashMap: Map<number, TextcutRectangle[]> = new Map()
        const filteredCommentTopics = comments.filter(
          (topic) => topic.fileId === f.fileId
        )
        refs.forEach((ref) => {
          if (hashMap.has(ref.filePage)) {
            const values = hashMap.get(ref.filePage)

            hashMap.set(ref.filePage, [...(values ?? []), ref])
          } else {
            hashMap.set(ref.filePage, [ref])
          }
        })
        for (const [k, values] of hashMap) {
          const page = doc.getPage(k - 1)
          const rotation = page.getRotation()
          const { width, height } = page.getSize()
          for (const v of values) {
            const filteredComments = filteredCommentTopics
              .filter((topic) => topic.filePage === k)
              .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
            const targetDegree = (((0 - v.degree) % 360) + 360) % 360
            const newRef_ = rotateARectangle(v, targetDegree)
            const targetDegree_ = (((0 - rotation.angle) % 360) + 360) % 360
            const newRef = rotateARectangle(newRef_, targetDegree_)
            const refX = newRef.x,
              refY = newRef.y + newRef.h,
              refH = newRef.h,
              refW = newRef.w,
              ocrH = newRef.ocrH,
              ocrW = newRef.ocrW

            page.drawRectangle({
              x: (refX * width) / ocrW - 2,
              y: height - (refY * height) / ocrH - 2,
              width: (refW * width) / ocrW + 4,
              height: (refH * height) / ocrH + 4,
              ...getColor(v.tag),
            })
            for (const topic of filteredComments) {
              const contents = [...topic.comments].sort((a, b) =>
                dayjs(b.createdAt).diff(a.createdAt)
              )
              const textArr = contents.map(
                (comment) => `${comment.content}(${comment.createdBy})\n`
              )

              const target_degree = 360 - rotation.angle

              const [rotated_x, rotated_y, rotated_w, rotated_h] =
                rotateAVertex(
                  topic.x,
                  topic.y,
                  topic.ocrW,
                  topic.ocrH,
                  target_degree
                )

              const x = (rotated_x * width) / rotated_w
              const y = height - (rotated_y * height) / rotated_h

              const annotation = doc.context.obj({
                Type: 'Annot',
                Subtype: 'Text',
                Open: false, // Is the annotation open by default?
                Name: 'Note', // Determines the icon to place in the document.
                Rect: [x, y, 30, 30], // The position of the annotation
                Contents: PDFString.of(textArr.join('')), // The annotation text
              })

              const annotationRef = doc.context.register(annotation)

              const annots = page.node.lookup(PDFName.of('Annots'))
              if (annots) {
                ;(annots as PDFArray).push(annotationRef)
              } else
                page.node.set(
                  PDFName.of('Annots'),
                  doc.context.obj([annotationRef])
                )
            }
          }
          const [copyPage] = await newPDF.copyPages(doc, [k - 1])
          newPDF.addPage(copyPage)
        }
      }
      const pdfBytes = await newPDF.save()
      download(pdfBytes, `${nanoid()}`, 'application/pdf')
    } catch (err) {
      console.error(err)
    } finally {
      setPageLoading(false)
    }
  }

  const downloadAllFiles = async (zip = false) => {
    setPageLoading(true)
    const promises: Promise<Downloadable>[] = []
    for (const file of files) {
      const promise = localMode
        ? downloadPDFFromLocal(file)
        : downloadPDFFromCloud(file)
      promises.push(promise)
    }
    const result = await Promise.all(promises).catch((err) =>
      console.error(err)
    )
    if (!result) {
      setPageLoading(false)
      return
    }
    if (!zip) {
      result.forEach((re) => download(re.blob, re.fileName, re.type))
    } else {
      const zipFile = new JSZip()
      result.forEach((re) =>
        zipFile.file(re.fileName, re.blob, { binary: true })
      )
      const blob = await zipFile
        .generateAsync({ type: 'blob' })
        .catch((err) => console.error(err))
      if (blob) download(blob, 'files.zip', 'application/zip')
    }
    setPageLoading(false)
  }

  const exportAllLinkedFiles = async (zip = false) => {
    setPageLoading(true)
    const promises: Promise<Downloadable | undefined>[] = []
    for (const file of linkedFiles) {
      promises.push(exportPDF(file))
    }
    const results = await Promise.all(promises).catch((err) =>
      console.error(err)
    )
    if (!results) {
      setPageLoading(false)
      return
    }
    if (!zip) {
      results.forEach((re) => {
        if (re) download(re.blob, re.fileName, re.type)
      })
    } else {
      const zipFile = new JSZip()
      results.forEach((re) => {
        if (re) zipFile.file(re.fileName, re.blob, { binary: true })
      })
      const blob = await zipFile
        .generateAsync({ type: 'blob' })
        .catch((err) => console.error(err))
      if (blob) download(blob, 'files.zip', 'application/zip')
    }
    setPageLoading(false)
  }

  const showPopup = (popup: PopupTextType) => {
    setPopupText({
      header: popup.header,
      content: popup.content,
      onClick: popup.onClick,
      onSecondBtnClick: popup.onSecondBtnClick ?? undefined,
    })
    setOpenPopup(true)
  }

  const confirmSaveToLocal = async () => {
    setOpenPopup(false)
    setPageLoading(true)
    try {
      const filteredFiles = files.filter(
        (file) => file.status === FileStatus.SUCCEEDED
      )
      await savePdfsToWorkbook(filteredFiles)
      const promises = []
      for (const file of files) promises.push(deleteFile(file.fileId))
      await Promise.all(promises)
      await dispatch(setLocalMode())
      await autoSave()
    } catch (err) {
      console.error(err)
    } finally {
      setPageLoading(false)
    }
  }

  const isExportable = (fileId: string) =>
    references.some((ref) => ref.fileId === fileId) ||
    comments.some((topic) => topic.fileId === fileId)

  const isTrimmable = (fileId: string) =>
    references.some((ref) => ref.fileId === fileId)

  const trimHelper = async (
    fileId: string,
    pdfBytes: ArrayBuffer,
    ocr: TestDataOcr
  ) => {
    const pdfDoc = await PDFDocument.load(pdfBytes)
    const newPDF = await PDFDocument.create()
    const pageSet = new Set<number>()
    const filteredRefs = references.filter((ref) => {
      if (ref.fileId === fileId) {
        pageSet.add(ref.filePage)
        return true
      }
      return false
    })
    const arr = Array.from(pageSet)
      .map((num) => num - 1)
      .sort((a, b) => a - b)
    const pages = await newPDF.copyPages(pdfDoc, arr)
    const newOcr = ocr.filter((data, idx) => arr.includes(idx))
    const newRefs: TextcutRectangle[] = []
    for (let i = 0; i < pages.length; i++) {
      newPDF.addPage(pages[i])
      const oldPage = arr[i] + 1
      const tmp = filteredRefs.filter((ref) => {
        if (ref.filePage === oldPage) return true
        return false
      })
      tmp.forEach((rect) => {
        const newRect: TextcutRectangle = { ...rect, filePage: i + 1 }
        newRefs.push(newRect)
      })
    }
    const savedPDF = await newPDF.save()
    const pdfBlob = new Blob([savedPDF.buffer], { type: 'application/pdf' })
    return [pdfBlob, newOcr, newRefs] as const
  }

  const trimCloudFile = async (id: string) => {
    const f = await getFileV2(id)
    const pdf = await (await fetch(f.pdfUrl)).blob()
    const ocr = await getOcr(f.ocrUrl)
    const pdfBytes = await pdf.arrayBuffer()

    const [pdfBlob, newOcr, newRefs] = await trimHelper(id, pdfBytes, ocr)
    const ocrBlob = new Blob([JSON.stringify(newOcr)], {
      type: 'applicatin/json',
    })
    const [pdfUploadUrl, ocrUploadUrl] = await replacePdfAndOcr(id)
    return await Promise.all([
      putFileToSignedURL(pdfUploadUrl, new File([pdfBlob], f.fileName ?? '')),
      putFileToSignedURL(ocrUploadUrl, new File([ocrBlob], f.fileName ?? '')),
      dispatch(updateRefereces(newRefs)),
    ])
  }

  const trimLocalFile = async (fileId: string) => {
    const file = await fetchPDFFromLocal(fileId)
    const ocr = file?.ocr
    const pdfBlob = file?.pdfBlob
    if (!ocr || !pdfBlob) return
    const pdfBytes = await pdfBlob.arrayBuffer()
    const [newPdfBlob, newOcr, newRefs] = await trimHelper(
      fileId,
      pdfBytes,
      ocr
    )
    const newFile: typeof file = { ...file, pdfBlob: newPdfBlob, ocr: newOcr }

    return Promise.all([
      replaceLocalPDF(newFile),
      dispatch(updateRefereces(newRefs)),
    ])
  }

  const trimFile = (fileId: string) => {
    setPageLoading(true)
    if (localMode) {
      trimLocalFile(fileId)
        .then(() => setRefetchLocal(!refetchLocal))
        .catch((err) => console.error(err))
    } else {
      trimCloudFile(fileId)
        .catch((err) => console.error(err))
        .finally(() => {
          refetch()
            .catch((err) => console.error(err))
            .finally(() => setPageLoading(false))
        })
    }
  }

  const renderErrorTitle = () => {
    if (localMode && localError) return getOfficeErrorTitle(localError.message)
    return isOfficeError(error)
      ? getOfficeErrorTitle(error.message)
      : 'Internal Error'
  }

  // if (isLoading || isFetchingLocal || pageLoading)
  //   return (
  //     <div className="flex h-screen w-screen items-center justify-center">
  //       <BeatLoader color="#36d7b7" />
  //     </div>
  //   )

  // if (isError)
  //   return (
  //     <div className="flex flex-col p-4">
  //       <ErrorMsg
  //         title={
  //           isOfficeError(error)
  //             ? getOfficeErrorTitle(error.message)
  //             : 'Something is wrong'
  //         }
  //         content={GENERAL_ERR_CONTENT}
  //       />
  //     </div>
  //   )
  return (
    <>
      <div className="sticky top-0 z-50 w-full bg-white shadow-sm min-h-[56px] max-h-[10%] min-w-[480px]">
        <div className="flex w-full min-w-[476px] h-fit justify-between space-x-6 p-1">
          {!upload && (
            <div className="text-lg font-bold p-2">
              <h1>Files</h1>
            </div>
          )}
          {upload && !uploadOnProgress && (
            <div className="text-md font-bold p-2">
              <button
                className="flex items-center transition ease-in-out delay-100 hover:scale-105"
                onClick={() => setUpload(false)}
              >
                <ArrowBackOutlinedIcon sx={{ fontSize: 20 }} />
                <h1 className="ml-1">Back to files</h1>
              </button>
            </div>
          )}
          <div className="flex justify-between items-center p-2">
            {!localMode &&
              !upload &&
              !files.some((file) => file.status === FileStatus.PROCESSING) && (
                <div className="relative flex justify-center group mr-2 transition ease-in-out delay-100 hover:scale-110">
                  <button
                    className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                    onClick={() =>
                      showPopup({ ...TRANSFER, onClick: confirmSaveToLocal })
                    }
                  >
                    <CloudDownloadOutlinedIcon sx={{ fontSize: '20px' }} />
                  </button>
                  <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                    Include in excel
                  </div>
                </div>
              )}
            {files.length !== 0 &&
              !upload &&
              !files.some((file) => file.status === FileStatus.PROCESSING) && (
                <div className="relative flex justify-center group  mr-2 transition ease-in-out delay-100 hover:scale-110">
                  <button
                    className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                    onClick={showDownloadOptionsPopup}
                  >
                    <FileUploadOutlinedIcon sx={{ fontSize: '20px' }} />
                  </button>
                  <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                    Export options
                  </div>
                </div>
              )}
            {files.length !== 0 &&
              !upload &&
              !files.some((file) => file.status === FileStatus.PROCESSING) && (
                <div className="relative flex justify-center group  mr-2 transition ease-in-out delay-100 hover:scale-110">
                  <button
                    className="hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center"
                    onClick={showDeleteOptionsPopup}
                  >
                    <DeleteOutlinedIcon sx={{ fontSize: '20px' }} />
                  </button>
                  <div className="absolute w-max top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
                    Delete options
                  </div>
                </div>
              )}
            {!upload && (
              <div className="transition ease-in-out delay-100 hover:scale-110">
                <button
                  onClick={() => setUpload(true)}
                  className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-teal-300 font-medium rounded-full text-xs px-2 py-2 text-center"
                >
                  Upload Files
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {upload && (
        <UploadContainer
          refetchFiles={refetchFiles}
          setUpload={setUpload}
          loading={uploadOnProgress}
          setLoading={setUploadOnProgress}
        />
      )}
      {!upload && (
        <div className="flex flex-col w-full h-full justify-start items-center px-4 mt-4">
          <Popup
            isOpen={openPopup}
            setIsOpen={setOpenPopup}
            popupHeader={popupText.header}
            popupContent={popupText.content}
            firstBtn={{ text: 'Confirm', onClick: popupText.onClick }}
            secondBtn={{
              text: 'Cancel',
              onClick: popupText.onSecondBtnClick
                ? popupText.onSecondBtnClick
                : () => setOpenPopup(false),
            }}
          />

          <Tab.Group>
            <Tab.List
              defaultValue={0}
              className="flex min-w-[480px] w-full h-fit max-w-md space-x-1 rounded-xl bg-[#81D8D0]/20 p-1"
            >
              {CATEGORIES.map((category) => (
                <Tab
                  as={motion.button}
                  whileHover={{ scale: 1.1 }}
                  transition={{
                    type: 'spring',
                    stiffness: 300,
                  }}
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white text-green-700 shadow'
                        : 'text-white-100 hover:bg-white/[0.12] hover:text-black hover:font-semibold'
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
            {(isFetchingLocal || pageLoading || isLoading) && (
              <div className="flex h-full w-full items-center justify-center">
                <BeatLoader color="#36d7b7" />
              </div>
            )}
            {(isError || isLocalError) && (
              <div className="flex flex-col p-4">
                <ErrorMsg
                  navigate={navigate}
                  title={renderErrorTitle()}
                  content={GENERAL_ERR_CONTENT}
                />
              </div>
            )}
            {!isError &&
              !isLocalError &&
              !isFetchingLocal &&
              !pageLoading &&
              !isLoading && (
                <Tab.Panels className="mt-2 flex min-h-0 max-h-[600px] w-full min-w-[480px]">
                  <Tab.Panel
                    hidden={files.length === 0}
                    className={classNames(
                      'rounded-xl bg-white p-3 mb-2 w-full h-full  ',
                      'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto'
                    )}
                  >
                    {files.length > 0 && (
                      <FileUnorderedList
                        folderStack={folderStack}
                        setFolderStack={setFolderStack}
                        folders={getFolders(folderStack, folders)}
                        files={getFilesFromFolder(folderStack, folders, files)}
                        deletePDF={deletePDF}
                        downloadFile={downloadFile}
                        exportPDF={downloadExportPdf}
                        isExportable={isExportable}
                        isTrimmable={isTrimmable}
                        onFileClick={onFileClick}
                        showPopup={showPopup}
                        trimFile={trimFile}
                        localMode={localMode}
                        references={references}
                        comments={comments}
                      />
                    )}
                  </Tab.Panel>

                  {/* <Tab.Panel
            hidden={files.length === 0}
            className={classNames(
              'rounded-xl bg-white p-3 mb-3 w-full h-full',
              'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto'
            )}
          >
            {files.length > 0 && (
              <FileUnorderedList
                files={files}
                deletePDF={deletePDF}
                downloadFile={downloadFile}
                exportPDF={exportPDF}
                isExportable={isExportable}
                isTrimmable={isTrimmable}
                onFileClick={onFileClick}
                showPopup={showPopup}
                trimFile={trimFile}
                localMode={localMode}
              />
            )}
          </Tab.Panel> */}

                  <Tab.Panel
                    hidden={linkedFiles.length === 0}
                    className={classNames(
                      'rounded-xl bg-white p-3 mb-3 w-full h-full',
                      'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2  overflow-y-auto'
                    )}
                  >
                    {linkedFiles.length > 0 && (
                      <FileUnorderedList
                        files={linkedFiles}
                        deletePDF={deletePDF}
                        downloadFile={downloadFile}
                        exportPDF={downloadExportPdf}
                        isExportable={isExportable}
                        isTrimmable={isTrimmable}
                        onFileClick={onFileClick}
                        showPopup={showPopup}
                        trimFile={trimFile}
                        localMode={localMode}
                        references={references}
                        comments={comments}
                      />
                    )}
                  </Tab.Panel>

                  <Tab.Panel
                    hidden={unlinkedFiles.length === 0}
                    className={classNames(
                      'rounded-xl bg-white p-3 mb-3 w-full h-full',
                      'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto '
                    )}
                  >
                    {unlinkedFiles.length > 0 && (
                      <FileUnorderedList
                        files={unlinkedFiles}
                        deletePDF={deletePDF}
                        downloadFile={downloadFile}
                        exportPDF={downloadExportPdf}
                        isExportable={isExportable}
                        isTrimmable={isTrimmable}
                        onFileClick={onFileClick}
                        showPopup={showPopup}
                        trimFile={trimFile}
                        localMode={localMode}
                        references={references}
                        comments={comments}
                      />
                    )}
                  </Tab.Panel>
                </Tab.Panels>
              )}
          </Tab.Group>
        </div>
      )}
    </>
  )
}

export default FilesContainer
