import axios from 'axios'
import {
  DynamoFile,
  FileRecord,
  GetFileType,
  OCRData,
  TextcutRectangle,
} from '../types'
import {
  findLocalFileRecordWithFileId,
  getLocalFileRecords,
  updateLocalFileRecords,
} from '../workbook'

axios.defaults.baseURL = `${process.env.REACT_APP_URL}`

export const putFileToSignedURL = (url: string, file: File) =>
  axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
    transformRequest: (data, headers) => {
      delete headers['Authorization']
      return data
    },
  })

export const checkSubscriptionStatus = async () => {
  const data = await axios.get<{
    data: {
      isSubscribed: boolean
      firmId: string
    }
  }>('/check-subscription')
  return data.data.data
}

export const startProcessingDoc = async (fileId: string) => {
  const body = { fileId }
  const data = await axios.post<{
    message: string
  }>('/file/process-doc', body)
  console.log(data)
  return data.data.message
}

export const startProcessingRedactedDoc = async (fileId: string) => {
  const body = { fileId }
  const data = await axios.post<{
    message: string
  }>('/file/process-redacted-doc', body)
  console.log(data)
  return data.data.message
}

axios.interceptors.request.use(async (config) => {
  try {
    const token = await Office.auth.getAccessToken()
    config.headers.Authorization = `Bearer ${token}`
  } catch (err) {
    console.error('Failed to acquire token silently', err)
  }
  return config
})

export const listAllFiles = async () => {
  const data = await axios.get<{ data: DynamoFile[] }>('/file/all')
  const files = data.data.data
  const records: FileRecord[] = files.map((file) => ({
    fileId: file.fileId,
    status: file.status,
    userId: file.userId,
  }))
  await updateLocalFileRecords(records)
  return files
}

export const listAllFilesV2 = async () => {
  const localFileRecords = await getLocalFileRecords()
  if (!localFileRecords.length) return []
  const body = localFileRecords.map((record) => ({
    fileId: record.fileId,
    userId: record.userId,
  }))
  const data = await axios.post<{ data: DynamoFile[] }>('/file/batch-get', body)
  const files = data.data.data
  const records: FileRecord[] = files.map((file) => ({
    fileId: file.fileId,
    status: file.status,
    userId: file.userId,
  }))
  await updateLocalFileRecords(records)
  // console.log(files)
  return files
}

export const createRecord = (fileName: string, relativePath: string) =>
  axios.post<{ data: { fileId: string } }>('/file', {
    fileName,
    dir: relativePath,
  })

export const getSignedUrl = (fileId: string) =>
  axios.post<{ uploadURL: string }>('/get-signed-url', { fileId })

export const getFile = async (id: string) => {
  const res = await axios.get<{ data: GetFileType }>(`/file/${id}`)
  return res.data.data
}

export const getFileV2 = async (id: string) => {
  const record = await findLocalFileRecordWithFileId(id)
  if (!record) throw new Error('Internal Error')
  const res = await axios.get<{ data: GetFileType }>(
    `/file/${id}/${record.userId}`
  )
  return res.data.data
}

export const getFilesWithFileIds = async (fileIds: string[]) => {
  const promises = fileIds.map((id) => getFile(id))
  const res = await Promise.all(promises)
  const arr: FileRecord[] = res.map((r) => ({
    fileId: r.fileId,
    status: r.status,
    userId: r.userId,
  }))
  await updateLocalFileRecords(arr)
  return res
}

export const getOcr = async (ocrUrl: string) => {
  const res = await axios.get<OCRData>(ocrUrl, {
    transformRequest: (data, headers) => {
      delete headers['Authorization']
      return data
    },
  })
  return res.data
}

export const deleteFile = (fileId: string) =>
  axios.delete<{ message: string }>(`/file/${fileId}`)

export const replacePdfAndOcr = async (fileId: string) => {
  const res = await axios.put<{
    data: { uploadUrlPdf: string; uploadUrlOcr: string }
  }>(`/file/update-attachments/${fileId}`)
  return [res.data.data.uploadUrlPdf, res.data.data.uploadUrlOcr]
}

export const redactFile = async (
  redacts: TextcutRectangle[],
  fileId: string
) => {
  const file = await getFileV2(fileId)
  const redactions = redacts.map((redact) => ({
    canvas_height: redact.ocrH,
    canvas_width: redact.ocrW,
    rect_height: redact.h,
    rect_width: redact.w,
    rect_in_x0: redact.x,
    rect_in_y0: redact.y,
    page_no: redact.filePage - 1,
  }))
  return axios.post(`${process.env.REACT_APP_REDACT_URL}`, {
    file_id: file.fileId,
    user_id: file.userId,
    input_pdf_s3_key: file.originalFileKey,
    redactions,
  })
}
