import { Dispatch, useRef, useState } from 'react'
import useClickOutside from '../../hooks/useClickOutside'
import {
  DynamoFile,
  FileStatus,
  RotationType,
  SearchAllType,
  TestDataOcr,
  TextBlock,
} from '../../types'
import { fetchPDFFromLocal } from '../../workbook'
import { getFileV2, getOcr } from '../../api'
import { breakDownOcr } from '../../utils/ocr'
import { ClockLoader } from 'react-spinners'
import { NavigateFunction } from 'react-router-dom'
import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

type Props = {
  files: DynamoFile[]
  isLocalMode: boolean
  degree: RotationType
  fileId: string
  filePage: number
  navigate: NavigateFunction
  setCurrentPage: Dispatch<React.SetStateAction<number>>
  setSearchAllItem: Dispatch<React.SetStateAction<string[]>>
  setFileId: Dispatch<React.SetStateAction<string>>
  setSearchAllInput: () => void
}

const getFilesOCR = async (files: DynamoFile[], isLocalMode: boolean) => {
  const hashMap = new Map<[string, string], TestDataOcr>()

  if (isLocalMode) {
    const promises = files.map((file) => fetchPDFFromLocal(file.fileId))
    const results = await Promise.all(promises)
    results.forEach((re) => {
      if (re?.fileId && re?.ocr) hashMap.set([re.fileId, re.fileName], re.ocr)
    })
  } else {
    for (const file of files) {
      const result = await getFileV2(file.fileId).catch((err) =>
        console.error(err)
      )
      if (!result) continue
      const ocr = await getOcr(result.ocrUrl).catch((err) => console.error(err))
      if (!ocr) continue
      hashMap.set([file.fileId, file.fileName], ocr)
    }
    // const promises = files.map((file) => getFileV2(file.fileId))
    // const results = await Promise.all(promises)
    // const ocrPromises = results.map((re) => getOcr(re.ocrUrl))
    // const ocrs = await Promise.all(ocrPromises)
    // for (let i = 0; i < ocrs.length; i++) {
    //   hashMap.set([files[i].fileId, files[i].fileName], ocrs[i])
    // }
  }
  return hashMap
}

const SearchAllComboBox = ({
  files,
  isLocalMode,
  degree,
  navigate,
  filePage,
  fileId,
  setCurrentPage,
  setSearchAllItem,
  setSearchAllInput,
}: Props) => {
  const [query, setQuery] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [possibleResults, setPossibleResults] = useState<SearchAllType[]>([])
  const [loading, setLoading] = useState(false)
  const [, setIsError] = useState(false)

  const reset = () => {
    setPossibleResults([])
    setShowOptions(false)
    setSearchAllItem([])
    // enableRibbonAfterSearchAll()
    setSearchAllInput()
  }

  useClickOutside<HTMLDivElement>({
    ref: containerRef,
    action: () => {
      if (!query || !query.trim()) {
        reset()
      }
    },
  })

  const setSearchAllItemHelper = (
    fileId: string,
    filePage: number,
    results: SearchAllType[]
  ) => {
    const ids = results
      .filter((re) => re.fileId === fileId && re.filePage === filePage)
      .map((re) => re.blockId)
    setSearchAllItem(ids)
  }

  const onSearchButtonClick = async () => {
    if (query.trim()) {
      setLoading(true)
      // await disableRibbon()
      const filteredFiles = files.filter(
        (file) => file.status === FileStatus.SUCCEEDED
      )
      const map = await getFilesOCR(filteredFiles, isLocalMode).catch((err) => {
        console.error(err)
        setIsError(true)
      })
      if (map) {
        let results: SearchAllType[] = []
        for (const [k, v] of map) {
          const [fileId, fileName] = k
          const [pageMap, , , lineMap] = breakDownOcr(v, degree.curr)
          const blocks: TextBlock[] = []
          for (const line of lineMap.values()) {
            if (
              line.description
                .toLocaleLowerCase()
                .includes(query.toLocaleLowerCase())
            )
              blocks.push(line)
          }
          for (const [pageNum, pageObj] of pageMap) {
            const filtered = blocks.filter((block) => {
              if (pageObj.relationships) {
                return pageObj.relationships[0].ids.includes(block.id)
              }
              return false
            })
            const arr: SearchAllType[] = filtered.map((block) => ({
              blockId: block.id,
              fileId,
              fileName,
              filePage: Number(pageNum),
            }))
            results = [...results, ...arr]
          }
        }
        setPossibleResults(results)
        setSearchAllItemHelper(fileId, filePage, results)
        setLoading(false)
      } else {
        setPossibleResults([])
        setSearchAllItem([])
        // await enableRibbonAfterSearchAll()
        setLoading(false)
      }
    }

    setShowOptions(true)
  }

  const onItemClick = (item: [string, string, number]) => () => {
    const [itemFileId, , itemFilePage] = item
    if (itemFileId === fileId && itemFilePage === filePage) return
    if (itemFileId !== fileId) {
      setSearchAllItemHelper(itemFileId, itemFilePage, possibleResults)
      navigate(`/file/${itemFileId}`, {
        replace: true,
        state: { page: itemFilePage },
      })
      return
    }
    if (filePage !== itemFilePage) {
      setSearchAllItemHelper(fileId, itemFilePage, possibleResults)
      setCurrentPage(itemFilePage)
      return
    }
  }
  const arr: [string, [string, string, number]][] = possibleResults.map(
    (result) => {
      const tuple: [string, string, number] = [
        result.fileId,
        result.fileName,
        result.filePage,
      ]
      return [tuple.join('-'), tuple]
    }
  )
  const possibleResultMap = new Map(arr)

  const onSearchInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter')
      onSearchButtonClick().catch((err) => console.error(err))
  }

  const clearSearchField = () => {
    setQuery('')
    setPossibleResults([])
    setSearchAllItem([])
  }

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <div className="relative">
        <div className="relative w-full cursor-default overflow-hidden rounded-sm text-left shadow-md  focus:ring-2 focus:ring-teal-300/75 focus:ring-offset-2 focus:ring-offset-teal-300 sm:text-sm">
          <input
            className="w-full border-none  py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 animate-pulse focus:animate-none"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Search files"
            onKeyUp={onSearchInputKeyUp}
          />

          {!loading && (
            <div className="absolute flex inset-y-0 right-0 items-center pr-2">
              <button className="mr-1" onClick={clearSearchField}>
                <CloseOutlinedIcon sx={{ fontSize: 16 }} />
              </button>
              <button onClick={onSearchButtonClick}>
                <SearchOutlinedIcon sx={{ fontSize: 16 }} />
              </button>
            </div>
            // <button
            //   className="absolute inset-y-0 right-0 items-center pr-2"
            //   onClick={onSearchButtonClick}
            // >
            //   <MdOutlineSearch />
            // </button>
          )}

          {loading && (
            <button
              className="absolute inset-y-0 right-0 items-center pr-2"
              onClick={reset}
            >
              <ClockLoader color="#36d7b7" size={15} />
            </button>
          )}
        </div>

        {showOptions && (
          <div className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {possibleResultMap.size === 0 && (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700 ">
                Nothing found.
              </div>
            )}
            {possibleResultMap.size > 0 &&
              Array.from(possibleResultMap.values()).map((item) => (
                <div
                  onClick={onItemClick(item)}
                  key={item.join('-')}
                  className={`relative cursor-default select-none py-2 pl-2 pr-4 
                                      hover:bg-teal-600 hover:text-white text-gray-900
                                    `}
                >
                  {`${item[1]}, page: ${item[2]}`}
                  {/* <div className="absolute flex justify-center inset-y-0 right-0 items-center pr-2">
                    <button
                      className="text-xs  hover:bg-teal-500 text-gray-800 py-2 px-2"
                      onClick={onItemClick(item)}
                    >
                      View
                    </button>
                    <button className="text-xs  hover:bg-teal-500 text-gray-800 py-2 px-2 ">
                      Snip
                    </button>
                  </div> */}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchAllComboBox
