import { Outlet, Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import './App.css'
import FilesContainer from './components/FilesContainer'
import FileContainer from './components/FileContainer'
import DataMatching from './components/DataMatching'
import { useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useAppDispatch, useAppSelector } from './dispatch'
import {
  deleteReference,
  initializeReferencesThunk,
  resetReferences,
} from './slice/referenceSlice'
import useOnActivated from './hooks/useOnActivated'
import { initActiveWorksheet } from './slice/activeWorksheetSlice'
import useOnActivatedReceiver from './hooks/useOnActivatedReceiver'
import { initSaveToLocal } from './slice/saveToLocalSlice'
import useUndo from './hooks/useUndo'
import { TextcutRectangle, UndoEvent } from './types'
import { nanoid } from 'nanoid'
import { pushToUndoStack } from './slice/undoSlice'
import { initComments, resetComments } from './slice/commentSlice'
import { checkSubscriptionStatus } from './api'
import { UnsubscribedPage } from './components/UnauthPages/UnsubscribedPage'
import useOnReferenceSelected from './hooks/useOnReferenceSelected'
import useOnChanged from './hooks/useOnChanged'
import useOnChangedReceiver from './hooks/useOnChangedReceiver'
import { getRangeValues } from './workbook'
import { BeatLoader } from 'react-spinners'
import ErrorMsg from './components/ErrorMsg'
import {
  FETCH_IS_SUBSCRIBED_ERROR_CONTENT,
  FETCH_IS_SUBSCRIBED_ERROR_TITLE,
} from './constant'
import useOnSelectionChange from './hooks/useOnSelectionChange'

// const queryClient = new QueryClient()

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: any
  }
}

type AppProps = {
  username: string
  userId: string
  userEmail: string
}

function MainComponent() {
  const navigationRef = useRef(useNavigate())
  const dispatch = useAppDispatch()
  const references = useAppSelector((state) => state.references.references)
  /**onSelection hooks */
  useOnSelectionChange()
  /**end of onSelection hooks */

  useOnReferenceSelected()
  useOnChanged()
  useOnChangedReceiver()

  useOnActivated()
  useOnActivatedReceiver()
  useUndo()

  useEffect(() => {
    const promises = [
      dispatch(initializeReferencesThunk()),
      dispatch(initActiveWorksheet()),
      dispatch(initSaveToLocal()),
      dispatch(initComments()),
      // dispatch(resetComments()),
    ]
    Promise.all(promises).catch((err) => console.error(err))
  }, [dispatch])

  /**Navigation effect */
  useEffect(() => {
    const navigationEventHandler = (event: any) => {
      const url = event?.detail?.url
      if (url) {
        Office.addin
          .showAsTaskpane()
          .then(() =>
            navigationRef.current(url === window.location.pathname ? '/' : url)
          )
      }
    }

    window.addEventListener('Navigation', navigationEventHandler)
    return () =>
      window.removeEventListener('Navigation', navigationEventHandler)
  }, [])

  useEffect(() => {
    const dispatchPushToUndoStack = (
      preValues: any[][],
      reference: TextcutRectangle,
      type: 'ADD' | 'DELETE'
    ) => {
      const event: UndoEvent = {
        id: nanoid(),
        preValues,
        reference,
        type,
      }
      dispatch(pushToUndoStack(event))
    }
    const handler = (event: any) =>
      Excel.run(async (ctx) => {
        try {
          const e = event as unknown as CustomEvent
          const { sheetId, rangeAddress } = e.detail as unknown as {
            sheetId: string
            rangeAddress: string
          }
          const item = references.find(
            (ref) => ref.sheetId === sheetId && ref.rangeAddr === rangeAddress
          )
          if (!item) return
          const bindingItem = ctx.workbook.bindings.getItemOrNullObject(
            item.bindingId
          )
          await ctx.sync()
          if (bindingItem.isNullObject) return
          const values = await getRangeValues(item.sheetId, item.rangeAddr)
          bindingItem.delete()
          await dispatch(deleteReference({ sheetId, rangeAddress }))
          await ctx.sync()
          dispatchPushToUndoStack(values, item, 'DELETE')
        } catch (err) {
          console.error(err)
        }
      })

    window.addEventListener('ReferenceDeletion', handler)

    return () => window.removeEventListener('ReferenceDeletion', handler)
  }, [dispatch, references])

  useEffect(() => {
    const handler = () => {
      dispatch(resetReferences())
        .then(() => dispatch(resetComments()))
        .then(() =>
          Excel.run(async (ctx) => {
            const bindings = ctx.workbook.bindings
            bindings.load('items')
            await ctx.sync()
            const items = bindings.items
            for (const item of items) item.delete()
          })
        )
        .catch((err) => console.error(err))
    }

    window.addEventListener('DELETE_ALL_REFERENCES', handler)

    return () => window.removeEventListener('DELETE_ALL_REFERENCES', handler)
  }, [dispatch])

  return (
    <>
      <div className="flex flex-col w-full h-screen bg-[#f5f5f5] overflow-hidden">
        <Outlet />

        <Routes>
          <Route path="/file/:id" element={<FileContainer />} />
          <Route path="/files" element={<FilesContainer />} />
          {/* <Route path="/upload" element={<UploadContainer />} /> */}
          <Route path="/data-match" element={<DataMatching />} />
          <Route path="/" element={<FilesContainer />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </>
  )
}
/**
 * MsalProvider is a wrapper component that provides the PublicClientApplication instance to the rest of the application.
 * This allows the application to use the instance for login, logout, and token acquisition.
 *
 * @returns MsalProvider component with the PublicClientApplication instance
 */
function App({ userEmail, userId, username }: AppProps) {
  const { data, isError, isLoading } = useQuery(
    [userId, userEmail],
    checkSubscriptionStatus,
    { refetchOnWindowFocus: false }
  )

  // Feedback feature via ribbon button click and auto populating user info
  useEffect(() => {
    const handler = () => {
      const elem = document.getElementById('atlwdg-trigger')
      const issueCollector = document.getElementById('atlwdg-frame')
      if (!issueCollector) elem?.click()
    }

    window.addEventListener('OPEN_FEEDBACK', handler)

    return () => window.removeEventListener('OPEN_FEEDBACK', handler)
  }, [userEmail, username])

  if (isError)
    return (
      <div className="w-full h-full p-4 flex">
        <ErrorMsg
          title={FETCH_IS_SUBSCRIBED_ERROR_TITLE}
          content={FETCH_IS_SUBSCRIBED_ERROR_CONTENT}
        />
      </div>
    )

  if (isLoading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <BeatLoader size={20} color="#36d7b7" />
      </div>
    )

  return (
    <>
      {data && data.isSubscribed && <MainComponent />}
      {(!data || !data?.isSubscribed) && <UnsubscribedPage />}
    </>
  )
}

export default App
