import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type ShowSelectionOnlyState = {
  showSelectionOnly: boolean
  sheetId: string
  rangeAddress: string
}

const initShowSelectionOnly = (): ShowSelectionOnlyState => {
  const item = localStorage.getItem('ShowSelectionOnly')
  if (item) {
    const obj: ShowSelectionOnlyState = JSON.parse(item)
    return obj
  }
  return {
    showSelectionOnly: false,
    sheetId: '',
    rangeAddress: '',
  }
}

const updateShowSelectionOnlyInLocalStorage = (
  state: ShowSelectionOnlyState
) => {
  localStorage.setItem('ShowSelectionOnly', JSON.stringify(state))
}

export const showSelectionOnlySlice = createSlice({
  name: 'ShowSelectionOnly',
  initialState: initShowSelectionOnly(),
  reducers: {
    setShowSelectionOnly: (state, action: PayloadAction<boolean>) => {
      state.showSelectionOnly = action.payload
      updateShowSelectionOnlyInLocalStorage(state)
    },
    setShowSelectionOnlyRange: (
      state,
      action: PayloadAction<{ sheetId: string; rangeAddress: string }>
    ) => {
      const { rangeAddress, sheetId } = action.payload
      state.rangeAddress = rangeAddress
      state.sheetId = sheetId
      updateShowSelectionOnlyInLocalStorage(state)
    },
  },
})

export const { setShowSelectionOnly, setShowSelectionOnlyRange } =
  showSelectionOnlySlice.actions
export const selectShowSelectionOnly = (state: RootState) =>
  state.showSelectionOnly
export default showSelectionOnlySlice.reducer
