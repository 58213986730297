import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UndoEvent } from '../types'
import { RootState } from '../store'

type UndoSlice = {
  undoStack: UndoEvent[]
}

const initialState: UndoSlice = {
  undoStack: [],
}

const undoSlice = createSlice({
  initialState,
  name: 'UndoSlice',
  reducers: {
    pushToUndoStack: (state, action: PayloadAction<UndoEvent>) => {
      while (state.undoStack.length >= 50) {
        state.undoStack.shift()
      }
      state.undoStack.push(action.payload)
    },
    popUndoStack: (state) => {
      state.undoStack.pop()
    },
    updateUndoStack: (state, action: PayloadAction<UndoEvent[]>) => {
      state.undoStack = action.payload
    },
  },
})

export const { popUndoStack, pushToUndoStack, updateUndoStack } =
  undoSlice.actions

export const selectUndoStack = (root: RootState) => root.undo.undoStack

export default undoSlice.reducer
