import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { getFile } from '../api'
import {
  CompressedFile,
  CutTag,
  DynamoFile,
  FileRecord,
  FileStatus,
  TestDataOcr,
  UndoEvent,
} from '../types'
import {
  DISABLED_RIBBON_BUTTON,
  ENABLED_RIBBON_BUTTON_AFTER_SEARCH_ALL,
} from '../constant'
import { publishOnReferenceSelectedEvent } from '../utils/common'

/**
 * Test function, set values a range of cells
 */
export const setValuesForRangeDemo = async () => {
  await Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    const data = [['foo', 'bar', 'qux']]
    const range = sheet.getRange('B5:D5')
    range.values = data
    await ctx.sync()
  })
}

export const setValueToSelectedRange = async (val: string) => {
  return await Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    sheet.load('id')
    const range = ctx.workbook.getSelectedRange()
    range.load('values')
    range.load('rowIndex')
    range.load('columnIndex')
    await ctx.sync()
    const values = range.values
    values[0][0] = val
    range.values = values
    range.format.autofitColumns()
    const row = range.rowIndex
    const col = range.columnIndex
    await ctx.sync()
    return `${sheet.id}/${numberToLetters(col)}${row + 1}`
  })
}

export const numberToLetters = (num: number) => {
  let letters = ''
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
    num = Math.floor(num / 26) - 1
  }
  return letters
}

export const setMatrixToSelectedRange = async (
  matrix: string[][],
  row: number,
  col: number
) => {
  return await Excel.run(async (ctx) => {
    const range = ctx.workbook.getSelectedRange()
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    range.load('columnIndex')
    range.load('rowIndex')
    sheet.load('id')
    await ctx.sync()
    const [startRow, startCol] = [range.rowIndex, range.columnIndex]
    const [endRow, endCol] = [startRow + row - 1, startCol + col - 1]

    const matrixRange = `${numberToLetters(startCol)}${
      startRow + 1
    }:${numberToLetters(endCol)}${endRow + 1}`

    const outputRange = ctx.workbook.worksheets
      .getActiveWorksheet()
      .getRange(matrixRange)
    outputRange.values = matrix
    outputRange.format.autofitColumns()
    outputRange.format.autofitRows()
    await ctx.sync()
    return `${sheet.id}/${matrixRange}`
  })
}

export const generateReferenceId = (sheetId: string, cellAddr: string) =>
  `REFERENCE>>${sheetId}>>${cellAddr}`

const lettersToNumber = (letters: string) => {
  let n = 0
  for (let p = 0; p < letters.length; p++) {
    n = letters[p].charCodeAt(0) - 64 + n * 26
  }
  return n
}

export const cellInRange = (cellAddr: string, rangeAddr: string) => {
  try {
    const cellCol = lettersToNumber(cellAddr[0])
    const cellRow = Number(cellAddr.substring(1, cellAddr.length))
    const [head, tail] = rangeAddr.split(':')
    const headCol = lettersToNumber(head[0])
    const headRow = Number(head.substring(1, head.length))
    const tailCol = lettersToNumber(tail[0])
    const tailRow = Number(tail.substring(1, tail.length))
    return (
      cellCol >= headCol &&
      cellCol <= tailCol &&
      cellRow >= headRow &&
      cellRow <= tailRow
    )
  } catch (err) {
    console.log('err:', err)
    return false
  }
}

/**
 * Loads values from a specified range in the workbook.
 * @param range - The range to load values from.
 * 
 * @returns A promise that resolves to the loaded values from the range.
 */
export const loadValuesFromARange = (range: string) => {
  return Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    const r = sheet.getRange(range)
    // r.load('values')
    r.load('text') // using text instead of values to catch exactly what user sees
    await ctx.sync()
    return r.text.map((row) => row.map((text) => text.trim()))
  })
}

// const decode = (buffer: ArrayBuffer) => {
//   const decoder = new TextDecoder()
//   return decoder.decode(buffer)
// }

// const encode = (str: string) => {
//   const encoder = new TextEncoder()
//   return encoder.encode(str).buffer
// }

const attachPDFsToWorkbook = (file: CompressedFile) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const k = `FILE_${file.fileId}`
    const item = settings.getItemOrNullObject(k)
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    settings.add(k, JSON.stringify(file))
    await ctx.sync()
  })

const pdfToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })

const base64ToBlob = (base64: string, type: string) => {
  const chars = atob(base64.split(',')[1])
  const numbers = new Array(chars.length)
  for (let i = 0; i < chars.length; i++) {
    numbers[i] = chars.charCodeAt(i)
  }
  const byteArray = new Uint8Array(numbers)
  return new Blob([byteArray], { type })
}

export const savePdfsToWorkbook = async (files: DynamoFile[]) => {
  const hashMap = new Map<string, CompressedFile>()
  for (const file of files) {
    const res = await getFile(file.fileId)
    const { pdfUrl, ocrUrl } = res
    const [pdf, ocr] = await Promise.all([fetch(pdfUrl), fetch(ocrUrl)])
    const ocrJSON = await ocr.json()
    const compressedOCR = compressToUTF16(JSON.stringify(ocrJSON))
    const pdfBlob = await pdf.blob()
    const pdfBase64 = await pdfToBase64(pdfBlob)
    const compressedPDF = compressToUTF16(pdfBase64)
    const ocrSize = new Blob([compressedOCR]).size

    hashMap.set(file.fileId, {
      ...file,
      compressedPDF,
      compressedOCR,
      originalFileSize: pdfBlob.size + ocrSize,
    })
  }
  const arr = Array.from(hashMap.values())
  for (const val of arr) await attachPDFsToWorkbook(val)
}

export const fetchPDFsFromWorkbook = () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    settings.load('items')
    await ctx.sync()
    const items = settings.items
    const settingArray: Excel.Setting[] = []
    for (const setting of items) {
      settingArray.push(setting)
      setting.load(['value', 'key', 'isNullObject'])
    }
    await ctx.sync()
    let arr: DynamoFile[] = []
    settingArray
      .filter((item) => !item.isNullObject && item.key.startsWith('FILE_'))
      .forEach((item) => {
        const parsedValue: CompressedFile = JSON.parse(item.value)
        arr.push({
          completedAt: parsedValue.completedAt,
          createdAt: parsedValue.createdAt,
          fileId: parsedValue.fileId,
          fileType: parsedValue.fileType,
          ocrFileKey: parsedValue.ocrFileKey,
          originalFileKey: parsedValue.originalFileKey,
          status: parsedValue.status,
          userId: parsedValue.userId,
          fileName: parsedValue.fileName,
          originalFileSize: parsedValue.originalFileSize,
          dir: parsedValue.dir,
        })
      })
    // if (!item.isNullObject) {
    //   const parsedValue: CompressedFile[] = JSON.parse(item.value)
    //   for (const file of parsedValue) {
    //     arr.push({
    //       completedAt: file.completedAt,
    //       createdAt: file.createdAt,
    //       fileId: file.fileId,
    //       fileType: file.fileType,
    //       ocrFileKey: file.ocrFileKey,
    //       originalFileKey: file.originalFileKey,
    //       status: file.status,
    //       userId: file.userId,
    //       fileName: file.fileName,
    //     })
    //   }
    // }
    return arr
  })

export const fetchPDFFromLocal = (id: string) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    settings.load('items')
    await ctx.sync()
    const items = settings.items
    const settingArray: Excel.Setting[] = []
    for (const setting of items) {
      settingArray.push(setting)
      setting.load(['value', 'key', 'isNullObject'])
    }
    await ctx.sync()
    const filteredSettingArray = settingArray.filter(
      (setting) => !setting.isNullObject && setting.key.startsWith('FILE_')
    )
    for (const item of filteredSettingArray) {
      const key = item.key
      const value = item.value
      const [, fileId] = key.split('_')

      if (fileId !== id) continue
      const parsedValue: CompressedFile = JSON.parse(value)
      const decompressedOCR = decompressFromUTF16(parsedValue.compressedOCR)
      const ocr: TestDataOcr = JSON.parse(decompressedOCR)
      const decompressedPDF = decompressFromUTF16(parsedValue.compressedPDF)
      const pdfBlob = base64ToBlob(decompressedPDF, 'application/pdf')
      return { ...parsedValue, ocr, pdfBlob }
    }
    return null
  })

export const removeAttachedFile = (id: string) =>
  Excel.run(async (ctx) => {
    const settingId = `FILE_${id}`
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject(settingId)
    item.load('isNullObject')
    await ctx.sync()
    if (!item.isNullObject) {
      item.delete()
      await ctx.sync()
    }
  })

export const autoSave = () =>
  Excel.run(async (ctx) => {
    await ctx.sync()
    ctx.workbook.save()
  })

export const replaceLocalPDF = (
  file: CompressedFile & { ocr: TestDataOcr; pdfBlob: Blob }
) =>
  Excel.run(async (ctx) => {
    const compressedOCR = compressToUTF16(JSON.stringify(file.ocr))
    const ocrSize = new Blob([compressedOCR]).size
    const pdfBase64 = await pdfToBase64(file.pdfBlob)
    const compressedPDF = compressToUTF16(pdfBase64)
    const settings = ctx.workbook.settings
    const k = `FILE_${file.fileId}`
    const item = settings.getItemOrNullObject(k)
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return
    const value: CompressedFile = JSON.parse(item.value)
    const newValue = {
      ...value,
      compressedOCR,
      compressedPDF,
      originalFileSize: file.pdfBlob.size + ocrSize,
    }
    settings.add(k, JSON.stringify(newValue))
    await ctx.sync()
    ctx.workbook.save()
  })

export const saveFileRecordsToLocal = (fileRecords: FileRecord[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) {
      settings.add('FileRecords', JSON.stringify(fileRecords))
    } else {
      const elems: FileRecord[] = JSON.parse(item.value)
      const arr = [...elems, ...fileRecords]
      settings.add('FileRecords', JSON.stringify(arr))
    }
    await ctx.sync()
    ctx.workbook.save()
  })

export const updateLocalFileRecords = (fileRecords: FileRecord[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) {
      settings.add('FileRecords', JSON.stringify(fileRecords))
    } else {
      const elems: FileRecord[] = JSON.parse(item.value)
      const arr: [string, FileRecord][] = fileRecords.map((record) => [
        record.fileId,
        record,
      ])
      const map = new Map(arr)
      const mapped: FileRecord[] = elems.map((elem) => {
        if (map.has(elem.fileId)) {
          const item = map.get(elem.fileId)
          return { ...elem, status: item?.status ?? FileStatus.FAILED }
        }
        return elem
      })
      // const filtered = mapped.filter(
      //   (elem) => elem.status !== FileStatus.FAILED
      // )
      settings.add('FileRecords', JSON.stringify(mapped))
    }
    await ctx.sync()
    ctx.workbook.save()
  })

export const containProcessingRecord = (): Promise<[boolean, FileRecord[]]> =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return [false, []]
    const elems: FileRecord[] = JSON.parse(item.value)
    const arr = elems.filter((elem) => elem.status === FileStatus.PROCESSING)
    return [arr.length > 0 ? true : false, arr]
  })

export const getLocalFileRecords = () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return []
    else {
      const elems: FileRecord[] = JSON.parse(item.value)
      return elems
    }
  })

export const findLocalFileRecordWithFileId = (fileId: string) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return undefined
    else {
      const elems: FileRecord[] = JSON.parse(item.value)
      return elems.find((elem) => elem.fileId === fileId)
    }
  })

export const removeLocalFileRecordWithIds = (fileIds: string[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return
    const elems: FileRecord[] = JSON.parse(item.value)
    const filtered = elems.filter((elem) => !fileIds.includes(elem.fileId))
    settings.add('FileRecords', JSON.stringify(filtered))
    await ctx.sync()
    ctx.workbook.save()
  })

export const resetLocalFileRecord = () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return
    const arr: FileRecord[] = []
    settings.add('FileRecords', JSON.stringify(arr))
    await ctx.sync()
    ctx.workbook.save()
  })

export const undoRange = (event: UndoEvent) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(
      event.reference.sheetId
    )
    sheet.load('isNullObject')
    await ctx.sync()
    if (sheet.isNullObject) return
    sheet.activate()
    const range = sheet.getRange(event.reference.rangeAddr)
    range.values = event.preValues
    if (event.reference.tag === CutTag.TABLE) {
      range.format.borders.getItem('EdgeBottom').style = 'None'
      range.format.borders.getItem('EdgeLeft').style = 'None'
      range.format.borders.getItem('EdgeRight').style = 'None'
      range.format.borders.getItem('EdgeTop').style = 'None'
    }
    range.select()
    await ctx.sync()
  })

export const getRangeValues = (sheetId: string, rangeAddress: string) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
    sheet.load('isNullObject')
    await ctx.sync()
    if (sheet.isNullObject) throw new Error('Invalid range')
    const range = sheet.getRange(rangeAddress)
    range.load('values')
    await ctx.sync()
    return range.values
  })

export const setRangeValues = (
  sheetId: string,
  rangeAddress: string,
  values: any[][]
) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
    sheet.load('isNullObject')
    await ctx.sync()
    if (sheet.isNullObject) throw new Error('Invalid range')
    const range = sheet.getRange(rangeAddress)
    range.values = values
    sheet.activate()
    range.select()
    await ctx.sync()
  })

export const disableRibbon = () =>
  Office.ribbon.requestUpdate(DISABLED_RIBBON_BUTTON)

export const enableRibbonAfterSearchAll = () =>
  Office.ribbon.requestUpdate(ENABLED_RIBBON_BUTTON_AFTER_SEARCH_ALL)

export const removeInvalidBindings = () =>
  Excel.run(async (ctx) => {
    const ids: string[] = []
    const bindings = ctx.workbook.bindings
    bindings.load('items')
    await ctx.sync()
    for (const item of bindings.items) {
      item.load('id')
      await ctx.sync().catch(() => ids.push(item.id))
    }
    for (const id of ids) Office.context.document.bindings.releaseByIdAsync(id)
    return ids
  })

export const createBindingAndAddOnSelectionHandler = (
  ctx: Excel.RequestContext,
  range: Excel.Range,
  bindingType: 'Range' | 'Table' | 'Text',
  bindingId: string
) => {
  const binding = ctx.workbook.bindings.add(range, bindingType, bindingId)
  binding.onSelectionChanged.add(async (args) => {
    args.binding.load('id')
    await args.binding.context.sync()
    publishOnReferenceSelectedEvent(args.binding.id)
  })
  return binding
}

export const deleteBinding = (bindingId: string) =>
  Excel.run(async (ctx) => {
    const binding = ctx.workbook.bindings.getItemOrNullObject(bindingId)
    binding.load(['id', 'isNullObject'])
    await ctx
      .sync()
      .catch(() =>
        Office.context.document.bindings.releaseByIdAsync(binding.id)
      )
    if (binding.isNullObject) return
    binding.delete()
    await ctx.sync()
  })
